import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { setErrorMessage } from 'reducers/globalReducer';
import useAxios from 'hooks/useAxios';
import { getCustomer } from 'services';
import withRouter from 'utils/withRouter';
import { colors, fonts } from 'styles/theme';
import Loading from 'components/Loading';
import Text from 'components/Text';

const CustomerCardWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24px 16px;
  margin: -16px -16px 0 -16px;
  background-color: ${colors.SHADES_50};
`;

const CustomerCard = styled.div`
  display: flex;
  min-height: 80px;
  width: 100%;
  padding: 16px;
  background: ${colors.SHADES_000};
  border: 1px solid ${colors.SHADES_200};
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06),
    0px 0px 1px rgba(0, 0, 0, 0.04);
  border-radius: 16px;
  > div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  img {
    max-width: 48px;
    max-height: 48px;
    object-fit: contain;
    margin-right: 16px;
    border-radius: 100%;
  }
`;

const ItemLinkWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 0;
  border-bottom: 1px solid ${colors.SHADES_100};
  > div {
    display: flex;
    align-items: flex-end;
    gap: 8px;
  }
  i {
    font-size: 20px;
    color: ${colors.SHADES_400};
  }
`;

const MemberShipLabel = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 2px 8px;
  margin-top: 8px;
  border-radius: 4px;
  background-color: ${colors.SYSTEM_INFO_100};
  color: ${colors.SYSTEM_INFO_500};
  ${fonts['Body/12px_Regular']};
`;

const ItemLink = ({ icon, title, onClick }) => (
  <ItemLinkWrapper onClick={onClick}>
    <div>
      <i className={icon} />
      <Text content={title} />
    </div>
    <i className="ri-arrow-right-s-line" />
  </ItemLinkWrapper>
);

const Customer = ({ auth, navigate }) => {
  const dispatch = useDispatch();
  const lineUserProfile = useSelector((state) => state.global.lineUserProfile);
  const { request } = useAxios();
  const [customerData, setCustomerData] = useState();

  // @TODO: unbind customer
  // const [openLogoutDialog, setOpenLogoutDialog] = useState(false);
  // const handleLogout = () => {
  //   // @TODO: remove local storage
  //   localStorage.removeItem('lineUserId');
  //   localStorage.removeItem('customerId');
  //   localStorage.removeItem('scale');
  //   window.location.href = '/login';
  // };

  useEffect(() => {
    if (auth.customerId) {
      request(getCustomer())
        .then((data) => {
          const {
            address,
            birth,
            countryCode,
            email,
            gender,
            idNum,
            membershipLevel,
            name,
            phone,
            viewScale,
          } = data;
          const customer = {
            phoneCountryCode: countryCode,
            phoneNumber: phone,
            name,
            idNumber: idNum,
            gender,
            birthday: birth,
            address,
            email,
            membershipLevelName: membershipLevel?.name,
            viewScale,
          };
          setCustomerData(customer);
        })
        .catch((error) => {
          dispatch(setErrorMessage(error.message));
        });
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return !customerData ? (
    <Loading />
  ) : (
    <>
      <CustomerCardWrapper>
        <CustomerCard>
          <img src={lineUserProfile?.pictureUrl} />
          <div>
            <Text font="Heading/Medium/Heavy" content={customerData.name} />
            {customerData.membershipLevelName && (
              <MemberShipLabel>
                {customerData.membershipLevelName}
              </MemberShipLabel>
            )}
          </div>
        </CustomerCard>
      </CustomerCardWrapper>
      <ItemLink
        icon="ri-customer-5-line"
        title="個人資訊"
        onClick={() =>
          navigate('/customer/profile', { state: { customerData } })
        }
      />
      <Text
        font="Heading/Large/Medium"
        content="設定"
        marginTop={16}
        marginBottom={16}
      />
      <ItemLink
        icon="ri-font-size"
        title="閱讀字體大小"
        onClick={() => navigate('/setting/scale', { state: { customerData } })}
      />
      {/* @TODO: hide for latter phase */}
      {/* <Button
        variant="outline"
        color="secondary"
        size="large"
        onClick={() => setOpenLogoutDialog(true)}
        isLoading={status.loading}
        style={{ marginTop: 40 }}
        fullWidth
      >
        登出
      </Button>
      <Dialog
        open={openLogoutDialog}
        title="您確定要登出？"
        description="登出後需再次登入才能使用服務"
        cancelText="先不要"
        confirmText="登出"
        confirmColorType="error"
        onConfirm={handleLogout}
        onCancel={() => setOpenLogoutDialog(false)}
      /> */}
    </>
  );
};

ItemLink.propTypes = {
  icon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

Customer.propTypes = {
  auth: PropTypes.object.isRequired,
  navigate: PropTypes.func,
};

export default withRouter(Customer);
