import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import withRouter from 'utils/withRouter';
import useAxios from 'hooks/useAxios';
import { canBookAppointment } from 'services';
import ErrorModal from 'components/ErrorModal';
import AppointmentSvg from 'images/AppointmentSvg';
import ManagementSvg from 'images/ManagementSvg';
import { colors, fonts } from 'styles/theme';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  min-height: 100vh;
  padding: 24px 16px;
  margin: -16px;
  background-color: ${colors.SHADES_50};
`;

const LinkCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;
  width: 100%;
  height: 220px;
  border: 1px solid ${colors.SHADES_200};
  border-radius: 16px;
  background-color: ${colors.SHADES_000};
  ${fonts['Heading/H6/Medium']}
`;

const Appointment = ({ auth, navigate }) => {
  const { request } = useAxios();
  const [error, setError] = useState(null);

  const handleCheckAppointmentLimit = async () => {
    request(canBookAppointment(), false)
      .then(() => {
        navigate('/appointment/create');
      })
      .catch((err) => {
        setError(err.message);
      });
  };

  return (
    <Container>
      <LinkCard onClick={handleCheckAppointmentLimit}>
        <AppointmentSvg />
        立即預約
      </LinkCard>
      <LinkCard onClick={() => navigate('/appointment/list')}>
        <ManagementSvg />
        預約管理
      </LinkCard>
      <ErrorModal
        open={!!error}
        description={error}
        buttonText="致電診所"
        onClose={() => setError(null)}
        onButtonClick={() =>
          (window.location.href = `tel: ${auth.clinicPhone}`)
        }
      />
    </Container>
  );
};

Appointment.propTypes = {
  auth: PropTypes.object,
  navigate: PropTypes.func.isRequired,
};

export default withRouter(Appointment);
