import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import dayjs from 'dayjs';
import { colors } from 'styles/theme';
import Text from 'components/Text';
import Input from 'components/Input';
import Button from 'components/Button';
import Radio from 'components/Radio';
import FieldBase from 'components/FieldBase';
import DatePicker from 'components/DatePicker';
import FormControl from 'components/FormControl';

const InfoWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 0;
  border-bottom: 1px solid ${colors.SHADES_100};
`;

const EditContentWrapper = styled.div`
  padding: 16px 0;
  border-bottom: 1px solid ${colors.SHADES_100};
  > span {
    display: block;
    text-align: right;
  }
`;

const Mask = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: ${colors.SHADES_000};
  opacity: 0.5;
`;

const getEditElement = (key, handleChange, elementProps) => {
  switch (key) {
    case 'birthday':
      return (
        <DatePicker
          {...elementProps}
          onChange={(e) => handleChange(key, e.target.value)}
          max={dayjs().format('YYYY-MM-DD')}
          required
        />
      );
    case 'gender':
      return (
        <Radio
          {...elementProps}
          onChange={(value) => handleChange(key, value)}
          items={[
            { label: '男', value: 'M' },
            { label: '女', value: 'F' },
          ]}
          required
        />
      );
    default:
      return (
        <Input
          {...elementProps}
          onChange={(e) => handleChange(key, e.target.value)}
          fullWidth
          type="text"
        />
      );
  }
};

const EditBlock = ({
  label,
  dataKey,
  customerData,
  draftData,
  handleChange,
  editOn,
  setEditOn,
  errors,
  onSave,
  placeholder,
  required,
  isLoading,
  children,
  hideEditButton,
}) => {
  const [originalData] = useState(customerData);
  const key = dataKey;
  const value = draftData?.[key];
  const disabled = editOn && editOn !== key;

  const getDisplayValue = (key) => {
    switch (key) {
      case 'phone':
        return `${customerData.phoneCountryCode} ${customerData.phoneNumber}`;
      case 'gender':
        return customerData.gender === 'M' ? '男' : '女';
      default:
        return customerData[key];
    }
  };

  const handleCancel = () => {
    if (key === 'phone') {
      handleChange('phoneCountryCode', '+886');
      handleChange('phoneNumber', '');
    } else {
      handleChange(key, originalData[key]);
    }
    setEditOn(null);
  };

  return editOn === key ? (
    <EditContentWrapper>
      <Text
        font="Heading/Medium/Medium"
        onClick={handleCancel}
        content="取消"
      />
      <FormControl>
        {children ||
          getEditElement(key, handleChange, {
            label,
            value,
            error: !!errors[key],
            helperText: errors[key],
            placeholder,
            required,
          })}
      </FormControl>
      {key !== 'phone' && (
        <Button
          size="large"
          onClick={() => onSave(key)}
          isLoading={isLoading}
          disabled={!!(disabled || errors[key] || (required && !value))}
        >
          儲存
        </Button>
      )}
    </EditContentWrapper>
  ) : (
    <InfoWrapper>
      <FieldBase label={label} required={required}>
        <Text content={getDisplayValue(key)} />
      </FieldBase>
      {!hideEditButton && (
        <Text
          font="Heading/Medium/Medium"
          onClick={() => setEditOn(key)}
          content="編輯"
          marginLeft={16}
          nowrap
        />
      )}
      {disabled && <Mask />}
    </InfoWrapper>
  );
};

EditBlock.propTypes = {
  label: PropTypes.string,
  draftData: PropTypes.object,
  customerData: PropTypes.object,
  dataKey: PropTypes.string,
  editOn: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  handleChange: PropTypes.func,
  setEditOn: PropTypes.func,
  errors: PropTypes.object,
  onSave: PropTypes.func,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  isLoading: PropTypes.bool,
  children: PropTypes.node,
  hideEditButton: PropTypes.bool,
};

export default memo(EditBlock);
