import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import withRouter from 'utils/withRouter';
import useAxios from 'hooks/useAxios';
import { getCustomer } from 'services';
import Text from 'components/Text';
import Button from 'components/Button';
import LottiePlayer from 'components/LottiePlayer';
import liff from '@line/liff';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - 32px);
`;

const WORDING_MAP = {
  login: {
    title: '登入成功',
    description: '您現在可以回到LINE主畫面使用完整的會員功能',
  },
  register: {
    title: '註冊成功',
    description: '已為您登入，您現在可以回到LINE主畫面使用完整的會員功能',
  },
  appointment: {
    title: '預約成功',
    description:
      '貼心提醒您，預約後如無故爽約，將暫停您的線上服務。\n\n如需取消預約請至>已預約取消您的預約。',
  },
};

const Success = ({ auth, location, navigate }) => {
  const { request } = useAxios();
  const type = location?.state?.type;

  useEffect(() => {
    if (!type) {
      navigate('/login');
    } else {
      window.onpopstate = () => history.forward();
      const { accessToken, lineUserId } = auth;
      if (['login', 'register'].includes(type)) {
        request(getCustomer({ accessToken, lineUserId })).then(
          ({ name, birth }) => {
            liff.sendMessages([
              {
                type: 'text',
                text: `✅ ${name} ${birth} 綁定成功！\n可以啟用線上相關服務囉！`,
              },
            ]);
          }
        );
      }
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return type ? (
    <Container>
      <LottiePlayer type="success" />
      <Text
        font="Heading/H6/Medium"
        marginTop={16}
        marginBottom={16}
        content={WORDING_MAP[type].title}
      />
      <Text
        color="SHADES_600"
        content={WORDING_MAP[type].description}
        marginBottom={32}
        preLine
      />
      {type === 'appointment' ? (
        <Button onClick={() => navigate('/appointment/list')}>
          查看預約記錄
        </Button>
      ) : (
        <Button
          onClick={() => liff.closeWindow()}
          variant="outline"
          color="secondary"
          fullWidth
        >
          回到 LINE 主畫面
        </Button>
      )}
    </Container>
  ) : null;
};

Success.propTypes = {
  auth: PropTypes.object,
  location: PropTypes.object,
  navigate: PropTypes.func,
};

export default withRouter(Success);
