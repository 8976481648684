import React, { useState, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import withRouter from 'utils/withRouter';
import { colors, fonts } from 'styles/theme';
import { findByPhone } from 'services';
import { phoneValidate } from 'utils/customer';
import useAxios from 'hooks/useAxios';
import Text from 'components/Text';
import FormControl from 'components/FormControl';
import Checkbox from 'components/Checkbox';
import Button from 'components/Button';
import TermsModal from 'components/TermsModal';
import PhoneNumberInput from 'components/PhoneNumberInput';

const TabWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 24px;
`;

const Tab = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  padding: 16px 0;
  ${({ active }) =>
    active
      ? `
          color: ${colors.PRIMARY_500};
          border-bottom: 2px solid ${colors.PRIMARY_500};
          ${fonts['Heading/Medium/Medium']};
        `
      : `
          color: ${colors.SHADES_500};
          border-bottom: 2px solid transparent;
          ${fonts['Body/16px_Regular']};
        `}
`;

const CheckboxWrapper = styled.div`
  &,
  & > label {
    display: flex;
    align-content: center;
  }
  margin-bottom: 24px;
`;

const TAB_MAP = {
  login: {
    label: '會員登入',
    title: '登入',
  },
  register: {
    label: '立即註冊',
    title: '註冊',
  },
};

const Login = ({ navigate }) => {
  const { request, status } = useAxios();
  const [tab, setTab] = useState('login');
  const [error, setError] = useState(null);
  const [agreeTerms, setAgreeTerms] = useState(false);
  const [openTerms, setOpenTerms] = useState(false);
  const [phoneCountryCode, setPhoneCountryCode] = useState('+886');
  const [phoneNumber, setPhoneNumber] = useState('');

  const errorMessage = useMemo(() => {
    switch (error) {
      case 'register':
        return '手機已註冊，請使用「會員登入」';
      case 'login':
        return '尚未註冊過的手機號碼，請使用「立即註冊」';
      default:
        return error;
    }
  }, [error]);

  const handleSubmit = async () => {
    setError(null);

    const phoneError = phoneValidate({ phoneCountryCode, phoneNumber });

    if (phoneError) {
      setError(phoneError);
      return;
    }

    const { hisCustomerID, customerExistsInHIS, customerExistsInDB } =
      await request(findByPhone({ phoneCountryCode, phoneNumber }));

    if (tab === 'login') {
      if (customerExistsInHIS && !customerExistsInDB) {
        navigate('/validation', {
          state: {
            type: 'bind',
            phoneCountryCode,
            phoneNumber,
            hisCustomerId: hisCustomerID,
          },
        });
      } else {
        setError('login');
      }
    }

    if (tab === 'register') {
      if (customerExistsInHIS) {
        setError('register');
      } else {
        navigate('/validation', {
          state: { type: 'register', phoneCountryCode, phoneNumber },
        });
      }
    }
  };

  useEffect(() => {
    setAgreeTerms(false);
    setError(null);
  }, [tab]);

  useEffect(() => {
    setError(null);
  }, [phoneCountryCode, phoneNumber]);

  return (
    <>
      <TabWrapper>
        {Object.keys(TAB_MAP).map((key) => (
          <Tab key={key} active={tab === key} onClick={() => setTab(key)}>
            {TAB_MAP[key].label}
          </Tab>
        ))}
      </TabWrapper>
      <Text
        font="Heading/H4/Medium"
        marginBottom={24}
        content={TAB_MAP[tab].title}
      />
      <FormControl>
        <PhoneNumberInput
          label="手機號碼"
          code={phoneCountryCode}
          number={phoneNumber}
          onCodeChange={setPhoneCountryCode}
          onNumberChange={setPhoneNumber}
          error={!!error}
          errorMessage={errorMessage}
        />
      </FormControl>
      {tab === 'register' && (
        <CheckboxWrapper>
          <label>
            <Checkbox checked={agreeTerms} onClick={setAgreeTerms} />
            <Text marginLeft={8} content="我已閱讀並同意" />
          </label>
          <Text
            color="PRIMARY_500"
            marginLeft={4}
            content="服務條款"
            onClick={() => setOpenTerms(true)}
          />
        </CheckboxWrapper>
      )}
      <Button
        size="large"
        fullWidth
        onClick={handleSubmit}
        isLoading={status.loading}
        disabled={tab === 'register' && !agreeTerms}
      >
        {TAB_MAP[tab].label}
      </Button>

      <TermsModal
        open={openTerms}
        onClose={() => setOpenTerms(false)}
        onButtonClick={() => setAgreeTerms(true)}
      />
    </>
  );
};

Login.propTypes = {
  auth: PropTypes.object,
  navigate: PropTypes.func,
};

export default withRouter(Login);
