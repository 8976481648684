import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { setAlert } from 'reducers/globalReducer';
import useAxios from 'hooks/useAxios';
import { findByPhone, updateCustomer } from 'services';
import withRouter from 'utils/withRouter';
import { getUrlParams } from 'utils';
import {
  phoneValidate,
  checkCustomerData,
  covertCustomerDataToApiFormat,
} from 'utils/customer';
import Loading from 'components/Loading';
import Text from 'components/Text';
import Button from 'components/Button';
import FormControl from 'components/FormControl';
import PhoneNumberInput from 'components/PhoneNumberInput';
import ValidationCodeBlock from 'components/ValidationCodeBlock';
import EditBlock from './EditBlock';

const defaultNewPhoneData = {
  phoneCountryCode: '+886',
  phoneNumber: '',
};

const formElementsMap = [
  {
    label: '姓名',
    dataKey: 'name',
    placeholder: '請輸入姓名',
    required: true,
  },
  {
    label: '生日',
    dataKey: 'birthday',
    placeholder: '請選擇生日',
    required: true,
  },
  {
    label: '性別',
    dataKey: 'gender',
    placeholder: '請選擇性別',
    required: true,
  },
  {
    label: '身分/居留證號',
    dataKey: 'idNumber',
    placeholder: '請輸入身分/居留證號',
    required: false,
  },
  {
    label: '地址',
    dataKey: 'address',
    placeholder: '請輸入地址',
    required: false,
  },
  {
    label: '電子信箱',
    dataKey: 'email',
    placeholder: '請輸入電子信箱',
    required: false,
  },
];

const CustomerProfile = ({ auth, location }) => {
  const dispatch = useDispatch();
  const validationBlockRef = useRef();
  const hideProfileEditButton = getUrlParams('hideProfileEditButton');
  const { request, status } = useAxios();
  const [editOn, setEditOn] = useState(null);
  const [errors, setErrors] = useState({});
  const [customerData, setCustomerData] = useState();
  const [draftData, setDraftData] = useState();
  const [validationStatus, setValidationStatus] = useState({});
  const [newPhone, setNewPhone] = useState(defaultNewPhoneData);
  const [newPhoneError, setNewPhoneError] = useState(null);

  const handleChange = (key, value) =>
    setDraftData((prev) => ({ ...prev, [key]: value }));

  const handleSave = (key) => {
    if (!errors[key]) {
      request(
        updateCustomer({
          customerData: covertCustomerDataToApiFormat(draftData),
        })
      ).then(() => {
        dispatch(setAlert({ type: 'success', title: '資料已更新' }));
        setCustomerData(draftData);
        setEditOn(null);
      });
    }
  };

  const checkNewPhoneNumber = async () => {
    setNewPhoneError(null);

    const { phoneCountryCode, phoneNumber } = newPhone;

    const phoneError = phoneValidate({ phoneCountryCode, phoneNumber });

    if (phoneError) {
      setNewPhoneError(phoneError);
      return;
    }

    const { customerExistsInHIS, customerExistsInDB } = await request(
      findByPhone({ phoneCountryCode, phoneNumber })
    );

    if (customerExistsInHIS || customerExistsInDB) {
      setNewPhoneError('此手機號碼已被註冊');
    } else {
      validationBlockRef.current?.handleSendValidation();
    }
  };

  useEffect(() => {
    if (draftData) {
      setErrors(checkCustomerData(draftData));
    }
  }, [draftData]);

  useEffect(() => {
    if (validationStatus?.isValid) {
      const payload = {
        ...draftData,
        ...newPhone,
        passcode: validationStatus.verificationCode,
      };

      request(
        updateCustomer({
          customerData: covertCustomerDataToApiFormat(payload),
        })
      ).then(() => {
        dispatch(setAlert({ type: 'success', title: '資料已更新' }));
        setCustomerData((prev) => ({ ...prev, ...newPhone }));
        setDraftData((prev) => ({ ...prev, ...newPhone }));
        setValidationStatus({});
        setEditOn(null);
      });
    }
  }, [
    auth,
    customerData,
    validationStatus,
    draftData,
    newPhone,
    request,
    dispatch,
  ]);

  useEffect(() => {
    if (location?.state?.customerData) {
      setDraftData(location.state.customerData);
      setCustomerData(location.state.customerData);
    }
  }, [location]);

  return !customerData ? (
    <Loading />
  ) : (
    <>
      <Text
        font="Heading/Large/Medium"
        marginBottom={24}
        content={'個人資訊'}
      />
      {/* phone */}
      <EditBlock
        label="手機號碼"
        dataKey="phone"
        customerData={customerData}
        editOn={editOn}
        setEditOn={setEditOn}
        handleChange={(key, value) => {
          setNewPhone((prev) => ({ ...prev, [key]: value }));
          setValidationStatus({});
        }}
        isLoading={status.loading}
        hideEditButton={hideProfileEditButton}
        required
      >
        <FormControl>
          <PhoneNumberInput
            label="手機號碼"
            code={customerData.phoneCountryCode}
            number={customerData.phoneNumber}
            disabled
          />
        </FormControl>
        <FormControl>
          <PhoneNumberInput
            label="新的手機號碼"
            code={newPhone.phoneCountryCode}
            number={newPhone.phoneNumber}
            onCodeChange={(value) =>
              setNewPhone((prev) => ({ ...prev, phoneCountryCode: value }))
            }
            onNumberChange={(value) =>
              setNewPhone((prev) => ({ ...prev, phoneNumber: value }))
            }
            error={!!newPhoneError}
            errorMessage={newPhoneError}
            disabled={validationStatus?.sentValidation}
          />
        </FormControl>
        {!validationStatus?.sentValidation && (
          <Button
            onClick={checkNewPhoneNumber}
            size="large"
            isLoading={status.loading}
            disabled={!newPhone.phoneNumber}
          >
            繼續
          </Button>
        )}
        <ValidationCodeBlock
          innerRef={validationBlockRef}
          phoneCountryCode={newPhone.phoneCountryCode}
          phoneNumber={newPhone.phoneNumber}
          setValidationStatus={setValidationStatus}
          isLoading={status.loading}
        />
      </EditBlock>
      {/* other field */}
      {formElementsMap.map((data) => (
        <EditBlock
          label={data.label}
          dataKey={data.dataKey}
          customerData={customerData}
          draftData={draftData}
          handleChange={handleChange}
          editOn={editOn}
          setEditOn={setEditOn}
          errors={errors}
          onSave={handleSave}
          placeholder={data.placeholder}
          isLoading={status.loading}
          required={data.required}
          hideEditButton={hideProfileEditButton}
          key={data.dataKey}
        />
      ))}
    </>
  );
};

CustomerProfile.propTypes = {
  auth: PropTypes.object,
  location: PropTypes.object,
};

export default withRouter(CustomerProfile);
