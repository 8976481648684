import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  alert: null,
  errorMessage: null,
  auth: {
    token: null,
    liffId: null,
    lineUserId: null,
    customerId: null,
    clinicPhone: null,
    clinicId: null,
  },
  lineUserProfile: {},
};

export const globalSlice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    setErrorMessage: (state, action) => {
      state.errorMessage = action.payload;
    },
    setAlert: (state, action) => {
      state.alert = action.payload;
    },
    setAuth: (state, action) => {
      state.auth = { ...state.auth, ...action.payload };
    },
    setLineUserProfile: (state, action) => {
      state.lineUserProfile = action.payload;
    },
  },
});

export const { setAuth, setAlert, setErrorMessage, setLineUserProfile } =
  globalSlice.actions;

export default globalSlice.reducer;
