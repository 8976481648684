import React, { memo } from 'react';

const EmptySvg = () => (
  <svg
    width="66"
    height="89"
    viewBox="0 0 66 89"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_935_17424)">
      <path
        d="M60.7898 21.5499C62.2298 24.4399 62.4098 30.5499 58.4298 31.8599C56.4239 32.4918 54.3487 32.8779 52.2498 33.0099C51.4137 33.0722 50.588 33.2334 49.7898 33.4899C48.1298 34.0899 42.4098 36.6699 40.4798 32.3699C40.0412 31.2911 39.8269 30.1343 39.8498 28.9699L39.9198 26.4299H40.0298C40.2685 26.4299 40.4974 26.3351 40.6662 26.1663C40.835 25.9975 40.9298 25.7686 40.9298 25.5299C40.9326 25.4063 40.9087 25.2835 40.8598 25.1699C42.8598 23.7199 44.5098 19.2199 42.4098 17.5499C39.1098 14.9199 37.4098 19.2799 37.4098 19.2799C33.4098 17.8499 29.8998 13.7799 29.8998 13.7799C29.5626 15.2593 29.5015 16.7883 29.7198 18.2899C29.6398 18.2899 26.8398 17.0099 27.2798 12.2899C27.7198 7.56991 31.2798 0.82991 42.8598 0.42991C42.8598 0.42991 48.7198 -0.430089 54.4998 5.06991C57.1998 7.63991 57.8998 10.1499 58.6398 13.9799C59.0417 16.5822 59.7638 19.1248 60.7898 21.5499Z"
        fill="#4271DD"
      />
      <path
        d="M52.2498 33.0101L52.6998 34.7101V35.2801C51.5033 35.2309 50.3052 35.3215 49.1298 35.5501C46.1989 36.2065 43.3049 37.0176 40.4598 37.9801L40.4098 37.8301L39.0298 32.9701L40.4998 32.3701C42.4298 36.6701 48.1498 34.0901 49.8098 33.4901C50.6015 33.2351 51.4205 33.074 52.2498 33.0101Z"
        fill="white"
      />
      <path
        d="M42.3698 17.55C44.4698 19.22 42.7998 23.72 40.8198 25.17C40.7573 25.0227 40.6569 24.8945 40.5289 24.7983C40.4009 24.7021 40.2498 24.6414 40.0909 24.6223C39.9319 24.6032 39.7708 24.6264 39.6236 24.6895C39.4765 24.7526 39.3486 24.8534 39.2529 24.9817C39.1572 25.11 39.097 25.2613 39.0784 25.4203C39.0599 25.5793 39.0836 25.7404 39.1472 25.8873C39.2108 26.0342 39.312 26.1618 39.4406 26.2571C39.5693 26.3523 39.7208 26.412 39.8798 26.43L39.8098 28.97C39.7869 30.1344 40.0013 31.2912 40.4398 32.37L38.9698 32.97C38.9698 32.97 32.5098 35.26 31.7398 31.36C31.4098 29.69 30.2498 26.04 30.2498 26.04L28.0898 25.04L29.6998 20.82C29.6075 19.9792 29.6075 19.1308 29.6998 18.29C29.4816 16.7884 29.5426 15.2595 29.8798 13.78C29.8798 13.78 33.4198 17.85 37.3898 19.28C37.3698 19.28 39.0698 14.92 42.3698 17.55ZM33.0698 20.76C33.0755 20.7337 33.0755 20.7064 33.0698 20.68L32.9098 19.84C32.8991 19.784 32.8773 19.7307 32.8458 19.6831C32.8142 19.6356 32.7735 19.5949 32.7261 19.5632C32.6786 19.5316 32.6253 19.5097 32.5693 19.4988C32.5133 19.488 32.4557 19.4884 32.3998 19.5C32.3451 19.5107 32.2931 19.5323 32.2467 19.5633C32.2004 19.5943 32.1607 19.6343 32.13 19.6808C32.0993 19.7274 32.0782 19.7796 32.0678 19.8344C32.0575 19.8892 32.0582 19.9455 32.0698 20L32.2298 20.84C32.2562 20.9507 32.3234 21.0472 32.418 21.1103C32.5126 21.1734 32.6276 21.1983 32.7398 21.18C32.8341 21.1575 32.9181 21.1037 32.978 21.0275C33.0379 20.9512 33.0702 20.857 33.0698 20.76Z"
        fill="white"
      />
      <path
        d="M33.0598 20.68C33.0655 20.7064 33.0655 20.7336 33.0598 20.76C33.0597 20.8583 33.0259 20.9537 32.964 21.0301C32.9022 21.1065 32.816 21.1594 32.7198 21.18C32.6076 21.1983 32.4926 21.1733 32.398 21.1103C32.3034 21.0472 32.2361 20.9506 32.2098 20.84L32.0498 20C32.0382 19.9455 32.0375 19.8892 32.0478 19.8344C32.0581 19.7796 32.0793 19.7274 32.11 19.6808C32.1407 19.6343 32.1804 19.5943 32.2267 19.5633C32.273 19.5322 32.3251 19.5107 32.3798 19.5C32.4357 19.4884 32.4933 19.488 32.5493 19.4988C32.6053 19.5097 32.6586 19.5315 32.706 19.5632C32.7535 19.5948 32.7942 19.6356 32.8257 19.6831C32.8573 19.7306 32.8791 19.784 32.8898 19.84L33.0598 20.68Z"
        fill="#040000"
      />
      <path
        d="M12.8201 50.6899L19.5701 49.6299C19.9996 49.9872 20.3208 50.4574 20.4974 50.9874C20.6741 51.5175 20.6993 52.0863 20.5701 52.6299C20.331 53.7843 20.331 54.9755 20.5701 56.1299C18.6801 57.0399 13.0701 57.6299 11.0001 57.8199C11.0853 56.9569 10.8775 56.0903 10.4101 55.3599C9.69006 54.6399 5.68006 50.0899 5.35006 48.6699C4.91023 46.6884 4.78872 44.6496 4.99007 42.6299L9.16006 49.3299C9.25006 48.9599 9.38007 48.4299 9.52007 47.7799C9.58007 47.4899 9.64007 47.1899 9.70007 46.8599C10.0601 44.9299 10.6001 42.3999 11.7001 43.2999C12.8001 44.1999 12.0001 47.9399 12.1801 49.6399C12.1878 49.7583 12.2079 49.8756 12.2401 49.9899C12.2659 50.1475 12.3333 50.2955 12.4352 50.4185C12.5371 50.5415 12.67 50.6352 12.8201 50.6899Z"
        fill="white"
      />
      <path
        d="M19.9899 48.3401V48.4101L19.5699 49.6301L12.8199 50.7401C12.6704 50.6831 12.5387 50.5873 12.4385 50.4625C12.3383 50.3377 12.2732 50.1884 12.2499 50.0301C12.2178 49.9158 12.1977 49.7985 12.1899 49.6801H12.2499L19.9899 48.3401Z"
        fill="#EEEDEC"
      />
      <path
        d="M13.06 39.7801C13.0915 39.8229 13.1115 39.873 13.1181 39.9258C13.1247 39.9785 13.1176 40.032 13.0975 40.0812C13.0774 40.1304 13.045 40.1736 13.0034 40.2067C12.9618 40.2398 12.9124 40.2616 12.86 40.2701L7.14998 41.1501C7.09037 41.1583 7.02967 41.1495 6.97484 41.1247C6.92 41.0999 6.87326 41.0602 6.83998 41.0101L5.24998 38.4101C5.22288 38.3663 5.20713 38.3164 5.20414 38.265C5.20115 38.2136 5.21101 38.1622 5.23285 38.1156C5.25468 38.0689 5.2878 38.0285 5.3292 37.9978C5.3706 37.9672 5.41899 37.9474 5.46998 37.9401L11.05 37.2001C11.1071 37.1928 11.165 37.201 11.2178 37.2239C11.2706 37.2468 11.3163 37.2835 11.35 37.3301L13.06 39.7801Z"
        fill="#D2DAE5"
      />
      <path
        d="M4.98991 42.6301L0.909912 36.07L1.37991 34.8H1.44991L9.51991 47.8C9.37991 48.45 9.24991 48.9801 9.15991 49.3501L4.98991 42.6301Z"
        fill="#EEEDEC"
      />
      <path
        d="M9.36993 34.1101L9.29993 34.7401C9.26681 34.9937 9.1508 35.2293 8.96994 35.4101C8.78909 35.591 8.55354 35.707 8.29993 35.7401L5.50993 36.1101C5.32835 36.1335 5.14384 36.115 4.97051 36.056C4.79718 35.9971 4.63964 35.8993 4.50993 35.7701L3.42993 34.6801L9.36993 34.1101Z"
        fill="#091429"
      />
      <path
        d="M40.8199 25.17C42.8199 23.72 44.4699 19.22 42.3699 17.55C39.0699 14.92 37.3699 19.28 37.3699 19.28C33.3699 17.85 29.8599 13.78 29.8599 13.78C29.5226 15.2595 29.4616 16.7884 29.6799 18.29"
        stroke="#091429"
        strokeWidth="0.81"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M40.3498 37.8299L38.9698 32.9699C38.9698 32.9699 32.5098 35.2599 31.7398 31.3599C31.4098 29.6899 30.2498 26.0399 30.2498 26.0399L28.0898 25.0399L29.6998 20.8199C29.6075 19.9791 29.6075 19.1307 29.6998 18.2899V18.2899C29.6198 18.2899 26.8198 17.0099 27.2598 12.2899C27.6998 7.56991 31.2598 0.82991 42.8398 0.42991C42.8398 0.42991 48.6998 -0.430089 54.4798 5.06991C57.1798 7.63991 57.8798 10.1499 58.6198 13.9799C59.0369 16.5737 59.7725 19.1062 60.8098 21.5199C62.2498 24.4099 62.4298 30.5199 58.4498 31.8299C56.4387 32.4736 54.3567 32.8698 52.2498 33.0099C51.4137 33.0722 50.588 33.2334 49.7898 33.4899C48.1298 34.0899 42.4098 36.6699 40.4798 32.3699C40.0412 31.2911 39.8269 30.1343 39.8498 28.9699L39.9198 26.4299"
        stroke="#091429"
        strokeWidth="0.81"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.3398 62.0801C24.1298 57.5001 26.8598 48.9301 29.2498 44.3001C30.0721 42.6768 31.4434 41.3976 33.1198 40.6901C34.8798 39.9401 37.5598 38.9301 40.4198 37.9801C43.2649 37.0176 46.159 36.2065 49.0898 35.5501C50.2653 35.3215 51.4634 35.2309 52.6598 35.2801C53.1148 35.2934 53.5687 35.3302 54.0198 35.3901C54.0198 35.3901 66.0198 41.8101 65.1498 63.3301C64.4198 81.5901 64.4198 87.7201 64.4198 87.7201"
        stroke="#091429"
        strokeWidth="0.81"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.5801 66.0201L21.8401 63.3001C22.0001 62.9401 22.1601 62.5301 22.3401 62.0801"
        stroke="#091429"
        strokeWidth="0.81"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M29.4302 75.8899V87.7199"
        stroke="#091429"
        strokeWidth="0.81"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M32.0298 17.9299C32.719 17.8287 33.4224 17.9588 34.0298 18.2999"
        stroke="#091429"
        strokeWidth="0.81"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M40.82 25.1701C40.869 25.2837 40.8928 25.4065 40.89 25.5301C40.89 25.7688 40.7952 25.9977 40.6264 26.1665C40.4576 26.3353 40.2287 26.4301 39.99 26.4301H39.88C39.7245 26.4094 39.5772 26.3485 39.4525 26.2533C39.3278 26.1581 39.2302 26.032 39.1693 25.8875C39.1084 25.7429 39.0863 25.585 39.1052 25.4293C39.1242 25.2735 39.1835 25.1255 39.2773 24.9998C39.3711 24.8741 39.4961 24.775 39.6399 24.7125C39.7838 24.65 39.9415 24.6262 40.0974 24.6434C40.2533 24.6606 40.402 24.7182 40.5288 24.8106C40.6555 24.903 40.7559 25.0269 40.82 25.1701V25.1701Z"
        stroke="#091429"
        strokeWidth="0.81"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M52.21 33.01L52.66 34.7"
        stroke="#091429"
        strokeWidth="0.81"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.3402 62.0801L20.5802 56.0801C18.6902 56.9901 13.0802 57.5801 11.0102 57.7701L10.2402 57.8401C10.2402 57.8401 14.4002 77.7301 17.4702 79.7201C20.5402 81.7101 24.0602 82.5401 27.1902 79.2301C30.3202 75.9201 35.0202 63.0801 35.0202 63.0801"
        stroke="#091429"
        strokeWidth="0.81"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.0099 57.7399C11.0952 56.8769 10.8873 56.0103 10.4199 55.2799C9.6999 54.5599 5.6899 50.0099 5.3599 48.5899C4.92518 46.6349 4.80033 44.6237 4.9899 42.6299"
        stroke="#091429"
        strokeWidth="0.81"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.96997 50.0301C8.96997 50.0301 9.03997 49.8001 9.15997 49.3701C9.27997 48.9401 9.37997 48.4701 9.51997 47.8201C9.57997 47.5301 9.63997 47.2301 9.69997 46.9001C10.06 44.9701 10.6 42.4401 11.7 43.3401C12.8 44.2401 12 47.9801 12.18 49.6801C12.1877 49.7985 12.2078 49.9158 12.24 50.0301C12.2731 50.1816 12.3448 50.322 12.4482 50.4376C12.5516 50.5532 12.6831 50.6402 12.83 50.6901C13.77 51.1001 15.56 50.8601 16.94 52.9301"
        stroke="#091429"
        strokeWidth="0.81"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.0001 48.3999L19.5701 49.5799L12.8101 50.6899"
        stroke="#091429"
        strokeWidth="0.81"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.14991 49.3701L4.98991 42.6701L0.909912 36.1101L1.37991 34.8401"
        stroke="#091429"
        strokeWidth="0.81"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.2102 42.6201L20.1102 48.3201L19.9902 48.3401L12.2202 49.6701"
        stroke="#091429"
        strokeWidth="0.81"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.1499 36.6799L13.4899 38.6299L14.1099 39.5399"
        stroke="#091429"
        strokeWidth="0.81"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.50995 47.8099L1.44995 34.8399L10.28 33.9399L12.15 36.6799"
        stroke="#091429"
        strokeWidth="0.81"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.1602 36.68C12.3457 36.4677 12.6017 36.3296 12.8809 36.291C13.1602 36.2525 13.444 36.316 13.6802 36.47C15.1502 37.35 14.2102 38.26 13.4902 38.63"
        stroke="#091429"
        strokeWidth="0.81"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.1099 39.54C14.2877 39.3461 14.5206 39.2113 14.7773 39.1535C15.034 39.0958 15.3022 39.1179 15.5459 39.2169C15.7897 39.316 15.9973 39.4871 16.141 39.7075C16.2847 39.9279 16.3576 40.187 16.3499 40.45C16.325 40.6975 16.2087 40.9269 16.0238 41.0933C15.8389 41.2597 15.5986 41.3512 15.3499 41.35"
        stroke="#091429"
        strokeWidth="0.81"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.3899 40.45C16.6316 40.6373 16.812 40.8924 16.9082 41.1827C17.0043 41.473 17.0119 41.7853 16.9299 42.08C16.872 42.2336 16.7626 42.3624 16.6204 42.4444C16.4782 42.5265 16.3119 42.5567 16.1499 42.53"
        stroke="#091429"
        strokeWidth="0.81"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.2099 42.62L16.1399 42.53L15.3399 41.35L14.1099 39.54"
        stroke="#091429"
        strokeWidth="0.81"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.5698 49.6299C19.9993 49.9872 20.3205 50.4574 20.4972 50.9874C20.6739 51.5175 20.699 52.0863 20.5698 52.6299C20.3308 53.7843 20.3308 54.9755 20.5698 56.1299"
        stroke="#091429"
        strokeWidth="0.81"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M31.7598 27.96C32.5916 27.8336 33.375 27.4885 34.0298 26.96"
        stroke="#091429"
        strokeWidth="0.81"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.9801 54.38C24.5801 54.58 24.1601 53.7599 23.8101 53.2599C23.0357 52.276 22.1793 51.3594 21.2501 50.5199C20.6925 49.9395 20.1715 49.325 19.6901 48.68C18.7501 47.43 18.0901 45.9399 17.1101 44.7299C16.4511 44.1085 15.7151 43.5741 14.9201 43.1399C14.0151 42.5133 13.1623 41.8143 12.3701 41.0499C11.9801 40.6599 11.1301 39.87 11.3701 39.22C12.2001 37.14 14.3101 41.28 19.5601 43.15C20.2624 43.3768 20.9341 43.6891 21.5601 44.08C22.4401 44.66 25.4801 48.02 27.2001 48.99C27.2601 48.99 26.2001 51.73 26.1001 51.92C25.8501 52.57 25.6201 53.22 25.3701 53.86C25.3016 54.0719 25.1644 54.2549 24.9801 54.38Z"
        fill="white"
        stroke="#091429"
        strokeWidth="0.81"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.9801 46.9399C21.6601 46.9399 21.2801 47.1099 20.9801 46.9399L18.8501 45.5499C18.3901 45.2499 17.9401 44.9299 17.4801 44.6199C17.0201 44.3099 16.5701 43.7699 15.9301 43.7799C15.7612 43.789 15.5966 43.8365 15.4488 43.9188C15.3011 44.0012 15.1742 44.1163 15.0777 44.2552C14.9812 44.3941 14.9177 44.5533 14.8922 44.7205C14.8666 44.8877 14.8796 45.0585 14.9301 45.2199C15.0914 45.5416 15.3281 45.8196 15.6201 46.0299C16.8864 47.1169 18.0058 48.364 18.9501 49.7399C19.1901 50.0799 19.4101 50.4299 19.6701 50.7399C20.2333 51.4394 20.7607 52.167 21.2501 52.9199C22.0805 54.1962 23.2609 55.2065 24.6501 55.8299L25.9401 52.2999"
        fill="white"
      />
      <path
        d="M21.9801 46.9399C21.6601 46.9399 21.2801 47.1099 20.9801 46.9399L18.8501 45.5499C18.3901 45.2499 17.9401 44.9299 17.4801 44.6199C17.0201 44.3099 16.5701 43.7699 15.9301 43.7799C15.7612 43.789 15.5966 43.8365 15.4488 43.9188C15.3011 44.0012 15.1742 44.1163 15.0777 44.2552C14.9812 44.3941 14.9177 44.5533 14.8922 44.7205C14.8666 44.8877 14.8796 45.0585 14.9301 45.2199C15.0914 45.5416 15.3281 45.8196 15.6201 46.0299C16.8864 47.1169 18.0058 48.364 18.9501 49.7399C19.1901 50.0799 19.4101 50.4299 19.6701 50.7399C20.2333 51.4394 20.7607 52.167 21.2501 52.9199C22.0805 54.1962 23.2609 55.2065 24.6501 55.8299L25.9401 52.2999"
        stroke="#091429"
        strokeWidth="0.81"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_935_17424">
        <rect
          width="65.07"
          height="88.12"
          fill="white"
          transform="translate(0.5)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default memo(EmptySvg);
