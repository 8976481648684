import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import withRouter from 'utils/withRouter';
import useAxios from 'hooks/useAxios';
import Text from 'components/Text';
import FormControl from 'components/FormControl';
import PhoneNumberInput from 'components/PhoneNumberInput';
import ValidationCodeBlock from 'components/ValidationCodeBlock';
import { bindCustomer } from 'services';

const Validation = ({ auth, navigate, location }) => {
  const { request, status } = useAxios();
  const validationBlockRef = useRef();
  const type = location?.state?.type;
  const hisCustomerId = location?.state?.hisCustomerId;
  const lockedPhoneCountryCode = location?.state?.phoneCountryCode;
  const lockedPhoneNumber = location?.state?.phoneNumber;
  const [validationStatus, setValidationStatus] = useState({});

  const handleSendValidation = () =>
    validationBlockRef.current.handleSendValidation();

  useEffect(() => {
    if (status.loading) return;

    if (validationStatus?.isValid) {
      if (type === 'bind') {
        request(
          bindCustomer({
            clientData: {
              clinicID: auth.clinicId,
              countryCode: lockedPhoneCountryCode,
              hisCustomerID: hisCustomerId,
              passcode: validationStatus.verificationCode,
              phone: lockedPhoneNumber,
            },
          })
        ).then(() => navigate('/success', { state: { type: 'login' } }));
      }
      if (type === 'register') {
        navigate('/register', {
          state: {
            phoneCountryCode: lockedPhoneCountryCode,
            phoneNumber: lockedPhoneNumber,
            passcode: validationStatus.verificationCode,
          },
        });
      }
    }
  }, [
    auth,
    request,
    navigate,
    type,
    hisCustomerId,
    validationStatus,
    lockedPhoneCountryCode,
    lockedPhoneNumber,
    status.loading,
  ]);

  useEffect(() => {
    handleSendValidation();
  }, [lockedPhoneCountryCode, lockedPhoneNumber]);

  return (
    <>
      <Text font="Heading/H4/Medium" marginBottom={24} content="驗證" />
      <FormControl>
        <PhoneNumberInput
          label="驗證手機號碼"
          code={lockedPhoneCountryCode}
          number={lockedPhoneNumber}
          disabled
        />
      </FormControl>
      <ValidationCodeBlock
        innerRef={validationBlockRef}
        phoneCountryCode={lockedPhoneCountryCode}
        phoneNumber={lockedPhoneNumber}
        setValidationStatus={setValidationStatus}
        isLoading={status.loading}
        fullWidth
      />
    </>
  );
};

Validation.propTypes = {
  auth: PropTypes.object,
  navigate: PropTypes.func,
  location: PropTypes.object,
};

export default withRouter(Validation);
