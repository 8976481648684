import React, { memo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { colors, fonts } from 'styles/theme';
import Text from 'components/Text';
import Button from 'components/Button';

const Wrapper = styled.div`
  width: 100%;
  padding: 16px;
  margin-bottom: 16px;
  background: ${colors.SHADES_000};
  border: 1px solid ${colors.SHADES_200};
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06),
    0px 0px 1px rgba(0, 0, 0, 0.04);
  border-radius: 16px;
  button {
    margin-top: 16px;
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
`;

const FlexWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const Hr = styled.hr`
  border: none;
  border-top: 1px solid #f1f5f9;
`;

export const ClinicLabel = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 88px;
  padding: 4px 16px;
  color: ${colors.SHADES_500};
  background-color: ${colors.SHADES_100};
  border-radius: 100px;
  ${fonts['Heading/Small/Regular']};
`;

const Status = styled.div`
  color: ${({ status }) => {
    switch (status) {
      case 'scheduled':
      case 'confirmed':
        return colors.PRIMARY_500;
      case 'cancelled':
      case 'completed':
      case 'expired':
      default:
        return colors.SHADES_300;
    }
  }};
  ${fonts['Heading/Small/Heavy']};
`;

const getStatusName = (status) => {
  switch (status) {
    case 'scheduled':
      return '已預約';
    case 'confirmed':
      return '已確定';
    case 'cancelled':
      return '已取消';
    case 'completed':
      return '已完成';
    case 'expired':
      return '已過期';
    default:
      return status;
  }
};

const AppointmentCard = ({ data, onCancel }) => {
  const { status, clinic, room, date, time, doctor, services } = data;

  return (
    <Wrapper>
      <Header>
        <Status status={status}>{getStatusName(status)}</Status>
        <ClinicLabel>{clinic}</ClinicLabel>
      </Header>
      {room && (
        <Text
          font="Heading/Large/Medium"
          marginBottom={8}
          content={room}
          block
        />
      )}
      {services && (
        <FlexWrapper>
          {services.map((service, index) => (
            <Text
              font="Heading/X-Small/Regular"
              marginBottom={8}
              content={`${index ? ', ' : ''}${service.name}`}
              block
              key={service.id}
            />
          ))}
        </FlexWrapper>
      )}
      <Hr />
      <Text font="Heading/Large/Medium" marginBottom={8} content={date} block />
      <Text
        font="Heading/Large/Medium"
        marginBottom={8}
        content={`${time} ${doctor ? `${doctor}醫師` : ''}`}
        block
      />
      {['scheduled', 'confirmed'].includes(status) && (
        <Button
          variant="outline"
          color="secondary"
          onClick={onCancel}
          fullWidth
        >
          取消預約
        </Button>
      )}
    </Wrapper>
  );
};

AppointmentCard.propTypes = {
  data: PropTypes.object.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default memo(AppointmentCard);
