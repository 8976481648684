import React, { memo } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const StyledDiv = styled.div`
  width: 100%;
  margin-bottom: ${({ margin }) => (margin === 'none' ? '0' : `${margin}px`)};
  ${({ flex }) =>
    flex &&
    `
      display: flex;
      align-items: center;
  `};
`;

const FormControl = ({ children, margin = 24, flex }) => (
  <StyledDiv margin={margin} flex={flex}>
    {children}
  </StyledDiv>
);

FormControl.propTypes = {
  children: PropTypes.node,
  margin: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  flex: PropTypes.bool,
};
export default memo(FormControl);
