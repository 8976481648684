import React, { memo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Button from 'components/Button';
import Modal from 'components/Modal';
import Text from 'components/Text';
import LottiePlayer from 'components/LottiePlayer';

const ModalContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  button {
    margin-top: 32px;
  }
`;

const ErrorModal = ({
  open = false,
  title,
  description,
  showButton = true,
  buttonText,
  onClose = () => {},
  onButtonClick = () => {},
}) => (
  <Modal open={open} onClose={onClose}>
    <ModalContentWrapper>
      <LottiePlayer type="error" />
      <Text
        font="Heading/H6/Medium"
        marginTop={16}
        marginBottom={16}
        content={title}
      />
      <Text color="SHADES_600" content={description} />
      {showButton && (
        <Button size="large" onClick={onButtonClick} fullWidth>
          {buttonText}
        </Button>
      )}
    </ModalContentWrapper>
  </Modal>
);

ErrorModal.propTypes = {
  open: PropTypes.bool,
  title: PropTypes.string,
  description: PropTypes.string,
  buttonText: PropTypes.string,
  showButton: PropTypes.bool,
  onClose: PropTypes.func,
  onButtonClick: PropTypes.func,
};

export default memo(ErrorModal);
