import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { assignCoupon } from 'services';
import withRouter from 'utils/withRouter';
import useAxios from 'hooks/useAxios';
import LottiePlayer from 'components/LottiePlayer';
import Text from 'components/Text';
import Loading from 'components/Loading';
import Button from 'components/Button';
import ErrorSvg from 'images/ErrorSvg';
import { getUrlParams } from 'utils';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - 32px);
`;

const CouponAssign = ({ auth, navigate }) => {
  const { request, status } = useAxios();
  const [isSuccess, setIsSuccess] = useState(false);

  useEffect(() => {
    const couponId = getUrlParams('couponId');
    if (!couponId) return;

    request(
      assignCoupon({
        couponData: { amount: 1, couponID: couponId, clinicID: auth.clinicId },
      })
    ).then(() => {
      setIsSuccess(true);
    });
  }, [request, auth.clinicId]);

  return status.loading ? (
    <Loading />
  ) : (
    <Wrapper>
      {isSuccess && (
        <>
          <LottiePlayer type="box" />
          <Text
            font="Heading/H6/Medium"
            content="領取成功"
            marginTop={32}
            marginBottom={32}
          />
          <Button onClick={() => navigate('/coupons')} fullWidth>
            查看專屬療程
          </Button>
        </>
      )}
      {!isSuccess && (
        <>
          <ErrorSvg />
          <Text font="Heading/H6/Medium" content="領取失敗" marginTop={32} />
        </>
      )}
    </Wrapper>
  );
};

CouponAssign.propTypes = {
  auth: PropTypes.object,
  navigate: PropTypes.func,
};

export default withRouter(CouponAssign);
