import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Loading from 'components/Loading';

/* eslint-disable react/display-name  */
/* eslint-disable react/prop-types  */
const withRouter = (WrappedComponent) => (props) => {
  const auth = useSelector((state) => state.global.auth);
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);

  // keep search params when redirect url
  const wrappedNavigate = (path, options) => {
    navigate(`${path}${location.search || ''}`, options);
  };

  useEffect(() => {
    if (auth.token) {
      if (props.checkAuth && !auth.customerId) {
        alert('請先登入');
        navigate(`/login${location.search || ''}`);
      } else {
        setIsLoading(false);
      }
    }
  }, [auth, navigate, props.checkAuth, location.search]);

  return (
    <>
      <Helmet>
        <title>{props.title}</title>
      </Helmet>
      {isLoading ? (
        <Loading />
      ) : (
        <WrappedComponent
          {...props}
          auth={auth}
          navigate={wrappedNavigate}
          location={location}
        />
      )}
    </>
  );
};

export default withRouter;
