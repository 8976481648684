import React, { memo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { getDisplayDate } from 'utils';
import { colors, fonts } from 'styles/theme';
import Text from 'components/Text';
import Button from 'components/Button';

const Wrapper = styled.div`
  position: relative;
  filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.1));
  &:not(:last-child) {
    margin-bottom: 24px;
  }
`;

const Ticket = styled.div`
  position: relative;
  width: 100%;
  max-width: 320px;
  border-radius: 8px;
  text-align: center;
`;

const Title = styled.div`
  padding: 28px 16px 8px 16px;
  background-color: ${colors.SHADES_000};
`;

const CutLine = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 32px;
  background-color: ${colors.SHADES_000};
  mask-image: radial-gradient(circle at 12px 16px, transparent 12px, red 12px);
  mask-position: -12px;
  margin: -1px 0;
  &:after {
    content: '';
    position: absolute;
    border-top: dashed 1px ${colors.SHADES_300};
    width: 100%;
  }
  ${({ used }) =>
    used &&
    `
      height: 16px;
      align-items: baseline;
      mask-image: radial-gradient(circle at 12px 0px, transparent 12px, red 12px);
    `}
`;

const Content = styled.div`
  padding: 8px 16px 16px 16px;
  background-color: ${colors.SHADES_000};
`;

const InfoBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
  ${fonts['Heading/X-Small/Regular']};
  div {
    color: ${colors.SHADES_500};
  }
  span {
    text-align: right;
  }
`;

const Hint = styled.div`
  display: block;
  padding: 16px;
  margin-bottom: 16px;
  background-color: ${colors.SHADES_100};
  color: ${colors.SHADES_500};
  text-align: left;
  div {
    ${fonts['Heading/X-Small/Regular']};
    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }
`;

const CouponTicket = ({ data, navigate }) => {
  const used = data.status.code === 'used';
  const shouldShowButton = data.status.code === 'unused';
  return (
    <Wrapper>
      <Ticket>
        {!used && (
          <Title>
            <Text font="Heading/Medium/Medium" content={data.name} />
          </Title>
        )}
        <CutLine used={used} />
        <Content>
          <InfoBlock>
            <div>專屬療程</div>
            <span>{data.name}</span>
          </InfoBlock>
          <InfoBlock>
            <div>使用期限</div>
            <span>{`${getDisplayDate(data.gotTime)}-${getDisplayDate(
              data.expireDate
            )}`}</span>
          </InfoBlock>
          <Hint>
            <div>1. 來店7日前先行預約</div>
            <div>2. 到店或預約時請告知您要使用專屬療程</div>
            <div>3. 最終使用辦法以現場工作人員解釋為主</div>
            <div>4. 如需轉贈請洽門店服務人員</div>
          </Hint>
          {shouldShowButton && (
            <Button onClick={() => navigate('/appointment/create')} fullWidth>
              立即預約
            </Button>
          )}
        </Content>
      </Ticket>
    </Wrapper>
  );
};

CouponTicket.propTypes = {
  data: PropTypes.object,
  navigate: PropTypes.func,
};

export default memo(CouponTicket);
