import React, { memo } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import Text from 'components/Text';
import FieldBase from 'components/FieldBase';
import { colors } from 'styles/theme';

const stateColorSetting = (color, bgColor, borderColor = color) => css`
  color: ${color} !important;
  border-color: ${borderColor} !important;
  background-color: ${bgColor} !important;
  ${StyledInput} {
    color: ${color};
    -webkit-text-fill-color: ${color};
    ::placeholder,
    ::-webkit-input-placeholder {
      color: ${color};
    }
    :-ms-input-placeholder {
      color: ${color};
    }
  }
`;

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  max-width: ${({ fullWidth, width }) => (fullWidth ? '100%' : `${width}px`)};
  border: 1px solid ${colors.SHADES_400};
  border-radius: 8px;
  background-color: ${colors.SHADES_000};
  font-size: 16px;
  transition: 0.3s;
  overflow: hidden;
  input {
    opacity: 1;
    ::placeholder,
    ::-webkit-input-placeholder {
      color: ${colors.SHADES_300};
    }
    :-ms-input-placeholder {
      color: ${colors.SHADES_300};
    }
  }
  &,
  > * {
    ${({ warning }) =>
      warning && stateColorSetting(colors.WARNING_500, colors.WARNING_100)}
    ${({ success }) =>
      success &&
      stateColorSetting(colors.SYSTEM_SUCCESS_500, colors.SYSTEM_SUCCESS_100)}
    ${({ error }) =>
      error &&
      stateColorSetting(colors.SYSTEM_ERROR_500, colors.SYSTEM_ERROR_100)}
    ${({ disabled }) =>
      disabled &&
      stateColorSetting(
        colors.SHADES_400,
        colors.SHADES_100,
        colors.SHADES_200
      )}
  }
`;

const InputWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  i {
    margin-right: 16px;
    color: ${colors.SHADES_500};
    cursor: pointer;
  }
`;

const Adornment = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  padding: 0 12px;
  line-height: 1;
  transition: 0.3s;
  color: ${colors.SHADES_400};
  ${({ decoration, position }) => {
    if (decoration) {
      if (position === 'start' && ['both', 'start'].includes(decoration))
        return `border-right: 1px solid ${colors.SHADES_400};`;
      if (position === 'end' && ['both', 'end'].includes(decoration))
        return `border-left: 1px solid ${colors.SHADES_400};`;
    }
  }}
  i {
    font-size: 16px;
  }
`;

const StyledInput = styled.input`
  width: 100%;
  border: none;
  font-size: 16px;
  background-color: transparent;
  letter-spacing: 0.02em;
  padding: 9px 16px;
  outline: none;
  ${({ prefix, decoration }) =>
    prefix &&
    ['both', 'start'].includes(decoration) &&
    `padding-left: ${decoration ? '8px' : '0'};`}
  ${({ suffix, decoration }) =>
    suffix &&
    ['both', 'end'].includes(decoration) &&
    `padding-right: ${decoration ? '8px' : '0'}`}
`;

const Input = ({
  type = 'text',
  name,
  label,
  value,
  width = 320,
  margin,
  marginTop,
  marginRight,
  marginBottom,
  marginLeft,
  helperText,
  optionalLabel,
  required,
  fullWidth,
  prefix,
  suffix,
  error,
  success,
  warning,
  disabled,
  decoration = 'both',
  innerRef,
  showClearButton,
  onInputClear,
  maxLength,
  ...restProps
}) => {
  return (
    <FieldBase
      fullWidth={fullWidth}
      margin={margin}
      marginTop={marginTop}
      marginRight={marginRight}
      marginBottom={marginBottom}
      marginLeft={marginLeft}
      required={required}
      name={name}
      label={label}
      width={width}
      helperText={helperText}
      optionalLabel={optionalLabel}
      error={error}
    >
      <Wrapper
        fullWidth={fullWidth}
        error={error}
        success={success}
        warning={warning}
        disabled={disabled}
      >
        {prefix && (
          <Adornment decoration={decoration} position="start">
            {prefix}
          </Adornment>
        )}
        <InputWrapper>
          <StyledInput
            type={type}
            name={name}
            value={value}
            prefix={prefix}
            suffix={suffix}
            decoration={decoration}
            ref={innerRef}
            maxLength={maxLength}
            disabled={disabled}
            autoComplete="off"
            {...restProps}
          />
          {maxLength && (
            <Text font="EN/Body/13/Medium" color="SHADES_500" marginRight={16}>
              {maxLength - ((value && value.length) || 0)}
            </Text>
          )}
          {!disabled && showClearButton && onInputClear && (
            <i className="ri-close-line" onClick={onInputClear} />
          )}
        </InputWrapper>
        {suffix && (
          <Adornment decoration={decoration} position="end">
            {suffix}
          </Adornment>
        )}
      </Wrapper>
    </FieldBase>
  );
};

Input.propTypes = {
  type: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  width: PropTypes.number,
  margin: PropTypes.string,
  marginTop: PropTypes.number,
  marginRight: PropTypes.number,
  marginBottom: PropTypes.number,
  marginLeft: PropTypes.number,
  helperText: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
    PropTypes.node,
  ]),
  optionalLabel: PropTypes.string,
  required: PropTypes.bool,
  fullWidth: PropTypes.bool,
  error: PropTypes.bool,
  success: PropTypes.bool,
  warning: PropTypes.bool,
  disabled: PropTypes.bool,
  decoration: PropTypes.oneOf(['both', 'start', 'end', 'none']),
  prefix: PropTypes.element,
  suffix: PropTypes.element,
  showClearButton: PropTypes.bool,
  maxLength: PropTypes.number,
  onInputClear: PropTypes.func,
  innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
};
export default memo(Input);
