import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { checkIn } from 'services';
import withRouter from 'utils/withRouter';
import useAxios from 'hooks/useAxios';
import LottiePlayer from 'components/LottiePlayer';
import Text from 'components/Text';
import Loading from 'components/Loading';
import ErrorSvg from 'images/ErrorSvg';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - 32px);
`;

const CheckIn = () => {
  const { request, status } = useAxios();
  const [checkInSuccess, setCheckInSuccess] = useState(false);

  useEffect(() => {
    request(checkIn()).then(() => {
      setCheckInSuccess(true);
    });
  }, [request]);

  return status.loading ? (
    <Loading />
  ) : (
    <Wrapper>
      {checkInSuccess && (
        <>
          <LottiePlayer type="box" />
          <Text font="Heading/H6/Medium" content="簽到成功" marginTop={32} />
        </>
      )}
      {!checkInSuccess && (
        <>
          <ErrorSvg />
          <Text font="Heading/H6/Medium" content="簽到失敗" marginTop={32} />
        </>
      )}
    </Wrapper>
  );
};

CheckIn.propTypes = {
  auth: PropTypes.object,
};

export default withRouter(CheckIn);
