import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import withRouter from 'utils/withRouter';
import useAxios from 'hooks/useAxios';
import { createCustomer } from 'services';
import {
  checkCustomerData,
  covertCustomerDataToApiFormat,
  defaultCustomerData,
} from 'utils/customer';
import Text from 'components/Text';
import Input from 'components/Input';
import Button from 'components/Button';
import Radio from 'components/Radio';
import DatePicker from 'components/DatePicker';
import FormControl from 'components/FormControl';
import PhoneNumberInput from 'components/PhoneNumberInput';

const Register = ({ auth, location, navigate }) => {
  const { request, status } = useAxios();
  const { phoneCountryCode, phoneNumber, passcode } = location?.state || {};
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState({
    ...defaultCustomerData,
    phoneCountryCode,
    phoneNumber,
    clinicId: auth?.clinicId,
  });

  const handleChange = (key, value) => {
    setFormData((prev) => ({ ...prev, [key]: value }));
  };

  const handleSubmit = () => {
    setIsSubmitted(true);
    const checkResult = checkCustomerData(formData);
    if (Object.keys(checkResult).length > 0) {
      setErrors(checkResult);
    } else {
      request(
        createCustomer({
          customerData: covertCustomerDataToApiFormat({
            ...formData,
            passcode,
          }),
        })
      ).then(() => navigate('/success', { state: { type: 'register' } }));
    }
  };

  useEffect(() => {
    if (isSubmitted) {
      setErrors(checkCustomerData(formData));
    }
  }, [isSubmitted, formData]);

  return (
    <>
      <Text font="Heading/H4/Medium" marginBottom={24} content={'基本資料'} />
      <FormControl>
        <PhoneNumberInput
          label="已綁定的手機號碼"
          code={formData.phoneCountryCode}
          number={formData.phoneNumber}
          error={!!errors.phoneNumber}
          errorMessage={errors.phoneNumber}
          disabled
        />
      </FormControl>
      <FormControl>
        <Input
          label="姓名"
          value={formData.name}
          onChange={(e) => handleChange('name', e.target.value)}
          error={!!errors.name}
          helperText={errors.name}
          placeholder="Ex:許翔評"
          required
          fullWidth
          type="text"
        />
      </FormControl>
      <FormControl>
        <Input
          label="身分/居留證字號"
          value={formData.idNumber}
          onChange={(e) => handleChange('idNumber', e.target.value)}
          error={!!errors.idNumber}
          helperText={errors.idNumber}
          placeholder="Ex:A120222345"
          fullWidth
          type="text"
        />
      </FormControl>
      <FormControl>
        <DatePicker
          label="生日"
          value={formData.birthday}
          onChange={(e) => handleChange('birthday', e.target.value)}
          max={dayjs().format('YYYY-MM-DD')}
          error={!!errors.birthday}
          helperText={errors.birthday}
          placeholder="Ex:1991/01/01"
          required
        />
      </FormControl>
      <FormControl>
        <Radio
          label="性別"
          value={formData.gender}
          items={[
            { label: '男', value: 'M' },
            { label: '女', value: 'F' },
          ]}
          onChange={(value) => handleChange('gender', value)}
          required
        />
      </FormControl>
      <FormControl>
        <Input
          label="聯絡地址"
          value={formData.address}
          onChange={(e) => handleChange('address', e.target.value)}
          error={!!errors.address}
          helperText={errors.address}
          placeholder="道路街名"
          fullWidth
          type="text"
        />
      </FormControl>
      <FormControl>
        <Input
          label="電子信箱"
          value={formData.email}
          onChange={(e) => handleChange('email', e.target.value)}
          error={!!errors.email}
          helperText={errors.email}
          decoration="none"
          marginBottom={8}
          fullWidth
          placeholder="example@mail.com"
          type="email"
        />
      </FormControl>
      <Button
        size="large"
        onClick={handleSubmit}
        isLoading={status.loading}
        fullWidth
      >
        確認
      </Button>
    </>
  );
};

Register.propTypes = {
  auth: PropTypes.object,
  location: PropTypes.object,
  navigate: PropTypes.func,
};

export default withRouter(Register);
