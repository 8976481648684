import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import styled from 'styled-components';
import withRouter from 'utils/withRouter';
import useAxios from 'hooks/useAxios';
import { useDispatch } from 'react-redux';
import { setAlert } from 'reducers/globalReducer';
import {
  getAppointments,
  cancelAppointment,
  getExecutionItems,
} from 'services';
import { getDisplayDate, getDisplayHourAndMinute } from 'utils';
import { colors } from 'styles/theme';
import EmptySvg from 'images/EmptySvg';
import Tabs from 'components/Tabs';
import Loading from 'components/Loading';
import Text from 'components/Text';
import Dialog from 'components/Dialog';
import AppointmentCard from 'components/AppointmentCard';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-top: 48px;
`;

const EmptyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
`;

const Header = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 16px 16px 0 16px;
  background-color: ${colors.SHADES_000};
`;

const Content = styled.div`
  margin-top: 24px;
`;

const tabItems = [
  { id: 2, name: '已預約', value: 'pending' },
  { id: 3, name: '已結束', value: 'completed' },
];

const groupList = (listData, itemIds) => {
  const sortDescending = (a, b) => b.end - a.end;
  const { pending, completed } = listData.reduce(
    (group, data) => {
      const { id, clinic, room, doctor, services, start, status, end } = data;
      const isExpired =
        !['completed', 'cancelled'].includes(status.code) &&
        dayjs().isAfter(dayjs(end).format('YYYY-MM-DD'), 'day');

      if (isExpired) {
        Object.assign(status, {
          code: 'expired',
          name: '已過期',
        });
      }

      const appointment = {
        id,
        status: status.code,
        clinic: clinic?.name,
        room: room?.name,
        doctor: doctor?.name,
        date: getDisplayDate(start, true),
        time: getDisplayHourAndMinute(start),
        services: services?.filter((service) => itemIds.includes(service.id)),
        end,
      };

      const type = ['completed', 'cancelled', 'expired'].includes(status?.code)
        ? 'completed'
        : 'pending';

      group[type].push(appointment);

      return group;
    },
    { pending: [], completed: [] }
  );

  return {
    pending: pending.sort(sortDescending),
    completed: completed.sort(sortDescending),
  };
};

const AppointmentList = ({ auth }) => {
  const dispatch = useDispatch();
  const { request, status } = useAxios();
  const [currentTab, setCurrentTab] = useState(tabItems[0]);
  const [appointmentList, setAppointmentList] = useState(null);
  const [cancelData, setCancelData] = useState(null);

  const getAppointmentList = useCallback(async () => {
    const { treatments } = await request(getExecutionItems());
    const listData = [];
    const itemIds = treatments.map((treatment) => treatment.item.id);

    const fetchList = async (page) => {
      const { appointments, pageObject } = await request(
        getAppointments({ clinicId: auth?.clinicId, page })
      );
      listData.push(...appointments);
      if (!pageObject.last) {
        await fetchList(page + 1);
      } else {
        setAppointmentList(groupList(listData, itemIds));
      }
    };
    fetchList();
  }, [request, auth?.clinicId]);

  const handleCancel = async () => {
    request(
      cancelAppointment({
        clinicId: auth.clinicId,
        appointmentId: cancelData.id,
      })
    )
      .then(async () => {
        dispatch(setAlert({ type: 'success', title: '取消預約成功！' }));
        getAppointmentList();
      })
      .finally(() => {
        setCancelData(null);
      });
  };

  useEffect(() => {
    getAppointmentList();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return !appointmentList ? (
    <Loading />
  ) : (
    <Wrapper>
      <Header>
        <Tabs
          currentTab={currentTab}
          tabs={tabItems}
          onChange={setCurrentTab}
        />
      </Header>
      <Content>
        {Object.keys(appointmentList || {}).length &&
        appointmentList[currentTab.value].length ? (
          appointmentList[currentTab.value].map((data) => (
            <AppointmentCard
              data={data}
              onCancel={() => setCancelData(data)}
              key={data.id}
            />
          ))
        ) : (
          <EmptyWrapper>
            <EmptySvg />
            <Text
              color="SHADES_400"
              content="您目前尚無未來預約。"
              marginTop={24}
              block
            />
          </EmptyWrapper>
        )}
      </Content>
      <Dialog
        open={!!cancelData}
        title="您確定要取消預約？"
        description="取消後無法復原，若確認取消請點擊下方按鈕"
        cancelText="先不要"
        confirmText="我要取消"
        confirmColorType="error"
        onConfirm={handleCancel}
        onCancel={() => setCancelData(null)}
        isLoading={status.loading}
      />
    </Wrapper>
  );
};

AppointmentList.propTypes = {
  auth: PropTypes.object,
};

export default withRouter(AppointmentList);
