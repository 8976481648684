import React, { useState, useRef, useEffect, memo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { fonts, colors } from 'styles/theme';
import FieldBase from 'components/FieldBase';

const SelectorWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  > div,
  > i {
    font-size: 14px;
    color: ${colors.SHADES_400};
  }
  > div {
    white-space: nowrap;
  }
  > i {
    margin-left: 16px;
  }
  ${({ variant }) => {
    if (variant === 'select') {
      return `
        border: 1px solid ${colors.SHADES_400};
        border-radius: 8px;
        padding: 8px 16px;
        justify-content: space-between;
        line-height: 1.25;
        min-height: 40px;
        > div {
          color: ${colors.SHADES_900};
        }
      `;
    }
  }}
  ${({ disabled }) =>
    disabled &&
    `
      &,
      & > div,
      & > i {
        cursor: not-allowed;
        color: ${colors.SHADES_400};
        border-color:  ${colors.SHADES_200};
      }
  `}
  ${({ error }) =>
    error &&
    ` &, 
      & > div,
      & > i {
        color: ${colors.SYSTEM_ERROR_500};
        border-color: ${colors.SYSTEM_ERROR_500};
        background-color: ${colors.SYSTEM_ERROR_100};
      }
  `}
`;

const OptionsBackdrop = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  background-color: rgba(0, 0, 0, 0.3);
  z-index: ${({ zIndex }) => zIndex};
`;

const Mask = styled.div`
  position: fixed;
  left: 0;
  bottom: 56px;
  height: 24px;
  width: 100%;
  background: linear-gradient(
    rgba(255, 255, 255, 0.3),
    rgba(255, 255, 255) 100%
  );
`;

const OptionsWrapper = styled.div`
  position: absolute;
  bottom: 56px;
  width: 100%;
  max-height: 50%;
  padding: 16px 16px 8px 16px;
  background-color: ${colors.SHADES_000};
  border-radius: 16px 16px 0 0;
  text-align: center;
  overflow: auto;
`;

const Option = styled.div`
  width: 100%;
  padding: 16px;
  border-radius: 8px;
  color: ${colors.SHADES_900};
  ${({ selected }) =>
    selected &&
    `
      color: ${colors.PRIMARY_400};
      background-color: ${colors.SHADES_50};
  `};
  &:not(:last-child) {
    margin-bottom: 8px;
  }
`;

const Button = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 56px;
  padding: 12px 24px;
  color: ${colors.SHADES_000};
  background-color: ${colors.PRIMARY_500};
  ${fonts['Body/19px_Medium']};
`;

const Select = ({
  label,
  name,
  options,
  selectedOption,
  setSelectedOption,
  placeholder = '請選擇',
  variant = 'select', // select, text
  showArrow = true,
  width,
  fullWidth,
  zIndex = 100,
  margin,
  marginTop,
  marginRight,
  marginBottom,
  marginLeft,
  required,
  helperText,
  optionalLabel,
  error,
  disabled,
}) => {
  const optionsRef = useRef();
  const selectedOptionRef = useRef();
  const [isOpen, setIsOpen] = useState(false);
  const [currentOption, setCurrentOption] = useState(selectedOption);

  const handleSelect = () => {
    setSelectedOption(currentOption);
    setIsOpen(false);
  };

  useEffect(() => {
    if (optionsRef.current && isOpen && selectedOption) {
      const optionWrapper = optionsRef.current;
      const selectedOptionElement = selectedOptionRef.current;
      if (
        selectedOptionElement &&
        optionWrapper.scrollHeight > optionWrapper.offsetHeight
      ) {
        const selectedOptionHeight = selectedOptionElement.offsetHeight;
        const selectedOptionPosition = selectedOptionElement.offsetTop;
        optionsRef.current.scroll({
          top: selectedOptionPosition - selectedOptionHeight * 3,
        });
      }
    }
  }, [isOpen, selectedOption]);

  useEffect(() => {
    document.body.style.overflow = isOpen ? 'hidden' : '';
  }, [isOpen]);

  useEffect(() => () => (document.body.style.overflow = ''), []);

  return (
    <FieldBase
      label={label}
      name={name}
      width={width}
      fullWidth={fullWidth}
      margin={margin}
      marginTop={marginTop}
      marginRight={marginRight}
      marginBottom={marginBottom}
      marginLeft={marginLeft}
      required={required}
      helperText={helperText}
      optionalLabel={optionalLabel}
      error={error}
    >
      <SelectorWrapper
        onClick={() => !disabled && setIsOpen(true)}
        variant={variant}
        disabled={disabled}
        error={error}
      >
        <div>{selectedOption?.name || placeholder}</div>
        {showArrow ? (
          <i
            className={isOpen ? 'ri-arrow-up-s-line' : 'ri-arrow-down-s-line'}
          />
        ) : null}
      </SelectorWrapper>
      {isOpen && (
        <OptionsBackdrop onClick={() => setIsOpen(false)} zIndex={zIndex}>
          <OptionsWrapper ref={optionsRef} onClick={(e) => e.stopPropagation()}>
            {options.map((option) => {
              const isSelected =
                `${currentOption?.id + currentOption?.name}` ===
                `${option?.id + option?.name}`;
              return (
                <Option
                  selected={isSelected}
                  onClick={() => setCurrentOption(option)}
                  ref={isSelected ? selectedOptionRef : null}
                  key={`${option.id + option.name}`}
                >
                  {option.name}
                </Option>
              );
            })}
            <Mask />
            <Button onClick={handleSelect}>確定</Button>
          </OptionsWrapper>
        </OptionsBackdrop>
      )}
    </FieldBase>
  );
};

const optionType = PropTypes.shape({
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
});

Select.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  options: PropTypes.arrayOf(optionType),
  selectedOption: optionType,
  setSelectedOption: PropTypes.func,
  variant: PropTypes.string,
  placeholder: PropTypes.string,
  showArrow: PropTypes.bool,
  width: PropTypes.number,
  zIndex: PropTypes.number,
  fullWidth: PropTypes.bool,
  margin: PropTypes.string,
  marginTop: PropTypes.number,
  marginRight: PropTypes.number,
  marginBottom: PropTypes.number,
  marginLeft: PropTypes.number,
  required: PropTypes.bool,
  helperText: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
    PropTypes.node,
  ]),
  optionalLabel: PropTypes.string,
  error: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default memo(Select);
