import React, { memo } from 'react';

const ManagementSvg = () => (
  <svg
    width="120"
    height="120"
    viewBox="0 0 120 120"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_293_19476)">
      <g clipPath="url(#clip1_293_19476)">
        <path
          d="M52.69 28.6499C52.28 27.8299 50.85 23.1299 47.58 25.1699C47.58 25.1699 46.2 22.4199 44.01 23.2899C44.01 23.2899 42.26 23.5099 41.38 26.1299C40.57 28.5599 38.18 29.2399 36.13 29.8499C35.9366 30.7884 35.7931 31.7364 35.7 32.6899C35.49 34.5299 34.86 34.2899 34.39 33.5599C33.7323 32.5696 33.2236 31.4881 32.88 30.3499C32.2893 28.3499 32.2478 26.2277 32.7598 24.206C33.2718 22.1844 34.3185 20.3378 35.79 18.8599C42.15 12.2899 54.62 15.6499 56.69 24.4999C57.1359 26.4385 57.0215 28.4639 56.36 30.3399C56.0225 31.2511 55.3649 32.0082 54.51 32.4699C54.06 32.6899 53.1 29.4699 52.69 28.6499Z"
          fill="#4271DD"
        />
        <path
          d="M30.4301 52.12C32.9446 50.1065 35.5758 48.2433 38.3101 46.54L39.0901 46.65C39.0301 46.7 38.6901 47 38.2101 47.45C37.8801 47.75 37.5001 48.12 37.1001 48.52C37.1001 48.52 38.6101 51.44 40.5501 55.27C42.1801 58.48 44.1101 62.33 45.7001 65.63V76.45L35.2401 66.12L36.5001 62.77L34.0401 61.55C32.9701 60.08 30.9401 53.73 30.5201 52.37L30.4301 52.12Z"
          fill="white"
        />
        <path
          d="M44.4501 57.74L45.7001 55.8L46.5 58.97C45.977 59.7881 45.6994 60.739 45.7001 61.71V65.61C44.1101 62.31 42.18 58.46 40.55 55.25L44.4501 57.74Z"
          fill="white"
        />
        <path
          d="M45.1 41.95C45.4346 41.9657 45.7683 41.904 46.0753 41.7697C46.3822 41.6355 46.6541 41.4323 46.8697 41.176C47.0854 40.9197 47.2392 40.6171 47.319 40.2918C47.3988 39.9664 47.4025 39.6271 47.33 39.3H42.47C42.02 39.89 42.67 41.96 45.1 41.95ZM48.2 32.44C48.0691 32.4426 47.9445 32.4965 47.8528 32.5899C47.7612 32.6834 47.7099 32.8091 47.71 32.94V34.03C47.7086 34.0952 47.7203 34.16 47.7443 34.2206C47.7684 34.2812 47.8043 34.3364 47.8499 34.383C47.8955 34.4295 47.95 34.4665 48.0101 34.4918C48.0702 34.517 48.1348 34.53 48.2 34.53H48.25C48.3826 34.53 48.5097 34.4773 48.6035 34.3836C48.6973 34.2898 48.75 34.1626 48.75 34.03V32.94C48.75 32.8074 48.6973 32.6802 48.6035 32.5865C48.5097 32.4927 48.3826 32.44 48.25 32.44H48.2ZM41.26 32.44C41.1273 32.44 41.0002 32.4927 40.9064 32.5865C40.8126 32.6802 40.76 32.8074 40.76 32.94V34.03C40.76 34.1626 40.8126 34.2898 40.9064 34.3836C41.0002 34.4773 41.1273 34.53 41.26 34.53H41.31C41.3752 34.53 41.4397 34.517 41.4998 34.4918C41.5599 34.4665 41.6144 34.4295 41.66 34.383C41.7057 34.3364 41.7415 34.2812 41.7656 34.2206C41.7896 34.16 41.8013 34.0952 41.8 34.03V32.94C41.8 32.8091 41.7487 32.6834 41.6571 32.5899C41.5654 32.4965 41.4408 32.4426 41.31 32.44H41.26ZM31.86 31.92C32.07 29.92 32.86 30.37 32.86 30.37H32.93C33.2735 31.5081 33.7823 32.5896 34.44 33.58C34.91 34.31 35.54 34.58 35.75 32.71C35.8431 31.7564 35.9866 30.8084 36.18 29.87C38.18 29.26 40.62 28.58 41.43 26.15C42.31 23.53 44.06 23.31 44.06 23.31C46.25 22.44 47.63 25.19 47.63 25.19C50.9 23.19 52.33 27.85 52.74 28.67C53.15 29.49 54.11 32.67 54.55 32.49C55.4048 32.0283 56.0625 31.2711 56.4 30.36H56.55C56.66 30.36 57.29 30.04 57.48 31.92C57.69 33.97 56.48 36.71 53.83 37.03C53.83 37.03 52.46 41.38 51.23 43.03C50.8092 43.5569 50.3256 44.0304 49.79 44.44C48.616 45.4663 47.1655 46.1237 45.62 46.33H43.71C42.6162 46.2306 41.5744 45.8174 40.71 45.14C39.7845 44.4919 38.9026 43.7837 38.07 43.02C36.55 41.66 35.56 37.02 35.56 37.02C32.25 37.23 31.66 33.97 31.86 31.92Z"
          fill="white"
        />
        <path
          d="M77.62 110.27L46.79 112.58L24.39 104.44C24.4246 103.882 24.6378 103.351 24.998 102.924C25.3582 102.497 25.8463 102.198 26.39 102.07L37.39 101.16L45.72 103.88V105C45.72 105 46.42 106.94 50.22 107.71C50.8534 107.968 51.5383 108.074 52.22 108.02L76.17 106.35L76.59 106.23L77.73 110.23L77.62 110.27Z"
          fill="#E1E7F0"
        />
        <path
          d="M113.46 116.11V116.33L63.5799 119.88L48.9399 114.44V114.43V112.77L63.5799 118.21L113.46 114.66V116.11Z"
          fill="white"
        />
        <path
          d="M113.46 114.65L63.5799 118.21L48.9399 112.77L77.7899 110.71H77.8599L78.3699 112.47C78.4989 112.944 78.7467 113.378 79.0899 113.73L79.6499 114.31L93.1399 113.12L92.1399 109.71L98.7899 109.22L113.46 114.65Z"
          fill="white"
        />
        <path
          d="M96.32 104.86L90.79 105.22L86.97 92.64C86.8137 92.1627 86.5001 91.7525 86.0806 91.4764C85.661 91.2004 85.1602 91.0747 84.66 91.12L75.66 91.92C75.3547 91.9436 75.0579 92.0316 74.789 92.1781C74.5202 92.3246 74.2853 92.5263 74.1 92.77L73.72 93.15C73.5152 93.3517 73.369 93.6052 73.2968 93.8834C73.2246 94.1616 73.2291 94.4542 73.31 94.73L76.6 106.28L76.18 106.4L52.19 108.02C51.5083 108.074 50.8234 107.968 50.19 107.71C49.3571 107.376 48.6433 106.801 48.1406 106.057C47.638 105.314 47.3696 104.437 47.37 103.54V62.41C47.3682 61.2613 47.8068 60.1555 48.5955 59.3203C49.3842 58.4851 50.463 57.984 51.61 57.92L108.5 54.67C108.95 54.644 109.402 54.6844 109.84 54.79L111.02 55.28C111.696 55.6691 112.258 56.2282 112.651 56.9018C113.044 57.5754 113.254 58.3402 113.26 59.12V99.54C113.262 100.684 112.828 101.785 112.045 102.62C111.263 103.454 110.192 103.959 109.05 104.03L96.32 104.86Z"
          fill="white"
        />
        <path
          d="M111.03 55.28L109.85 54.79C110.265 54.8968 110.662 55.0618 111.03 55.28Z"
          fill="white"
        />
        <path
          d="M100.29 105.81V108.58L91.96 109.2L90.76 105.22L96.32 104.86L100.29 105.81Z"
          fill="#E1E7F0"
        />
        <path
          d="M92.1101 109.69L93.1101 113.1L79.5901 114.29L73.7101 94.71C73.6074 94.3719 73.5865 94.0143 73.649 93.6666C73.7116 93.3189 73.8559 92.991 74.0701 92.71C74.2554 92.4662 74.4903 92.2645 74.7591 92.118C75.028 91.9715 75.3248 91.8836 75.6301 91.86L84.6301 91.06C85.1354 91.017 85.6402 91.1484 86.0605 91.4322C86.4807 91.7161 86.7912 92.1352 86.9401 92.62L90.7901 105.22L91.9901 109.22L92.1101 109.69Z"
          fill="white"
        />
        <path
          d="M87.9901 52.1201V54.3701L75.0601 55.1201V52.1201C75.0601 52.1201 75.2901 52.1901 75.7101 52.2801C77.6001 52.7301 83.1201 53.8101 87.1101 52.4701C87.4097 52.3699 87.7035 52.2531 87.9901 52.1201Z"
          fill="white"
        />
        <path
          d="M83 28.2101C83.3045 28.1494 83.6199 28.1774 83.9089 28.2909C84.1979 28.4044 84.4482 28.5985 84.63 28.8501C84.994 29.3098 85.2718 29.8315 85.45 30.3901C85.58 30.7401 85.69 31.0901 85.79 31.4401C85.9555 31.4847 86.1114 31.5592 86.25 31.6601C86.6679 32.0501 86.9545 32.5603 87.07 33.1201C87.5553 34.4505 87.6829 35.885 87.44 37.2801C86.95 39.1501 85.44 40.6101 85.44 42.5701C85.4266 44.9136 85.6005 47.2544 85.96 49.5701C85.96 49.9701 86.05 50.3701 86.1 50.7501C80.43 52.4801 77.29 50.6001 77.29 50.6001V47.5001C75.5 46.1801 73.29 44.6001 73.29 44.6001C73.29 44.6001 72.76 41.4401 73.29 39.8601C73.29 39.8601 72.56 37.5401 74.29 36.3401C74.4617 36.5615 74.6837 36.7386 74.9377 36.8569C75.1916 36.9752 75.4701 37.0311 75.75 37.0201C76.1794 38.2719 76.2556 39.6179 75.97 40.9101C76.217 40.9803 76.4578 41.0706 76.69 41.1801C77.23 40.4701 78.69 38.5601 78.76 38.3501C78.76 38.3501 78.76 37.9201 78.76 37.4301L79.93 37.4901C80.17 36.5401 80.46 35.6101 80.7 34.6601C80.7 34.4801 80.79 34.3001 80.85 34.1101C81.056 33.5421 81.1674 32.9442 81.18 32.3401C81.18 31.1301 80.98 29.9301 80.93 28.7201C80.8956 28.3663 80.933 28.0091 81.04 27.6701C81.1021 27.5182 81.2051 27.3864 81.3374 27.2894C81.4698 27.1923 81.6264 27.1337 81.79 27.1201C81.9763 27.1472 82.1511 27.2264 82.2944 27.3484C82.4377 27.4705 82.5436 27.6305 82.6 27.8101C82.6693 27.9097 82.7327 28.0132 82.79 28.1201L83 28.2101Z"
          fill="white"
        />
        <path
          d="M86.07 50.7399C86.07 50.3599 85.97 49.9599 85.93 49.5599H85.99C86.39 49.4499 87.08 50.2099 87.11 50.3399V52.4899C83.11 53.8299 77.6 52.7499 75.71 52.2999V50.2999C75.71 50.2999 76.32 50.0699 77.26 49.7999V50.5799C77.26 50.5799 80.4 52.4699 86.07 50.7399Z"
          fill="white"
        />
        <path
          d="M80.4001 33.99L80.7901 34.12C80.7301 34.31 80.6801 34.49 80.6401 34.67C80.4001 35.62 80.1101 36.55 79.8701 37.5L78.7001 37.44C78.6906 37.0247 78.7208 36.6095 78.7901 36.2C78.4808 36.0165 78.2037 35.7834 77.9701 35.51C77.9701 35.51 77.0801 37.02 75.8201 37.02H75.6501C75.3702 37.031 75.0917 36.9751 74.8377 36.8568C74.5838 36.7385 74.3617 36.5613 74.1901 36.34C74.1034 36.1574 74.0799 35.9512 74.1233 35.7539C74.1668 35.5565 74.2747 35.3792 74.4301 35.25C75.0862 34.6759 75.6886 34.0433 76.2301 33.36C76.31 33.2382 76.4181 33.1374 76.5451 33.066C76.6721 32.9947 76.8144 32.9549 76.9601 32.95L78.6201 32.87C78.8521 32.8576 79.0805 32.9325 79.2601 33.08L80.4001 33.99Z"
          fill="white"
        />
        <path
          d="M73.7099 94.71L79.6199 114.29L79.0599 113.71C78.7167 113.358 78.4689 112.924 78.3399 112.45L77.7899 110.69L77.6599 110.24L76.5199 106.24L73.2699 94.68C73.1929 94.407 73.1903 94.1184 73.2624 93.8441C73.3345 93.5698 73.4787 93.3198 73.6799 93.12L74.0599 92.74C73.8522 93.0184 73.7124 93.3415 73.6516 93.6835C73.5908 94.0256 73.6108 94.377 73.7099 94.71Z"
          fill="white"
        />
        <path
          d="M68.12 55.5601L56.87 56.2401C56.87 53.7201 56.77 51.2401 56.73 50.3101L67.07 54.7101C67.4911 54.8921 67.8542 55.1861 68.12 55.5601Z"
          fill="white"
        />
        <path
          d="M56.73 50.31C56.73 51.21 56.87 53.72 56.87 56.24L52.6 56.49C52.6389 54.0433 52.5019 51.597 52.19 49.17C51.5026 48.5211 50.6843 48.0267 49.79 47.72V46.98H50.63C50.63 46.98 55.79 49.12 56.72 49.95C56.72 49.95 56.72 50.08 56.72 50.31H56.73Z"
          fill="white"
        />
        <path
          d="M49.85 49.62L49.72 49.54V47.72C50.6249 48.0228 51.4538 48.5174 52.15 49.17C52.4618 51.597 52.5988 54.0433 52.56 56.49H52.2C51.53 55.42 49.66 52.87 48.2 52.2L48.12 52.12L49.85 49.62Z"
          fill="white"
        />
        <path
          d="M52.24 56.5101L50.5 56.6201C49.6951 56.6684 48.9131 56.9066 48.2179 57.315C47.5227 57.7234 46.934 58.2906 46.5 58.9701L45.7 55.8001L47.79 52.5701L48.12 52.1001L48.29 52.2201C49.7 52.8901 51.57 55.4401 52.24 56.5101Z"
          fill="#121930"
        />
        <path
          d="M49.79 47.7199V49.5399L49.92 49.6199L48.12 52.1199L47.79 52.5899L39.22 47.5199L38.22 47.4499C38.7 46.9999 39.04 46.6999 39.1 46.6499C39.4225 46.5312 39.7183 46.3499 39.9706 46.1165C40.2229 45.8832 40.4265 45.6022 40.57 45.2899L40.66 45.1699C41.5244 45.8473 42.5662 46.2606 43.66 46.3599H45.59C47.1494 46.1508 48.6115 45.4824 49.79 44.4399L49.87 44.5399L49.79 46.9799V47.7199Z"
          fill="white"
        />
        <path
          d="M48.79 32.9399V34.0299C48.79 34.1625 48.7373 34.2897 48.6435 34.3835C48.5498 34.4773 48.4226 34.5299 48.29 34.5299H48.24C48.1748 34.53 48.1102 34.517 48.0501 34.4917C47.99 34.4665 47.9355 34.4295 47.8899 34.3829C47.8443 34.3363 47.8084 34.2811 47.7844 34.2205C47.7603 34.1599 47.7486 34.0951 47.75 34.0299V32.9399C47.75 32.809 47.8013 32.6834 47.8929 32.5899C47.9845 32.4964 48.1091 32.4426 48.24 32.4399H48.29C48.4226 32.4399 48.5498 32.4926 48.6435 32.5864C48.7373 32.6802 48.79 32.8073 48.79 32.9399Z"
          fill="#121930"
        />
        <path
          d="M47.79 52.5699L45.7 55.7999L44.45 57.7399L40.55 55.2499C38.61 51.4199 37.1 48.4999 37.1 48.4999C37.5 48.0999 37.88 47.7299 38.21 47.4299L39.21 47.4999L47.79 52.5699Z"
          fill="white"
        />
        <path
          d="M47.33 39.3C47.4026 39.6271 47.3989 39.9665 47.319 40.2918C47.2392 40.6172 47.0855 40.9197 46.8698 41.1761C46.6541 41.4324 46.3823 41.6355 46.0753 41.7698C45.7684 41.904 45.4347 41.9657 45.1 41.9501C42.67 41.9501 42.02 39.89 42.47 39.32L47.33 39.3Z"
          fill="white"
        />
        <path
          d="M45.6999 91.1201V103.88L37.37 101.12L21.9299 96.1201C21.4973 95.9286 21.1091 95.6494 20.79 95.3001C20.2524 94.7215 19.9401 93.9693 19.91 93.1801C19.69 87.5501 19.07 72.4901 18.74 69.7801C18.32 66.4301 18.74 61.4101 19.89 59.3201C19.9261 59.2491 19.9697 59.182 20.0199 59.1201L25.0199 59.2101C25.0199 59.2101 30.8799 62.9701 33.1799 69.0401V71.0401C33.1799 71.0401 33.39 80.5301 32.97 82.2101C33.4612 82.6019 33.8375 83.119 34.0593 83.7068C34.2811 84.2947 34.34 84.9315 34.2299 85.5501C34.2299 85.5501 41.7899 89.5301 45.6999 91.1201Z"
          fill="white"
        />
        <path
          d="M45.7 76.4501V91.1201C41.81 89.5001 34.2 85.5301 34.2 85.5301C34.3101 84.9115 34.2511 84.2747 34.0293 83.6868C33.8076 83.0989 33.4312 82.5818 32.94 82.1901C33.36 80.5101 33.15 71.0201 33.15 71.0201V69.0201C30.85 62.9501 24.99 59.1901 24.99 59.1901L19.99 59.1001C21.6 56.9101 30.51 52.3401 30.51 52.3401C30.93 53.7001 32.96 60.0501 34.03 61.5201L36.49 62.7401L35.24 66.1201L45.7 76.4501Z"
          fill="white"
        />
        <path
          d="M41.79 32.9399V34.0299C41.7913 34.0951 41.7796 34.1599 41.7556 34.2205C41.7316 34.2811 41.6957 34.3363 41.6501 34.3829C41.6044 34.4295 41.55 34.4665 41.4899 34.4917C41.4297 34.517 41.3652 34.53 41.3 34.5299H41.25C41.1174 34.5299 40.9902 34.4773 40.8964 34.3835C40.8027 34.2897 40.75 34.1625 40.75 34.0299V32.9399C40.75 32.8073 40.8027 32.6802 40.8964 32.5864C40.9902 32.4926 41.1174 32.4399 41.25 32.4399H41.3C41.4309 32.4426 41.5555 32.4964 41.6471 32.5899C41.7387 32.6834 41.79 32.809 41.79 32.9399Z"
          fill="#121930"
        />
        <path
          d="M40.55 55.25L44.44 57.75L45.7 55.81L47.79 52.57L39.22 47.5"
          stroke="#121930"
          strokeWidth="0.84"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M47.79 52.5699L48.12 52.0999L49.84 49.6299L50.19 49.1299"
          stroke="#121930"
          strokeWidth="0.84"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M52.25 56.53C51.58 55.46 49.71 52.91 48.25 52.24"
          stroke="#121930"
          strokeWidth="0.84"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M49.72 47.7C50.6249 48.0028 51.4538 48.4974 52.15 49.1499C52.4618 51.577 52.5988 54.0233 52.56 56.4699V56.4699"
          stroke="#121930"
          strokeWidth="0.84"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M39.0301 46.0601L38.3101 46.5201C35.5753 48.2298 32.944 50.0997 30.4301 52.1201L30.5201 52.3801C30.9401 53.7401 32.9701 60.0901 34.0401 61.5601L36.5001 62.7801L35.2401 66.1201L45.7001 76.4501"
          stroke="#121930"
          strokeWidth="0.84"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M37.1 48.5C37.1 48.5 38.61 51.42 40.55 55.25C42.18 58.46 44.11 62.31 45.7 65.61"
          stroke="#121930"
          strokeWidth="0.84"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M56.73 50.3101C56.73 51.2101 56.87 53.7201 56.87 56.2401"
          stroke="#121930"
          strokeWidth="0.84"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M50.62 47C50.62 47 55.79 49.12 56.72 49.95C56.72 49.95 56.72 50.08 56.72 50.31"
          stroke="#121930"
          strokeWidth="0.84"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M56.73 50.3101L67.07 54.7101C67.4728 54.8997 67.8179 55.193 68.07 55.5601V55.6201"
          stroke="#121930"
          strokeWidth="0.84"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M30.5201 52.3601C30.5201 52.3601 21.6101 56.9301 20.0001 59.1201C19.9498 59.182 19.9062 59.249 19.8701 59.3201C18.7201 61.4101 18.3001 66.4301 18.7201 69.7801C19.0501 72.4901 19.6701 87.5501 19.8901 93.1801C19.9257 93.9719 20.2452 94.7245 20.7901 95.3001C21.116 95.648 21.5112 95.9239 21.9501 96.1101L37.3701 101.12L45.7001 103.84"
          stroke="#121930"
          strokeWidth="0.84"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M24.99 59.21C24.99 59.21 30.85 62.97 33.15 69.04"
          stroke="#121930"
          strokeWidth="0.84"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M33.1499 71.05C33.1499 71.05 33.3599 80.54 32.9399 82.22C33.4312 82.6118 33.8075 83.1289 34.0293 83.7168C34.2511 84.3046 34.31 84.9415 34.1999 85.5601C34.1999 85.5601 41.8099 89.56 45.6999 91.15"
          stroke="#121930"
          strokeWidth="0.84"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M32.45 85.2701C30.183 85.1817 27.9441 85.7964 26.04 87.0301"
          stroke="#121930"
          strokeWidth="0.84"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M75.0601 55.1201V52.1201C75.0601 52.1201 75.2901 52.1901 75.7101 52.2801C77.6001 52.7301 83.1201 53.8101 87.1101 52.4701C87.4097 52.3699 87.7035 52.2531 87.9901 52.1201V54.4101"
          stroke="#121930"
          strokeWidth="0.84"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M111.03 55.28L109.85 54.79L108.56 54.26C106.975 53.6178 105.267 53.3344 103.56 53.43L88.0299 54.36L75.0599 55.12L68.12 55.54L56.87 56.22L52.6 56.47H52.24L50.5 56.58C49.2017 56.6565 47.9818 57.226 47.0896 58.1722C46.1973 59.1183 45.7002 60.3695 45.7 61.67V105C45.7 105 46.4 106.94 50.2 107.71"
          stroke="#121930"
          strokeWidth="0.84"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M90.79 105.22L96.35 104.86L109.08 104.03C110.222 103.959 111.293 103.454 112.075 102.62C112.858 101.785 113.292 100.684 113.29 99.54V59.12C113.292 58.3343 113.087 57.562 112.696 56.8808C112.304 56.1996 111.74 55.6337 111.06 55.24C110.692 55.0217 110.295 54.8567 109.88 54.75C109.442 54.6444 108.99 54.6039 108.54 54.63L51.62 57.92C50.473 57.9839 49.3942 58.4851 48.6055 59.3203C47.8168 60.1555 47.3782 61.2612 47.38 62.41V103.54C47.3796 104.437 47.648 105.314 48.1507 106.057C48.6533 106.801 49.3671 107.376 50.2 107.71C50.8334 107.968 51.5183 108.074 52.2 108.02L76.15 106.35"
          stroke="#121930"
          strokeWidth="0.84"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M74.06 92.72L73.68 93.1C73.4752 93.3017 73.329 93.5551 73.2568 93.8333C73.1846 94.1116 73.1892 94.4041 73.27 94.68L76.56 106.23L77.7 110.23L77.83 110.68L78.34 112.44C78.4689 112.914 78.7167 113.348 79.06 113.7L79.62 114.28"
          stroke="#121930"
          strokeWidth="0.84"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M92.1101 109.69L93.1101 113.1L79.5901 114.29L73.7101 94.71C73.6074 94.3719 73.5865 94.0143 73.649 93.6666C73.7116 93.3189 73.8559 92.991 74.0701 92.71C74.2554 92.4662 74.4903 92.2645 74.7591 92.118C75.028 91.9715 75.3248 91.8836 75.6301 91.86L84.6301 91.06C85.1354 91.017 85.6402 91.1484 86.0605 91.4322C86.4807 91.7161 86.7912 92.1352 86.9401 92.62L90.7901 105.22L91.9901 109.22L92.1101 109.69Z"
          stroke="#121930"
          strokeWidth="0.84"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M48.9399 114.44L63.5799 119.88L113.46 116.33"
          stroke="#121930"
          strokeWidth="0.84"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M77.7599 110.72L48.9399 112.77L63.5799 118.21L113.46 114.66L98.8099 109.22L92.1099 109.7"
          stroke="#121930"
          strokeWidth="0.84"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M48.9399 114.43V112.77"
          stroke="#121930"
          strokeWidth="0.84"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M113.46 114.66V116.11"
          stroke="#121930"
          strokeWidth="0.84"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M46.74 109.83L27.08 102.72"
          stroke="#121930"
          strokeWidth="0.84"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M91.96 109.2L100.29 108.58V105.81L96.32 104.86L96.11 104.81"
          stroke="#121930"
          strokeWidth="0.84"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M37.37 101.12L26.37 102.03C25.8263 102.158 25.3382 102.457 24.978 102.884C24.6178 103.311 24.4046 103.843 24.37 104.4L46.79 112.58L77.66 110.27"
          stroke="#121930"
          strokeWidth="0.84"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.42004 96.44L20.76 95.3"
          stroke="#121930"
          strokeWidth="0.84"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M56.35 30.3399C56.0125 31.2511 55.3549 32.0082 54.5 32.4699C54.06 32.6899 53.1 29.4699 52.69 28.6499C52.28 27.8299 50.85 23.1199 47.58 25.1699C47.58 25.1699 46.2 22.4199 44.01 23.2899C44.01 23.2899 42.26 23.5099 41.38 26.1299C40.57 28.5599 38.18 29.2399 36.13 29.8499C35.9366 30.7884 35.7931 31.7364 35.7 32.6899C35.49 34.5299 34.86 34.2899 34.39 33.5599C33.7323 32.5696 33.2236 31.4881 32.88 30.3499C32.2893 28.3499 32.2478 26.2277 32.7598 24.206C33.2718 22.1844 34.3185 20.3378 35.79 18.8599C42.15 12.2899 54.62 15.6499 56.69 24.4999C57.1327 26.4395 57.0148 28.4649 56.35 30.3399V30.3399Z"
          stroke="#121930"
          strokeWidth="0.84"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M32.7901 30.3701C32.7901 30.3701 32.0501 29.8801 31.7901 31.9201C31.5301 33.9601 32.1801 37.2301 35.4501 37.0301C35.4501 37.0301 36.45 41.6601 37.96 43.0301C38.8161 43.786 39.7214 44.4842 40.6701 45.1201C41.5345 45.7975 42.5763 46.2107 43.6701 46.3101H45.5901C47.144 46.1152 48.6054 45.4645 49.7901 44.4401C50.3192 44.0261 50.7961 43.5493 51.2101 43.0201C52.4401 41.3801 53.8101 37.0201 53.8101 37.0201C56.4701 36.7001 57.6701 33.9601 57.4601 31.9101C57.2701 30.0301 56.6401 30.3001 56.5301 30.3501"
          stroke="#121930"
          strokeWidth="0.84"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M43.74 32.76L44.6 37.08"
          stroke="#121930"
          strokeWidth="0.84"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M39.4 31.12C39.6542 30.8241 39.9792 30.5974 40.3446 30.461C40.7101 30.3246 41.1041 30.283 41.49 30.34"
          stroke="#121930"
          strokeWidth="0.84"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M50.08 31.12C49.8258 30.8241 49.5008 30.5974 49.1354 30.461C48.7699 30.3246 48.3759 30.283 47.99 30.34"
          stroke="#121930"
          strokeWidth="0.84"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M40.58 45.26C40.4366 45.5723 40.2329 45.8532 39.9806 46.0866C39.7284 46.32 39.4325 46.5013 39.11 46.62V46.62C39.05 46.67 38.71 46.97 38.23 47.42C37.9 47.72 37.52 48.09 37.12 48.49"
          stroke="#121930"
          strokeWidth="0.84"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M49.84 44.55L49.78 46.98L49.76 47.7201L49.72 49.5401"
          stroke="#121930"
          strokeWidth="0.84"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M85.99 49.5299C86.39 49.4199 87.08 50.1799 87.11 50.3099V52.4599"
          stroke="#121930"
          strokeWidth="0.84"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M75.71 52.27V50.27C75.71 50.27 76.32 50.04 77.26 49.77"
          stroke="#121930"
          strokeWidth="0.84"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M75.6799 37.02C76.1094 38.2718 76.1855 39.6178 75.8999 40.91C76.1469 40.9802 76.3877 41.0705 76.6199 41.18C77.0448 41.3441 77.4326 41.5914 77.7606 41.9073C78.0885 42.2233 78.3501 42.6016 78.5299 43.02"
          stroke="#121930"
          strokeWidth="0.84"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M74.2199 36.3401C72.5399 37.5401 73.2199 39.8601 73.2199 39.8601C72.6899 41.4401 73.2199 44.6001 73.2199 44.6001C73.2199 44.6001 75.4199 46.1801 77.2199 47.5001V50.5901C77.2199 50.5901 80.3599 52.4701 86.0299 50.7401"
          stroke="#121930"
          strokeWidth="0.84"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M76.6201 41.18C77.1601 40.47 78.6201 38.56 78.6901 38.35C78.6901 38.35 78.6901 37.92 78.6901 37.43C78.6846 37.0177 78.718 36.6059 78.7901 36.2C78.4808 36.0165 78.2037 35.7834 77.9701 35.51C77.9701 35.51 77.0801 37.02 75.8201 37.02H75.6501C75.3702 37.031 75.0917 36.9751 74.8377 36.8568C74.5838 36.7385 74.3617 36.5613 74.1901 36.34C74.1034 36.1574 74.0799 35.9512 74.1233 35.7539C74.1668 35.5565 74.2747 35.3792 74.4301 35.25C75.0862 34.6759 75.6886 34.0433 76.2301 33.36C76.31 33.2382 76.4181 33.1374 76.5451 33.066C76.6721 32.9947 76.8144 32.9549 76.9601 32.95L78.6201 32.87C78.8521 32.8576 79.0805 32.9325 79.2601 33.08L80.3701 33.99"
          stroke="#121930"
          strokeWidth="0.84"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M82.7901 28.1201C82.7489 27.9869 82.6988 27.8566 82.6401 27.7301C82.5686 27.5599 82.4513 27.4128 82.3013 27.3051C82.1512 27.1974 81.9743 27.1334 81.7901 27.1201C81.6149 27.1297 81.4465 27.1907 81.3059 27.2957C81.1654 27.4007 81.059 27.5449 81.0001 27.7101C80.8931 28.0491 80.8557 28.4063 80.8901 28.7601C80.8901 29.9701 81.1001 31.1701 81.1401 32.3801C81.1179 32.9752 80.9997 33.5627 80.7901 34.1201C80.7301 34.3101 80.6801 34.4901 80.6401 34.6701C80.4001 35.6201 80.1101 36.5501 79.8701 37.5001"
          stroke="#121930"
          strokeWidth="0.84"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M81.1599 38.29C81.3543 37.5889 81.64 36.9165 82.0099 36.29C82.3941 35.4172 82.6371 34.489 82.7299 33.54C82.8895 32.5833 82.8895 31.6067 82.7299 30.65C82.5999 30.08 82.2199 29.23 82.5099 28.65C82.6026 28.4528 82.7571 28.2913 82.9499 28.19C83.2544 28.1293 83.5699 28.1573 83.8588 28.2708C84.1478 28.3842 84.3981 28.5783 84.5799 28.83C84.9439 29.2896 85.2217 29.8114 85.3999 30.37C85.5299 30.72 85.6399 31.07 85.7399 31.42"
          stroke="#121930"
          strokeWidth="0.84"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M85.93 49.5399C85.5705 47.2241 85.3966 44.8834 85.41 42.5399C85.49 40.5399 86.95 39.1199 87.41 37.2499C87.6571 35.8671 87.5399 34.4437 87.07 33.1199C86.9449 32.578 86.6591 32.0866 86.25 31.7099C86.1114 31.609 85.9555 31.5345 85.79 31.4899C85.6136 31.4405 85.4271 31.4405 85.2508 31.4899C85.0744 31.5393 84.915 31.6361 84.79 31.7699C84.7028 31.8942 84.6385 32.033 84.6 32.1799C84.2 33.6599 84.95 34.6299 84.7 36.1799C84.5213 36.8477 84.1774 37.4599 83.7 37.9599C83.4124 38.465 83.1682 38.9935 82.97 39.5399"
          stroke="#121930"
          strokeWidth="0.84"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M81.79 38.6699L82.93 39.4299"
          stroke="#121930"
          strokeWidth="0.84"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M81.79 40.47C81.3817 41.0688 81.1131 41.7518 81.0041 42.4683C80.895 43.1849 80.9483 43.9168 81.16 44.61"
          stroke="#121930"
          strokeWidth="0.84"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M79.2401 24.5501L78.3201 19.1201"
          stroke="#F6C667"
          strokeWidth="0.84"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M83.4399 24.52L83.8199 19.03"
          stroke="#F6C667"
          strokeWidth="0.84"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M87.3701 26.4401L90.7801 22.1101"
          stroke="#F6C667"
          strokeWidth="0.84"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M45.7 55.8101L46.5 58.9801"
          stroke="#121930"
          strokeWidth="0.84"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M47.33 39.3C47.4026 39.6271 47.3989 39.9665 47.319 40.2918C47.2392 40.6172 47.0855 40.9197 46.8698 41.1761C46.6541 41.4324 46.3823 41.6355 46.0753 41.7698C45.7684 41.904 45.4347 41.9657 45.1 41.9501C42.67 41.9501 42.02 39.89 42.47 39.32L47.33 39.3Z"
          stroke="#121930"
          strokeWidth="0.84"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_293_19476">
        <rect width="120" height="120" fill="white" />
      </clipPath>
      <clipPath id="clip1_293_19476">
        <rect
          width="106.88"
          height="105.3"
          fill="white"
          transform="translate(7 15)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default memo(ManagementSvg);
