import React, { useEffect } from 'react';
import withRouter from 'utils/withRouter';
import PropTypes from 'prop-types';
import Loading from 'components/Loading';

const Home = ({ auth, navigate }) => {
  useEffect(() => {
    if (auth.customerId && auth.lineUserId) {
      navigate('/customer');
    } else {
      navigate('/login');
    }
  }, [auth, navigate]);

  return <Loading />;
};

Home.propTypes = {
  auth: PropTypes.object,
  navigate: PropTypes.func,
};

export default withRouter(Home);
