const countryCallingCodes = [
  {
    code: '+971',
    name: '阿拉伯聯合大公國(+971)',
  },
  {
    code: '+93',
    name: '阿富汗(+93)',
  },
  {
    code: '+1268',
    name: '安地瓜(+1268)',
  },
  {
    code: '+1264',
    name: '安圭拉(+1264)',
  },
  {
    code: '+54',
    name: '阿根廷(+54)',
  },
  {
    code: '+43',
    name: '奧地利(+43)',
  },
  {
    code: '+61',
    name: '澳大利亞(+61)',
  },
  {
    code: '+297',
    name: '阿魯巴(+297)',
  },
  {
    code: '+880',
    name: '孟加拉(+880)',
  },
  {
    code: '+32',
    name: '比利時(+32)',
  },
  {
    code: '+973',
    name: '巴林(+973)',
  },
  {
    code: '+1441',
    name: '百慕達(+1441)',
  },
  {
    code: '+591',
    name: '玻利維亞(+591)',
  },
  {
    code: '+55',
    name: '巴西(+55)',
  },
  {
    code: '+975',
    name: '不丹(+975)',
  },
  {
    code: '+1',
    name: '加拿大(+1)',
  },
  {
    code: '+243',
    name: '剛果共和國(+243)',
  },
  {
    code: '+41',
    name: '瑞士(+41)',
  },
  {
    code: '+237',
    name: '喀麥隆(+237)',
  },
  {
    code: '+86',
    name: '中國(+86)',
  },
  {
    code: '+57',
    name: '哥倫比亞(+57)',
  },
  {
    code: '+49',
    name: '德國(+49)',
  },
  {
    code: '+45',
    name: '丹麥(+45)',
  },
  {
    code: '+1767',
    name: '多明尼加(+1767)',
  },
  {
    code: '+20',
    name: '埃及(+20)',
  },
  {
    code: '+34',
    name: '西班牙(+34)',
  },
  {
    code: '+33',
    name: '法國(+33)',
  },
  {
    code: '+358',
    name: '芬蘭(+358)',
  },
  {
    code: '+679',
    name: '斐濟(+679)',
  },
  {
    code: '+1473',
    name: '格瑞那達(+1473)',
  },
  {
    code: '+995',
    name: '喬治亞(+995)',
  },
  {
    code: '+233',
    name: '迦納(+233)',
  },
  {
    code: '+30',
    name: '希臘(+30)',
  },
  {
    code: '+502',
    name: '瓜地馬拉(+502)',
  },
  {
    code: '+967',
    name: '蓋亞那(+967)',
  },
  {
    code: '+852',
    name: '香港(+852)',
  },
  {
    code: '+504',
    name: '宏都拉斯(+504)',
  },
  {
    code: '+509',
    name: '海地(+509)',
  },
  {
    code: '+62',
    name: '印尼(+62)',
  },
  {
    code: '+353',
    name: '愛爾蘭(+353)',
  },
  {
    code: '+91',
    name: '印度(+91)',
  },
  {
    code: '+354',
    name: '冰島(+354)',
  },
  {
    code: '+39',
    name: '義大利(+39)',
  },
  {
    code: '+964',
    name: '伊拉克(+964)',
  },
  {
    code: '+1876',
    name: '牙買加(+1876)',
  },
  {
    code: '+962',
    name: '約旦(+962)',
  },
  {
    code: '+81',
    name: '日本(+81)',
  },
  {
    code: '+254',
    name: '肯亞(+254)',
  },
  {
    code: '+975',
    name: '柬埔寨(+855)',
  },
  {
    code: '+82',
    name: '韓國(+82)',
  },
  {
    code: '+965',
    name: '科威特(+965)',
  },
  {
    code: '+7',
    name: '哈薩克(+7)',
  },
  {
    code: '+1758',
    name: '聖盧西亞(+1758)',
  },
  {
    code: '+94',
    name: '斯里蘭卡(+94)',
  },
  {
    code: '+352',
    name: '盧森堡(+352)',
  },
  {
    code: '+212',
    name: '摩洛哥(+212)',
  },
  {
    code: '+261',
    name: '馬達加斯加(+261)',
  },
  {
    code: '+389',
    name: '馬其頓(+389)',
  },
  {
    code: '+60',
    name: '馬來西亞(+60)',
  },
  {
    code: '+853',
    name: '澳門(+853)',
  },
  {
    code: '+960',
    name: '馬爾地夫(+960)',
  },
  {
    code: '+52',
    name: '墨西哥(+52)',
  },
  {
    code: '+234',
    name: '奈及利亞(+234)',
  },
  {
    code: '+505',
    name: '尼加拉瓜(+505)',
  },
  {
    code: '+47',
    name: '挪威(+47)',
  },
  {
    code: '+674',
    name: '諾魯(+674)',
  },
  {
    code: '+64',
    name: '紐西蘭(+64)',
  },
  {
    code: '+507',
    name: '巴拿馬(+507)',
  },
  {
    code: '+675',
    name: '巴布亞紐幾內亞(+675)',
  },
  {
    code: '+92',
    name: '巴基斯坦(+92)',
  },
  {
    code: '+351',
    name: '葡萄牙(+351)',
  },
  {
    code: '+595',
    name: '巴拉圭(+595)',
  },
  {
    code: '+40',
    name: '羅馬尼亞(+40)',
  },
  {
    code: '+7',
    name: '俄羅斯(+7)',
  },
  {
    code: '+250',
    name: '盧旺達(+250)',
  },
  {
    code: '+966',
    name: '沙烏地阿拉伯(+966)',
  },
  {
    code: '+248',
    name: '塞席爾(+248)',
  },
  {
    code: '+249',
    name: '蘇丹(+249)',
  },
  {
    code: '+46',
    name: '瑞典(+46)',
  },
  {
    code: '+65',
    name: '新加坡(+65)',
  },
  {
    code: '+503',
    name: '薩爾瓦多(+503)',
  },
  {
    code: '+381',
    name: '敘利亞(+381)',
  },
  {
    code: '+676',
    name: '湯加群島(+676)',
  },
  {
    code: '+90',
    name: '土耳其(+90)',
  },
  {
    code: '+66',
    name: '泰國(+66)',
  },
  {
    code: '+886',
    name: '台灣(+886)',
  },
  {
    code: '+380',
    name: '烏克蘭(+380)',
  },
  {
    code: '+256',
    name: '烏干達(+256)',
  },
  {
    code: '+44',
    name: '英國(+44)',
  },
  {
    code: '+1',
    name: '美國(+1)',
  },
  {
    code: '+598',
    name: '烏拉圭(+598)',
  },
  {
    code: '+998',
    name: '烏茲別克(+998)',
  },
  {
    code: '+58',
    name: '委內瑞拉(+58)',
  },
  {
    code: '+967',
    name: '葉門(+967)',
  },
];

export default countryCallingCodes;
