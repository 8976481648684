const API_URL = process.env.REACT_APP_API_URL;
const OPEN_API_URL = process.env.REACT_APP_OPEN_API_URL;

export const getToken = ({ liffId, lineUserId }) => ({
  url: `${OPEN_API_URL}/v1/auth/lineUserIDAuth`,
  method: 'POST',
  headers: {
    'x-liff-id': liffId,
    'x-lineuser-id': lineUserId,
  },
});

/* USER */
export const getCustomer = ({ accessToken, lineUserId } = {}) => ({
  url: `${API_URL}/api/v1/customer`,
  method: 'GET',
  headers: {
    ...(accessToken && { authorization: `Bearer ${accessToken}` }),
    ...(lineUserId && { 'x-lineuser-id': lineUserId }),
  },
});

export const bindCustomer = ({ clientData }) => ({
  url: `${API_URL}/api/v1/customers/bind`,
  method: 'POST',
  data: clientData,
});

export const createCustomer = ({ customerData }) => ({
  url: `${API_URL}/api/v1/customers`,
  method: 'POST',
  data: customerData,
});

export const updateCustomer = ({ customerData }) => ({
  url: `${API_URL}/api/v1/customer`,
  method: 'PUT',
  data: customerData,
});

export const findByPhone = ({
  phoneCountryCode: countryCode,
  phoneNumber: phone,
}) => ({
  url: `${API_URL}/api/v1/customers/findByPhone`,
  method: 'GET',
  params: { countryCode, phone },
});

export const sendPhoneValidationCode = ({
  phoneCountryCode: countryCode,
  phoneNumber: phone,
}) => ({
  url: `${API_URL}/api/v1/phone/passcode`,
  method: 'POST',
  data: { countryCode, phone },
});

export const confirmPhoneValidationCode = ({
  phoneCountryCode: countryCode,
  phoneNumber: phone,
  verificationCode: passcode,
}) => ({
  url: `${API_URL}/api/v1/phone/valid`,
  method: 'POST',
  data: { countryCode, phone, passcode },
});

export const login = () => ({
  url: '/api/vi/customer/login',
  method: 'POST',
});

/* APPOINTMENT */
export const getClinics = ({ accessToken, lineUserId } = {}) => ({
  url: `${API_URL}/api/v1/clinics/branchClinics`,
  method: 'GET',
  headers: {
    ...(accessToken && { authorization: `Bearer ${accessToken}` }),
    ...(lineUserId && { 'x-lineuser-id': lineUserId }),
  },
});

export const getRooms = ({ clinicId }) => ({
  url: `${API_URL}/api/v1/clinics/${clinicId}/rooms`,
  method: 'GET',
});

export const getSchedules = ({ clinicId, date, roomId, roomName }) => ({
  url: `${API_URL}/api/v1/clinics/${clinicId}/schedules?date=${date}&roomHISID=${roomId}&roomName=${roomName}`,
  method: 'GET',
});

export const canBookAppointment = () => ({
  url: `${API_URL}/api/v1/appointments/canBookAppointment`,
  method: 'GET',
});

export const getAppointments = ({ page = 0, size = 200 }) => ({
  url: `${API_URL}/api/v1/customer/appointments?page=${page}&size=${size}`,
  method: 'GET',
});

export const confirmAppointment = ({ clinicId, appointmentId }) => ({
  url: `${API_URL}/api/v1/clinics/${clinicId}/appointments/${appointmentId}`,
  method: 'PUT',
});

export const cancelAppointment = ({ clinicId, appointmentId }) => ({
  url: `${API_URL}/api/v1/clinics/${clinicId}/appointments/${appointmentId}`,
  method: 'DELETE',
});

export const createAppointment = ({ clinicId, appointmentData }) => ({
  url: `${API_URL}/api/v1/clinics/${clinicId}/appointments`,
  method: 'POST',
  data: appointmentData,
});

/* TREATMENT */
export const getTreatments = () => ({
  url: `getTreatments`,
  method: 'GET',
});

export const getExecutionItems = () => ({
  url: `${API_URL}/api/v1/customer/treatments-items`,
  method: 'GET',
});

export const getExecutionRecords = ({ itemIDs }) => {
  return {
    url: `${API_URL}/api/v1/customer/treatments-records`,
    method: 'GET',
    params: { itemIDs },
  };
};

/* Check In */
export const checkIn = () => ({
  url: `${API_URL}/api/v1/customer/sign-in`,
  method: 'POST',
});

/* COUPON */
export const assignCoupon = ({ couponData }) => ({
  url: `${API_URL}/api/v1/customer/coupons`,
  method: 'POST',
  data: couponData,
});

export const getCoupons = () => ({
  url: `${API_URL}/api/v1/customer/coupons`,
  method: 'GET',
});
