import React, { memo, useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { colors } from 'styles/theme';
import Text from 'components/Text';

const Wrapper = styled.div`
  position: relative;
`;

const StyledText = styled(Text)`
  color: ${({ status }) => {
    switch (status) {
      case 'completion':
        return colors.SHADES_400;
      default:
        return colors.SECONDARY_500;
    }
  }};
`;

const PopoverContent = styled.div`
  position: absolute;
  display: inline-block;
  top: 32px;
  right: 32px;
  padding: 12px 24px;
  width: auto !important;
  background: ${colors.SHADES_000};
  box-shadow: 0px 24px 32px rgba(0, 0, 0, 0.04),
    0px 16px 24px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.04),
    0px 0px 1px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
`;

const CardWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  width: 100%;
  padding: 16px;
  background-color: ${colors.SHADES_000};
  border: 1px solid ${colors.SHADES_200};
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06),
    0px 0px 1px rgba(0, 0, 0, 0.04);
  border-radius: 16px;
  transition: transform 0.2s ease-in-out;

  > div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    gap: 16px;
  }

  > i {
    position: absolute;
    top: 16px;
    right: 16px;
    font-size: 24px;
    color: ${colors.SHADES_400};
  }
`;

const getStatusText = (status) => {
  switch (status) {
    case 'completion':
      return '已完成';
    default:
      return '未完成';
  }
};

const getInfoTitle = (data) => {
  const { status, leftTimes } = data;
  switch (status) {
    case 'completion':
      return `此療程皆已完成`;
    case 'pending':
    case 'executing':
    default:
      return `剩餘療程數：${leftTimes}`;
  }
};

const TreatmentCard = ({ data, onClickRecord }) => {
  const ref = useRef();
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const handleRecordClick = (item) => {
    onClickRecord(item);
    setIsPopoverOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        isPopoverOpen && setIsPopoverOpen(false);
      }
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [isPopoverOpen]);

  return (
    <Wrapper>
      <CardWrapper>
        <i
          className="ri-more-2-fill"
          onClick={() => setIsPopoverOpen(!isPopoverOpen)}
        />
        {isPopoverOpen && (
          <PopoverContent ref={ref} onClick={() => handleRecordClick(data)}>
            使用紀錄
          </PopoverContent>
        )}
        <div>
          <StyledText
            font="Heading/Small/Heavy"
            status={data.status}
            content={getStatusText(data.status)}
            block
          />
          <Text font="Heading/Large/Medium" content={data.name} block />
          <Text
            font="Heading/X-Small/Regular"
            content={getInfoTitle(data)}
            block
          />
        </div>
        {/* @TODO: hide for later phase */}
        {/* <div>
          {['executing', 'pending'].includes(data.status) && (
            <Button onClick={onClick} fullWidth>
              立即預約
            </Button>
          )}
        </div> */}
      </CardWrapper>
    </Wrapper>
  );
};

TreatmentCard.propTypes = {
  data: PropTypes.object,
  onClick: PropTypes.func,
  onClickRecord: PropTypes.func,
};

export default memo(TreatmentCard);
