import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { setAlert } from 'reducers/globalReducer';
import { updateCustomer } from 'services';
import { covertCustomerDataToApiFormat } from 'utils/customer';
import useAxios from 'hooks/useAxios';
import withRouter from 'utils/withRouter';
import Loading from 'components/Loading';
import RangeSelector from 'components/RangeSelector';
import Text from 'components/Text';
import Button from 'components/Button';

const RangeSelectorWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
  margin-bottom: 32px;
`;

const VALUE_TO_SCALE_MAP = {
  1: '100%',
  2: '115%',
  3: '125%',
  4: '150%',
};

const SCALE_TO_VALUE_MAP = {
  '100%': 1,
  '115%': 2,
  '125%': 3,
  '150%': 4,
};

const ScaleSetting = ({ navigate, location }) => {
  const dispatch = useDispatch();
  const { request, status } = useAxios();
  const [isInit, setIsInit] = useState(false);
  const [customerData, setCustomerData] = useState(null);

  const handleScaleChange = (value) => {
    setCustomerData((prev) => ({
      ...prev,
      viewScale: VALUE_TO_SCALE_MAP[value],
    }));
  };

  const handleSave = () => {
    request(
      updateCustomer({
        customerData: covertCustomerDataToApiFormat(customerData),
      })
    ).then(() => {
      dispatch(setAlert({ type: 'success', title: '資料已更新' }));
      navigate('/customer');
    });
  };

  useEffect(() => {
    const data = location.state.customerData;
    if (data) {
      setCustomerData(data);
      setIsInit(true);
    }
  }, [location]);

  useEffect(() => {
    if (isInit) {
      document.body.style.zoom = customerData.viewScale || '100%';
    }
  }, [isInit, customerData]);

  return !isInit || !customerData ? (
    <Loading />
  ) : (
    <>
      <Text
        font="Heading/Large/Medium"
        marginBottom={32}
        content={'閱讀字體大小'}
      />
      <RangeSelectorWrapper>
        <Text font="Heading/Medium/Medium" content="A" />
        <RangeSelector
          value={SCALE_TO_VALUE_MAP[customerData.viewScale]}
          onChange={handleScaleChange}
          step={1}
          min={1}
          max={4}
        />
        <Text font="Heading/H6/Medium" content="A" />
      </RangeSelectorWrapper>{' '}
      <Button size="large" onClick={handleSave} isLoading={status.loading}>
        儲存
      </Button>
    </>
  );
};

ScaleSetting.propTypes = {
  navigate: PropTypes.func,
  location: PropTypes.object,
};

export default withRouter(ScaleSetting);
