import React, { memo } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import Select from 'components/Select';
import DatePicker from 'components/DatePicker';
import FormControl from 'components/FormControl';

const Form = ({ data, options, onChange, isLoading }) => (
  <>
    <FormControl>
      <Select
        label="館別"
        value={data.clinic?.name}
        options={options.clinicOptions}
        selectedOption={data.clinic}
        setSelectedOption={(option) => onChange('clinic', option)}
        disabled={isLoading}
        fullWidth
      />
    </FormControl>

    {data.clinic && (
      <>
        {options.roomOptions[data.clinic.id]?.length ? (
          <FormControl>
            <Select
              label="診間"
              value={data.room?.name}
              options={options.roomOptions[data.clinic.id]}
              selectedOption={data.room}
              setSelectedOption={(option) => onChange('room', option)}
              placeholder="請選擇診間"
              disabled={isLoading}
              fullWidth
            />
          </FormControl>
        ) : (
          '該館別無診間資料，請選擇其他館別'
        )}
      </>
    )}

    {data.room && (
      <FormControl>
        <DatePicker
          label="預約日期"
          value={data.date}
          onChange={(e) => onChange('date', e.target.value)}
          min={dayjs().format('YYYY-MM-DD')}
          placeholder="請選擇預約日期"
          disabled={isLoading}
          fullWidth
        />
      </FormControl>
    )}

    {data.date && (
      <>
        {dayjs(data.date).isBefore(dayjs().format('YYYY-MM-DD')) ? (
          '請選擇正確的日期'
        ) : options.timeOptions?.length ? (
          <FormControl>
            <Select
              label="時間"
              value={data.time?.name}
              options={options.timeOptions}
              selectedOption={data.time}
              setSelectedOption={(option) => onChange('time', option)}
              placeholder="請選擇時間"
              disabled={isLoading}
              fullWidth
            />
          </FormControl>
        ) : (
          <div>
            該日期無可預約時段， 請選擇其他日期或
            <a href={`tel: ${data.clinic.phone}`}>致電診所</a>
          </div>
        )}
      </>
    )}

    {data.time && (
      <>
        {options.doctorOptions?.[data.time.id]?.length ? (
          <FormControl>
            <Select
              label="醫師"
              value={data.doctor?.name}
              options={options.doctorOptions[data.time.id]}
              selectedOption={data.doctor}
              setSelectedOption={(option) => onChange('doctor', option)}
              placeholder="請選擇醫師"
              fullWidth
            />
          </FormControl>
        ) : (
          <div>
            該時段無可預約醫師，請選擇其他時段
            <a href={`tel: ${data.clinic.phone}`}>致電診所</a>
          </div>
        )}
      </>
    )}
  </>
);

Form.propTypes = {
  data: PropTypes.object.isRequired,
  options: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default memo(Form);
