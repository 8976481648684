import React, { memo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Player } from '@lottiefiles/react-lottie-player';
import success from './list/success.json';
import error from './list/error.json';
import box from './list/box.json';

const Wrapper = styled.div`
  svg {
    transform: unset !important;
  }
`;

const JSON_FILES = {
  success,
  error,
  box,
};

const LottiePlayer = ({
  type,
  size = 120,
  loop = false,
  keepLastFrame = true,
}) => (
  <Wrapper>
    <Player
      autoplay
      loop={loop}
      keepLastFrame={keepLastFrame}
      src={JSON_FILES[type]}
      style={{ maxHeight: `${size}px`, maxWidth: `${size}px` }}
    />
  </Wrapper>
);

LottiePlayer.propTypes = {
  type: PropTypes.string.isRequired,
  size: PropTypes.number,
  loop: PropTypes.bool,
  keepLastFrame: PropTypes.bool,
};

export default memo(LottiePlayer);
