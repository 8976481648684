import React, { memo } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { colors } from 'styles/theme';
import Text from 'components/Text';

const StyledAlert = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 16px;
  width: 100%;
  color: ${({ setting }) => setting.color};
  border: solid 1px ${({ setting }) => setting.border};
  background-color: ${({ setting }) => setting.background};
  border-radius: 8px;
  > div {
    display: flex;
    align-items: flex-start;
    i {
      font-size: 20px;
      margin-right: 8px;
    }
  }
`;

const CloseButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const getTypeSetting = (type) => {
  switch (type) {
    case 'information':
      return {
        color: colors.PRIMARY_600,
        background: colors.PRIMARY_100,
        border: colors.PRIMARY_300,
        icon: <i className="ri-error-warning-line" />,
      };
    case 'error':
      return {
        color: colors.SYSTEM_ERROR_700,
        background: colors.SYSTEM_ERROR_100,
        border: colors.SYSTEM_ERROR_400,
        icon: <i className="ri-alert-line" />,
      };
    case 'warning':
      return {
        color: colors.SYSTEM_WARNING_700,
        background: colors.SYSTEM_WARNING_100,
        border: colors.SYSTEM_WARNING_500,
        icon: <i className="ri-error-warning-line" />,
      };
    case 'success':
      return {
        color: colors.SYSTEM_SUCCESS_700,
        background: colors.SYSTEM_SUCCESS_100,
        border: colors.SYSTEM_SUCCESS_400,
        icon: <i className="ri-checkbox-circle-line" />,
      };
    case 'general':
    default:
      return {
        color: colors.SHADES_600,
        background: colors.SHADES_100,
        border: colors.SHADES_400,
        icon: <i className="ri-fire-line" />,
      };
  }
};

const Alert = ({ type, title, description, onClose }) => {
  const setting = getTypeSetting(type);
  return (
    <StyledAlert setting={setting}>
      <div>
        {setting.icon}
        <div>
          {title && (
            <Text
              font="Heading/Medium/Medium"
              color={setting.color}
              content={title}
              block
            />
          )}
          {description && typeof description === 'string' ? (
            <Text
              font="Body/12px_Regular"
              color={setting.color}
              marginTop={8}
              content={description}
              block
            />
          ) : (
            description
          )}
        </div>
      </div>
      {onClose && (
        <CloseButton onClick={onClose}>
          <i className="ri-close-line" />
        </CloseButton>
      )}
    </StyledAlert>
  );
};

Alert.propTypes = {
  type: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string || PropTypes.node,
  onClose: PropTypes.func,
};

export default memo(Alert);
