import React, { memo, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Button from 'components/Button';
import Text from 'components/Text';
import { colors } from 'styles/theme';

const Backdrop = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: ${({ zIndex }) => zIndex || 100};
`;

const Wrapper = styled.div`
  position: relative;
  width: ${({ width }) => (width ? `${width}px` : '320px')};
  background-color: ${colors.SHADES_000};
  padding: 48px 40px 40px 40px;
  border-radius: 16px;
`;

const CloseButton = styled.div`
  position: absolute;
  top: 32px;
  right: 32px;
  font-size: 24px;
  color: ${colors.SHADES_400};
  cursor: pointer;
`;

const ActionButtons = styled.div`
  display: flex;
  justify-content: center;
  gap: 16px;
  width: 100%;
  margin-top: 32px;
`;

const Dialog = ({
  open = false,
  width,
  zIndex,
  title,
  description,
  showCloseButton = true,
  confirmText,
  cancelText,
  onConfirm,
  onCancel,
  confirmColorType,
  disabled,
  isLoading,
}) => {
  useEffect(() => {
    document.body.style.overflow = open ? 'hidden' : '';
  }, [open]);
  useEffect(() => () => (document.body.style.overflow = ''), []);
  return open ? (
    <Backdrop zIndex={zIndex}>
      <Wrapper width={width}>
        {showCloseButton && (
          <CloseButton onClick={onCancel}>
            <i className="ri-close-line" />
          </CloseButton>
        )}
        {title && <Text font="Heading/H6/Medium" content={title} />}
        {description && (
          <Text
            font="Body/16px_Regular"
            color="SHADES_500"
            content={description}
            marginTop={12}
          />
        )}
        <ActionButtons>
          {cancelText && (
            <Button
              color="secondary"
              variant="filled"
              onClick={onCancel}
              disabled={isLoading || disabled}
            >
              {cancelText}
            </Button>
          )}
          {confirmText && (
            <Button
              color={confirmColorType}
              variant="filled"
              onClick={onConfirm}
              isLoading={isLoading}
              disabled={disabled}
            >
              {confirmText}
            </Button>
          )}
        </ActionButtons>
      </Wrapper>
    </Backdrop>
  ) : null;
};

Dialog.propTypes = {
  open: PropTypes.bool,
  width: PropTypes.number,
  zIndex: PropTypes.number,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  showCloseButton: PropTypes.bool,
  confirmText: PropTypes.string,
  cancelText: PropTypes.string,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  confirmColorType: PropTypes.string,
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  viewMode: PropTypes.bool,
};

export default memo(Dialog);
