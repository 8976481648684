import { useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setErrorMessage } from 'reducers/globalReducer';
import axios from 'axios';

axios.defaults.baseURL = '/';

const useAxios = () => {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.global.auth);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const request = useCallback(
    async (config, setGlobalError = true) => {
      setLoading(true);
      setError(null);
      dispatch(setErrorMessage(null));
      try {
        const response = await axios({
          url: config.url,
          method: config.method || 'GET',
          headers: {
            authorization: auth?.token ? `Bearer ${auth.token}` : null,
            'x-lineuser-id': auth?.lineUserId,
            ...(config.headers || {}),
          },
          data: config.data || null,
          params: config.params || null,
        });
        return Promise.resolve(response.data);
      } catch (error) {
        const errorCode = error?.response?.data?.code;
        const errorMessage =
          error?.response?.data?.error ||
          error?.response?.data?.message ||
          error.message ||
          'Something went wrong';

        if (errorMessage.includes('LineUserNotFound')) {
          // will retry in App.js to avoid webhook async issue
          return Promise.reject(error);
        }

        if (errorCode === 100002) {
          return Promise.resolve(errorMessage);
        }
        if (setGlobalError) {
          dispatch(setErrorMessage(errorMessage));
        }
        setError(errorMessage);
        return Promise.reject({ code: errorCode, message: errorMessage });
      } finally {
        setLoading(false);
      }
    },
    [auth, dispatch]
  );

  return {
    request,
    status: {
      loading,
      error,
    },
  };
};

export default useAxios;
