import dayjs from 'dayjs';
import phoneValidation from 'phone';
import {
  isNationalIdentificationNumberValid,
  isResidentCertificateNumberValid,
  isNewResidentCertificateNumberValid,
} from 'taiwan-id-validator';

export const defaultCustomerData = {
  phoneCountryCode: '+886',
  phoneNumber: '',
  name: '',
  idNumber: '',
  gender: 'M',
  birthday: '',
  address: '',
  email: '',
};

export const covertCustomerDataToApiFormat = ({
  phoneCountryCode,
  phoneNumber,
  name,
  idNumber,
  gender,
  birthday,
  address,
  email,
  clinicId,
  viewScale,
  passcode,
}) => {
  return {
    name,
    birth: birthday,
    gender,
    email,
    address,
    countryCode: phoneCountryCode,
    phone: phoneNumber,
    idNum: idNumber,
    clinicID: clinicId,
    viewScale: viewScale || '100%',
    passcode,
  };
};

export const phoneValidate = ({ phoneCountryCode, phoneNumber }) => {
  const isValidPhone = phoneValidation(phoneCountryCode + phoneNumber).isValid;

  if (!isValidPhone) {
    return '請輸入正確的手機號碼';
  }

  if (
    isValidPhone &&
    phoneCountryCode === '+886' &&
    !phoneNumber.startsWith('09')
  ) {
    return '請輸入 09 開頭的手機號碼格式';
  }

  return null;
};

export const checkCustomerData = ({
  phoneCountryCode,
  phoneNumber,
  name,
  idNumber,
  birthday,
  email,
}) => {
  const errors = {};

  const phoneError = phoneValidate({ phoneCountryCode, phoneNumber });

  if (phoneError) {
    errors.phoneNumber = phoneError;
  }

  if (!name) {
    errors.name = '請輸入姓名';
  }
  if (
    idNumber &&
    !(
      isNationalIdentificationNumberValid(idNumber) ||
      isResidentCertificateNumberValid(idNumber) ||
      isNewResidentCertificateNumberValid(idNumber)
    )
  ) {
    errors.idNumber = '請輸入正確的身分/居留證字號';
  }
  if (!birthday || !dayjs(birthday).isValid()) {
    errors.birthday = '請選擇生日';
  }
  if (email && !email.match(/^[^@\s]+@[^@\s]+$/)) {
    errors.email = '請輸入正確的電子信箱';
  }

  return errors;
};
