import React, { memo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { colors, fonts } from 'styles/theme';

const TabContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  overflow: auto;
`;

const Tab = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 24px;
  min-width: 88px;
  width: 100%;
  white-space: nowrap;
  color: ${colors.SHADES_500};
  border-bottom: 2px solid transparent;
  ${fonts['Body/16px_Regular']};
  ${({ active }) =>
    active &&
    `
      color: ${colors.PRIMARY_500};
      border-bottom: 2px solid ${colors.PRIMARY_500};
      ${fonts['Heading/Medium/Medium']};
    `}
`;

const Tabs = ({ currentTab, tabs, onChange }) => (
  <TabContainer>
    {tabs.map((tab) => (
      <Tab
        onClick={() => onChange(tab)}
        active={tab.id === currentTab.id}
        key={tab.id}
      >
        {tab.name}
      </Tab>
    ))}
  </TabContainer>
);

Tabs.propTypes = {
  currentTab: PropTypes.object.isRequired,
  tabs: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default memo(Tabs);
