import React, { useEffect, useRef, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  setAuth,
  setAlert,
  setErrorMessage,
  setLineUserProfile,
} from 'reducers/globalReducer';
import styled from 'styled-components';
import { Routes, Route } from 'react-router-dom';
import liff from '@line/liff';
import 'normalize.css/normalize.css';
import 'remixicon/fonts/remixicon.css';
import 'styles/global.scss';

import { getUrlParams, parseToken } from 'utils';
import { getToken, getCustomer, getClinics } from 'services';
import useAxios from 'hooks/useAxios';
import ErrorModal from 'components/ErrorModal';
import Alert from 'components/Alert';
import Home from 'pages/Home';
import Login from 'pages/Login';
import Register from 'pages/Register';
import Success from 'pages/Success';
import Validation from 'pages/Validation';
import Customer from 'pages/Customer';
import CustomerProfile from 'pages/CustomerProfile';
import ScaleSetting from 'pages/ScaleSetting';
import Appointment from 'pages/Appointment';
import AppointmentCreate from 'pages/AppointmentCreate';
import AppointmentList from 'pages/AppointmentList';
import Treatments from 'pages/Treatments';
import SignIn from 'pages/SignIn';
import Coupons from 'pages/Coupons';
import CouponAssign from 'pages/CouponAssign';

const AppWrapper = styled.div`
  min-height: 100%;
  padding: 16px;
`;

const AlertWrap = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 16px;
  left: 16px;
  width: calc(100% - 32px);
  opacity: 0;

  ${({ show }) =>
    show
      ? `
          transition: opacity 0.5s ease-in-out;
          opacity: 1;
        `
      : 'pointer-events: none;'}
`;

const App = () => {
  const dispatch = useDispatch();
  const alert = useSelector((state) => state.global.alert);
  const auth = useSelector((state) => state.global.auth);
  const errorMessage = useSelector((state) => state.global.errorMessage);
  const timeoutRef = useRef();
  const initAuthRetryTimes = useRef(2);
  const { request } = useAxios();

  const handleClearAlert = useCallback(() => {
    clearTimeout(timeoutRef.current);
    dispatch(setAlert(null));
  }, [dispatch]);

  const initAuth = useCallback(
    async (liffId, lineUserId) => {
      try {
        const { accessToken } = await request(getToken({ liffId, lineUserId }));
        if (accessToken) {
          // Customer Data
          const customerData = await request(
            getCustomer({ accessToken, lineUserId })
          );
          if (customerData) {
            const viewScale = customerData?.viewScale;
            document.body.style.zoom = viewScale;
          }
          // Clinic Data
          const clinics = await request(
            getClinics({ accessToken, lineUserId })
          );
          const clinicId = parseToken(accessToken)?.clientID;
          const clinicPhone = clinics?.find(
            (clinic) => clinic.id === clinicId
          )?.phone;
          // Auth
          dispatch(
            setAuth({
              token: accessToken,
              liffId,
              lineUserId,
              clinicId,
              clinicPhone,
              customerId: customerData.id,
            })
          );
        }
      } catch (err) {
        const errorMessage = JSON.stringify(err?.response?.data) || err;
        if (
          errorMessage?.includes &&
          errorMessage.includes('LineUserNotFound') &&
          initAuthRetryTimes.current > 0
        ) {
          setTimeout(() => initAuth(liffId, lineUserId), 1000);
          initAuthRetryTimes.current -= 1;
          return;
        } else {
          dispatch(setErrorMessage(`取得授權失敗: ${errorMessage}`));
        }
      }
    },
    [dispatch, request]
  );

  useEffect(() => {
    if (alert) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = setTimeout(() => dispatch(setAlert(null)), 3500);
    }
    return () => clearTimeout(timeoutRef.current);
  }, [alert, dispatch]);

  useEffect(() => {
    const redirectLink = getUrlParams('redirectLink');
    if (redirectLink) {
      window.location.replace(redirectLink);
      return;
    }

    const liffId = getUrlParams('liffId') || auth?.liffId;
    let lineUserId = getUrlParams('id') || auth?.lineUserId;

    liff
      .init({ liffId })
      .then(async () => {
        // Line Profile
        const isLineLogin = liff.isLoggedIn();
        if (!isLineLogin && !lineUserId) {
          throw new Error('Line Login Error');
        }

        if (isLineLogin) {
          const profile = await liff.getProfile();
          lineUserId = lineUserId || profile.userId;
          dispatch(setLineUserProfile(profile));
        }

        initAuth(liffId, lineUserId);
      })
      .catch((err) => dispatch(setErrorMessage(`LIFF初始化失敗: ${err}`)));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <AppWrapper>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login title="會員登入/註冊" />} />
        <Route path="/register" element={<Register title="會員註冊" />} />
        <Route path="/success" element={<Success title="成功" />} />
        <Route path="/validation" element={<Validation title="驗證" />} />
        <Route
          path="/customer"
          element={<Customer title="會員資料" checkAuth />}
        />
        <Route
          path="/customer/profile"
          element={<CustomerProfile title="個人資訊編輯" checkAuth />}
        />
        <Route
          path="/setting/scale"
          element={<ScaleSetting title="閱讀文字大小設定" checkAuth />}
        />
        <Route
          path="/appointment"
          element={<Appointment title="我的預約" checkAuth />}
        />
        <Route
          path="/appointment/create"
          element={<AppointmentCreate title="立即預約" checkAuth />}
        />
        <Route
          path="/appointment/list"
          element={<AppointmentList title="預約管理" checkAuth />}
        />
        <Route
          path="/treatments"
          element={<Treatments title="我的療程" checkAuth />}
        />
        <Route path="/sign-in" element={<SignIn title="簽到" checkAuth />} />
        <Route
          path="/coupon-assign"
          element={<CouponAssign title="獲得專屬療程" checkAuth />}
        />
        <Route
          path="/coupons"
          element={<Coupons title="專屬療程" checkAuth />}
        />
      </Routes>

      <AlertWrap show={!!alert}>
        <Alert
          type={alert?.type}
          title={alert?.title}
          onClose={handleClearAlert}
        />
      </AlertWrap>

      <ErrorModal
        open={!!errorMessage}
        description={errorMessage}
        onClose={() => dispatch(setErrorMessage(null))}
        showButton={false}
      />
    </AppWrapper>
  );
};

export default App;
