import React, { useState, useEffect, useRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import useAxios from 'hooks/useAxios';
import { sendPhoneValidationCode, confirmPhoneValidationCode } from 'services';
import Text from 'components/Text';
import FormControl from 'components/FormControl';
import Input from 'components/Input';
import Button from 'components/Button';

const IconText = styled.span`
  display: flex;
  align-items: center;
  i {
    line-height: 1;
    margin-right: 8px;
  }
`;

const ValidationCodeBlock = ({
  phoneCountryCode,
  phoneNumber,
  innerRef,
  fullWidth,
  setValidationStatus,
  isLoading,
}) => {
  const { request, status } = useAxios();
  const timer = useRef(0);
  const [error, setError] = useState(false);
  const [sentValidation, setSentValidation] = useState(false);
  const [verificationCode, setVerificationCode] = useState('');
  const [time, setTime] = useState(0);

  const handleSendValidation = () => {
    request(sendPhoneValidationCode({ phoneCountryCode, phoneNumber })).then(
      () => {
        setSentValidation(true);
        setValidationStatus((prev) => ({ ...prev, sentValidation: true }));
        setTime(120);
        timer.current = setInterval(() => setTime((prev) => prev - 1), 1000);
      }
    );
  };

  const handleCodeValidation = () => {
    setError(false);
    request(
      confirmPhoneValidationCode({
        phoneCountryCode,
        phoneNumber,
        verificationCode,
      })
    )
      .then(() => {
        setValidationStatus((prev) => ({
          ...prev,
          verificationCode,
          isValid: true,
        }));
      })
      .catch(() => {
        setError(true);
      });
  };

  useImperativeHandle(innerRef, () => ({
    handleSendValidation,
  }));

  useEffect(() => {
    if (time === 0) {
      clearInterval(timer.current);
    }
  }, [time]);

  useEffect(() => () => clearInterval(timer.current), []);

  return (
    sentValidation && (
      <>
        <FormControl>
          <Input
            label="驗證碼"
            value={verificationCode}
            onChange={(e) => setVerificationCode(e.target.value)}
            error={error}
            helperText={
              error && (
                <IconText>
                  <i className="ri-information-line" />
                  錯誤的驗證碼
                </IconText>
              )
            }
            type="tel"
            maxLength={6}
            fullWidth
          />
        </FormControl>
        <Text
          marginBottom={8}
          content={`我們已透過簡訊將驗證碼發送至${phoneCountryCode} ${phoneNumber} 可能幾分鐘後才會收到。`}
        />
        <Text marginRight={8} content="沒有收到嗎?" />
        <Text
          color={time ? 'SHADES_400' : 'PRIMARY_500'}
          marginBottom={24}
          content={`重試${time ? `(${time})` : ''}`}
          onClick={time === 0 ? handleSendValidation : null}
        />
        <div>
          <Button
            size="large"
            onClick={handleCodeValidation}
            fullWidth={fullWidth}
            isLoading={status.loading || isLoading}
            disabled={
              !phoneCountryCode ||
              !phoneNumber ||
              !verificationCode ||
              isLoading
            }
          >
            驗證
          </Button>
        </div>
      </>
    )
  );
};

ValidationCodeBlock.propTypes = {
  phoneCountryCode: PropTypes.string,
  phoneNumber: PropTypes.string,
  innerRef: PropTypes.object,
  fullWidth: PropTypes.bool,
  setValidationStatus: PropTypes.func,
  isLoading: PropTypes.bool,
};

export default ValidationCodeBlock;
