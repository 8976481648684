import React, { useState, useMemo, useEffect } from 'react';
import dayjs from 'dayjs';
import { createBrowserHistory } from 'history';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { colors } from 'styles/theme';
import useAxios from 'hooks/useAxios';
import {
  getClinics,
  getRooms,
  getSchedules,
  createAppointment,
} from 'services';
import withRouter from 'utils/withRouter';
import Button from 'components/Button';
import Alert from 'components/Alert';
import Loading from 'components/Loading';
import ErrorModal from 'components/ErrorModal';
import Form from './Form';
import Confirm from './Confirm';

const Wrapper = styled.div`
  position: relative;
  margin-bottom: ${({ step }) => (step === 'confirm' ? '200px' : '75px')};
`;

const ButtonWrapper = styled.div`
  position: fixed;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 16px;
  margin: 0 -16px;
  background-color: ${colors.SHADES_000};
  border-top: 1px solid ${colors.SHADES_200};
  > div {
    margin-bottom: 16px;
  }
`;

/* 
  @WARNING:: 
  DO NOT CHANGE THE ORDER OF KEYS.
  It is important for assigning values to appointmentData (handleChange) and should follow the UI flow.
*/
const defaultAppointmentData = {
  clinic: undefined,
  room: undefined,
  date: undefined,
  time: undefined,
  doctor: undefined,
  remark: undefined,
};

const AppointmentCreate = ({ auth, navigate, location }) => {
  const clinicId = auth.clinicId;
  const browserHistory = createBrowserHistory();
  const { request, status } = useAxios();
  const [error, setError] = useState(null);
  const [step, setStep] = useState('create');
  const [currentTime] = useState(dayjs());
  const [options, setOptions] = useState();
  const [timeSlotInterval, setTimeSlotInterval] = useState();
  const [appointmentData, setAppointmentData] = useState(
    defaultAppointmentData
  );

  const isSubmitDisabled = useMemo(() => {
    const { clinic, room, date, time, doctor } = appointmentData;
    return !clinic || !room || !date || !time || !doctor || status.loading;
  }, [appointmentData, status]);

  const handleChange = async (name, value) => {
    if (name === 'date') {
      const { timeSlots: schedules, timeSlotInterval } = await request(
        getSchedules({
          clinicId: appointmentData.clinic.id,
          date: value,
          roomId: appointmentData.room.id,
          roomName: appointmentData.room.name,
        })
      );

      setTimeSlotInterval(timeSlotInterval);

      const { timeOptions, doctorOptions } = Object.keys(schedules).reduce(
        (obj, key) => {
          const schedule = schedules[key];
          const { time, doctors } = schedule;
          if (currentTime.isAfter(dayjs(time))) return obj;

          obj.timeOptions.push({
            id: key,
            name: dayjs(time).format('HH:mm'),
            value: key,
          });
          obj.doctorOptions[key] = [{ id: null, name: '不指定' }, ...doctors];
          return obj;
        },
        { timeOptions: [], doctorOptions: {} }
      );

      setOptions((prev) => ({
        ...prev,
        timeOptions,
        doctorOptions,
      }));
    }

    const dataKeys = Object.keys(appointmentData);
    const changeKeyIndex = dataKeys.indexOf(name);
    const newData = dataKeys.reduce(
      (obj, key, index) => {
        if (index < changeKeyIndex) {
          return { ...obj, [key]: appointmentData[key] };
        }
        if (index === changeKeyIndex) {
          return { ...obj, [key]: value };
        }
        return obj;
      },
      { ...defaultAppointmentData }
    );
    setAppointmentData(newData);
  };

  const handleSubmit = () => {
    if (step === 'create') {
      setStep('confirm');
      history.pushState(null, null, null);
    }
    if (step === 'confirm') {
      request(
        createAppointment({
          clinicId: appointmentData.clinic.id,
          appointmentData: {
            roomHISID: appointmentData.room.id,
            doctorHISID: appointmentData.doctor.id,
            remark: appointmentData.remark,
            startAt: parseInt(appointmentData.time.value, 10),
            endAt:
              parseInt(appointmentData.time.value, 10) +
              parseInt(timeSlotInterval, 10),
          },
        }),
        false
      )
        .then(() => {
          navigate('/success', {
            state: { type: 'appointment' },
            replace: true,
          });
        })
        .catch((err) => {
          setError(err.message);
        });
    }
  };

  useEffect(() => {
    const getOptions = async () => {
      const clinics = await request(getClinics());
      const clinicOptions = clinics.map(({ id, name, phone }) => ({
        id,
        name,
        phone,
      }));

      const roomData = await Promise.all(
        clinicOptions.map((option) =>
          request(getRooms({ clinicId: option.id }))
        )
      );

      const roomOptions = roomData.reduce((obj, room, index) => {
        obj[clinics[index].id] = room
          .filter((room) => room.type.code === 'room')
          .map(({ id, name }) => ({
            id,
            name,
          }));
        return obj;
      }, {});

      setOptions({
        clinicOptions,
        roomOptions,
      });

      setAppointmentData({
        ...defaultAppointmentData,
        clinic: clinicOptions.find((option) => option.id === clinicId),
      });
    };
    getOptions();
  }, [clinicId, location, request]);

  useEffect(() => {
    browserHistory.listen(({ action }) => {
      if (action === 'POP') {
        setStep('create');
      }
    });
  }, [browserHistory]);

  return !options ? (
    <Loading />
  ) : (
    <Wrapper step={step}>
      {step === 'create' && (
        <Form
          data={appointmentData}
          options={options}
          onChange={handleChange}
          isLoading={status.loading}
        />
      )}
      {step === 'confirm' && (
        <Confirm data={appointmentData} onChange={handleChange} />
      )}
      <ButtonWrapper>
        {step === 'confirm' && (
          <Alert
            type="warning"
            title="注意"
            description="如需取消預約請至[預約管理]中取消您的預約。預約後如無故爽約，將暫停您的線上服務。"
            fullWidth
          />
        )}
        <Button onClick={handleSubmit} disabled={isSubmitDisabled} fullWidth>
          {step === 'create' ? '下一步' : '確認預約'}
        </Button>
      </ButtonWrapper>
      <ErrorModal
        open={!!error}
        description={error}
        buttonText="致電診所"
        onClose={() => setError(null)}
        onButtonClick={() =>
          (window.location.href = `tel: ${auth.clinicPhone}`)
        }
      />
    </Wrapper>
  );
};

AppointmentCreate.propTypes = {
  auth: PropTypes.object,
  navigate: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
};

export default withRouter(AppointmentCreate);
