import React, { useState, useEffect, memo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Input from 'components/Input';
import Select from 'components/Select';
import countryCallingCodes from 'utils/countryCallingCodes';

const ErrorHint = styled.span`
  display: flex;
  align-items: center;
  i {
    line-height: 1;
    margin-right: 8px;
  }
`;

const codeOptions = countryCallingCodes.map((data, index) => ({
  id: index,
  name: data.name,
  value: data.code,
}));

const defaultCode = codeOptions.find((option) => option.value === '+886');

const PhoneNumberInput = ({
  label,
  code = '+886',
  number = '',
  onCodeChange,
  onNumberChange,
  suffix,
  error,
  errorMessage,
  disabled,
}) => {
  const [selectedCode, setSelectedCode] = useState(defaultCode);

  const handleCodeChange = (option) => {
    setSelectedCode(option);
    onCodeChange(option.value);
  };

  useEffect(() => {
    setSelectedCode(codeOptions.find((option) => option.value === code));
  }, [code]);

  return (
    <Input
      label={label}
      value={number}
      onChange={(e) => onNumberChange(e.target.value)}
      error={error}
      helperText={
        error && (
          <ErrorHint>
            <i className="ri-information-line" />
            {errorMessage}
          </ErrorHint>
        )
      }
      prefix={
        <Select
          value={selectedCode.name}
          options={codeOptions}
          selectedOption={selectedCode}
          setSelectedOption={handleCodeChange}
          error={error}
          showArrow={false}
          variant="text"
          disabled={disabled}
        />
      }
      suffix={suffix}
      disabled={disabled}
      type="tel"
      decoration="start"
      fullWidth
    />
  );
};

PhoneNumberInput.propTypes = {
  label: PropTypes.string,
  code: PropTypes.string,
  number: PropTypes.string,
  onCodeChange: PropTypes.func,
  onNumberChange: PropTypes.func,
  suffix: PropTypes.node,
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
  disabled: PropTypes.bool,
  showValidationButton: PropTypes.bool,
};

export default memo(PhoneNumberInput);
