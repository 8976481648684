import React, { memo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { getDisplayDate } from 'utils';
import { colors, fonts } from 'styles/theme';
import Text from 'components/Text';
import FieldBase from 'components/FieldBase';
import { ClinicLabel } from 'components/AppointmentCard';

const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-bottom: 1px solid ${colors.SHADES_200};
  margin-top: 16px;
  margin-bottom: 24px;
  width: 100%;
`;

const RemarkEditor = styled.textarea`
  border: 1px solid ${colors.SHADES_400};
  border-radius: 8px;
  width: 100%;
  min-height: 128px;
  padding: 8px 16px;
  color: ${colors.SHADES_500};
  ${fonts['Body/16px_Regular']};
`;

const Confirm = ({ data, onChange }) => {
  return (
    <>
      <Header>
        <ClinicLabel>{data.clinic.name}</ClinicLabel>
        <Text
          font="Heading/H4/Medium"
          marginTop={16}
          marginBottom={24}
          content={data.room.name}
        />
      </Header>
      <Text
        font="Heading/H6/Medium"
        marginBottom={16}
        content={getDisplayDate(data.date, true)}
        block
      />
      <Text
        font="Heading/H6/Medium"
        marginBottom={16}
        content={data.time.name}
        block
      />
      <Text
        font="Heading/H6/Medium"
        marginBottom={16}
        content={`${data.doctor.name}醫師`}
        block
      />
      <FieldBase label="備註" fullWidth>
        <RemarkEditor
          value={data.remark}
          onChange={(e) => onChange('remark', e.target.value)}
          placeholder="請填入欲預約或諮詢之療程項目，或其他告知診所注意事項"
        />
      </FieldBase>
    </>
  );
};

Confirm.propTypes = {
  data: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default memo(Confirm);
