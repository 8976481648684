import React, { memo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Button from 'components/Button';
import Modal from 'components/Modal';
import { colors, fonts } from 'styles/theme';

const ModalContentWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  button {
    position: absolute;
    bottom: 0;
    > span {
      ${fonts['Body/12px_Regular']};
    }
  }
`;

const Content = styled.div`
  margin-bottom: 64px;
  height: 100%;
  max-height: 300px;
  overflow: auto;
  h1 {
    ${fonts['Heading/Medium/Medium']};
    color: ${colors.SHADES_900};
  }
  h2 {
    ${fonts['Body/14px_Medium']};
    color: ${colors.SHADES_900};
  }
  p,
  ol,
  li {
    ${fonts['Body/14px_Medium']};
    color: ${colors.SHADES_600};
  }
`;

const TermsModal = ({
  open = false,
  onClose = () => {},
  onButtonClick = () => {},
}) => {
  const handleButtonClick = () => {
    onButtonClick();
    onClose();
  };
  return (
    <Modal open={open} onClose={onClose}>
      <ModalContentWrapper>
        <Content>
          <div>
            <h1>翔評互動股份有限公司醫點通 服務條款/隱私權政策</h1>
            <p>
              翔評互動股份有限公司醫點通為 翔評互動股份有限公司
              建置與提供之服務（以下簡稱本服務），致力給予您更好、更便捷的就醫後體驗，並能整合自己的醫療行程追蹤與接收適切的實用資訊。您在使用本服務之前，請先詳細閱讀以下條款，當您點選「同意」並開始使用本服務後，即視為您已有效且不可撤銷地同意本條款，並同意遵守本服務條款之約定。
            </p>
            <h2>壹、應遵守之事項</h2>
            <p>
              一、 您需為年滿
              20歲之自然人，方得使用本服務。若您非完全行為能力人，應在法定代理人同意之下，方得使用本服務；請注意，當您使用本服務時，我們會視為您已取得法定代理人之同意。
            </p>
            <p>
              二、
              使用本服務時，如有需要您提供相關的個人資料進行資料建立、串接各診所或醫院的頻道以進
              行追蹤和互動時，您應提供真實、正確且完整的個人資料。請注意，對於不正確的資料，我們將有可能無法進行對您選定的院所頻道進行串接；若您提供的是偽造或假冒的資料，可能會導致本公司無法提供完整服務或者影響您的使用權益，並有可能向司法機關舉報要求追究與使您負相應的法律責任。
            </p>
            <p>
              三、
              您應妥善使用並嚴密保管本服務之Line帳號與密碼，並應自行防止第三人以不同的裝置登入您
              的LINE帳號，以免遭到第三人不當使用。本服務對於您Line帳號所進行的一切行為與活動，均視為您本人的行為，所有的活動過程與結果，您應當自行負責，本服務不對您帳號的使用做任何監測或自主管理。
            </p>
            <h2>貳、免責聲明</h2>
            <p>
              一、
              本服務將依據您所選擇追蹤的院所頻道、以及您所願意分享的掛號病程資訊，依據該頻道的發布頻率與分享方式，選擇適合您的醫學文章、內容、訊息、與健康常識的說明，請注意，前述訊息的推播並不具有醫療或診療目的，亦不得取代任何專業醫療諮詢或診斷或適用於任何醫療緊急情況、診斷或疾病及症狀治療。
            </p>
            <p>
              二、
              本服務保留無須事前通知您而得隨時修改資訊內容之權利。如有任何個人健康或醫療相關問題及疑問，建議您應立即諮詢醫師。若是遇到您有需醫療協助的緊急情況，應馬上撥打119或當地緊急救護電話送醫急救。本服務並未推薦或為任何醫師與院所進行保證或背書。
            </p>
            <p>
              三、
              本服務所提供相關的網站資訊或公開於社群媒體的資訊僅供參考，不對前述資訊負任何法律責任。本服務所提供資訊如有侵權或不符合事實者，您應即時告知。
            </p>
            <h2>參、隱私權政策</h2>
            <p>
              一、
              當您使用本服務時，本公司將遵循「個人資料保護法」之規範蒐集、處理及利用您提供之個人資料。為了提供良好的服務，本公司會在您使用本服務之期間或法律所允許之期間內留存您之相關個人資料，但非經您書面同意，或有其他合法使用依據，本公司不會將個人資料用於其他逾越本服務條款所說明之用途。本公司也有可能透過合法途徑從商業夥伴取得與暫存您的個人資料以優化或提供本服務。為了確保您之個人資料受保護，本公司謹依個人資料保護法規定告知以下事項並取得您之同意：
            </p>
            <ol>
              <li>
                個人資料蒐集之目的：蒐集之目的在於提供您智慧醫療服務，例如協助您管理您(包含您依法有權或經授權為其行使權利義務之親友)的掛號資訊(包括症狀紀錄、掛號科別、掛號提醒、看診後之醫囑說明及藥品說明等)，並進行保健醫療服務、客戶管理與服務、學術研究，及調查、統計與研究分析，明文使用類別將如下所示：
                <br />
                I. ○六四 保健醫療服務 <br />
                II ○八一 個人資料之合法交易業務 <br />
                III. ○九○ 消費者、客戶管理與服務 <br />
                IV. ○九一 消費者保護 <br />
                V. ○九八 商業與技術資訊 <br />
                VI. 一三五 資（通）訊服務 <br />
                VII. 一三六 資（通）訊與資料庫管理 <br />
                VIII. 一三七 資通安全與管理 <br />
                IX. 一四八 網路購物及其他電子商務服務 <br />
                X. 一五二 廣告或商業行為管理
                <br />
                XI.一五七 調查、統計與研究分析
              </li>
            </ol>
            <ol>
              <li>個人資料之類別：本服務蒐集的個人資料包括以下類別：</li>
            </ol>
            <p>
              (1)
              辨識個人者：姓名、電話、身份證資料、Line帳號、即時通帳號、網路平臺申請之帳號、提供網路身分認證或申辦查詢服務之紀錄及其他任何可辨識資料本人者
            </p>
            <p>(2) 個人描述：性別、出生年月日</p>
            <p>
              3.
              個人資料利用之期間：本網站蒐集個人資料所使用的期間為自您使用本服務日起至本服務終止營運之日、及本服務停止提供服務之日止；或自您加入後，至其要求停止接受本網站服務為止。
              4. 個人資料利用之地區、對象：
            </p>
            <p>
              （1）台灣地區及服務使用地，且基於履行契約義務，將提供相關資料予合作特約商或本服務於特定目的範圍內委外之廠商。
            </p>
            <p>（2）本服務將於蒐集之特定目的範圍內處理並利用個人資料。</p>
            <p>
              （3）合作特約院所及委外廠商於健康管理與行銷之特定目的範圍內蒐集、處理或利用個人資料。
            </p>
            <p>5、個人資料利用之方式：</p>
            <p>（1）將您所輸入或儲存之資料儲存於第三人提供之雲端儲存空間。</p>
            <p>（2）根據您操作功能，與院所系統連線，完成指定動作。</p>
            <p>
              （3）在服務伺服器上，進行資料統計分析，以優化服務內容，並提供個人化資訊與服務。
              6、依「個人資料保護法」第3條之規定，本網站所蒐集個人資料之當事人得請求本網站提供其：
              （1）查詢或請求閱覽；
            </p>
            <p>（2）請求製給複製本；</p>
            <p>（3）請求補充或更正；</p>
            <p>（4）請求停止蒐集、處理或利用；</p>
            <p>（5）請求刪除。</p>
            <p>
              上述權利，若因會員不符合申請程序或法律規定，或本服務依法負有保存義務，或法律另有規定之情況者，不在此限。若您就您的會員註冊資料向本服務請求答覆查詢、提供閱覽或製給複製本時，本服務將酌收必要成本費用。前述之申請，應填具申請文件，並由本人親自申請，本服務得向您請求提出可資確認之身分證明文件。若委託他人代為申請者，並應出具委任書，且提供本人及代理人之身分證明文件。本公司聯絡方式：Tel
              : 02-27457177/ Email :{' '}
              <a href="mailto:info@alleypin.com">info@alleypin.com</a>
            </p>
            <p>
              二、若您拒絕提供使用本服務所需必要之個人資料，則無法使用本服務。
            </p>
            <p>
              三、本服務處理您之個人資料過程中，將遵守相關流程與內部作業規範，並依據資訊安全之要求，進
              行必要之人員控管。
            </p>
            <p>
              四、您瀏覽使用本服務時，伺服器將會視使用的服務內容，自動記錄相關點選紀錄。其中可能包括您
              連線使用的IP位址、使用時間、瀏覽、使用紀錄等，做為提供相關服務之依據。
            </p>
            <p>五、特定資訊的存取與分析</p>
            <p>當您使用我們的服務時，會提供給我們特定資訊，這些資訊包括：</p>
            <p>
              當您建立帳號時，會提供給我們您的登入資訊等基本資料，這些資訊是為了讓我們服務可以運作。
            </p>
            <p>
              當您參加我們的促銷活動、競賽活動等，我們將會收集您所註冊或輸入的資訊。
            </p>
            <p>
              如果您透過我們提供的電子郵箱和我們的客戶服務團隊聯絡，我們會收集您的電子郵件地址、IP位址和您提供給我們的資訊，這些資訊用於幫助解決您的查詢和確保我們的服務品質。我們會保留與您的通訊記錄，包括我們在刪除您的帳戶後_1_年內收到的有關其他用戶（以及其他用戶關於您）的投訴。
            </p>
            <p>
              當您使用我們的服務時，我們會收集您在我們服務裡所採取的動作資訊，例如您登入的日期和時間、您使用過的功能、您的搜尋條件、您點擊的廣告、功能、您和其他您互動的方式、您在各頻道的操作、您傳給各院所頻道的訊息...等。
            </p>
            <p>
              當您使用我們服務時，在您的同意下，我們可以透過各種方式來收集您的所在地理位置資訊(經度和緯度)，這些資訊可以幫助我們識別您的實際位置。如果您拒絕提供此資訊，我們將不會收集到這項資訊。
            </p>
            <p>
              請注意您需擔保您所提交之必要資訊的真實性，如因資料的錯誤與偏差造成聯繫失常或其他有損於您的情況，我們將不負任何衍生責任。
            </p>
            <h2>肆、與第三人共用個人資料</h2>
            <p>
              本服務不會提供、交換、出租或出售任何您的個人資料給他人，除非法律有明文規定，或在未逾越特定目的(即蒐集之目的)之必要範圍內，而有下列情事者：
            </p>
            <p>一、為免除您生命、身體、自由或財產上之危險。</p>
            <p>
              二、與公務機關、本公司委託之協力機構或學術研究機構合作，基於優化您體驗以急您的利益為統計或學術研究而有必要，且資料經過提供者處理或蒐集者依其揭露方式無從識別特定之當事人。
            </p>
            <p>
              三、當您之行為，違反本條款或可能損害或妨礙本服務與其他您權益或導致任何人遭受損害時，經本服務管理單位研析揭露您的個人資料是為了辨識、聯絡或採取法律行動所必要者。
            </p>
            <p>四、有利於您的權益。</p>
            <h2>伍、外部資訊的取得</h2>
            <p>
              除了您提供給我們資訊以外，我們還會從其他人那裡獲得有關您的資訊，包括：
            </p>
            <p>
              社群媒體：當您使用社群媒體帳號登入我們的服務時，我們將視為您同意我們從社群媒體帳號取得您的登入資訊，這樣一來您可以無需在記憶另一組帳號密碼。
            </p>
            <p>
              其他合作夥伴：我們可能會從其他合作夥伴取得有關您的資訊，例如本網站建立頻道、推播廣告於合作夥伴，這種情況下，他們可能會將您留存於該處的資料傳遞給我們。
            </p>
            <p>
              您的資訊除了用來提供且改善我們的服務，還會用來保護您的帳號安全，並提供您有興趣的廣告給您，以下將會告知使用您的資訊的各項原因。
            </p>
            <h2>陸、 第三人揭露：</h2>
            <p>除有下列情形，本網站不會提供您之個人資料予其他人或法人：</p>
            <p>(一)徵得您之事先同意；</p>
            <p>(二)基於契約約定之使用目的；</p>
            <p>(三)基於法令規定。</p>
            <h2>柒、服務條款修改</h2>
            <p>
              一、本服務之所有者或經營管理者，對於由其自行提供而構成本服務內容之任何資訊，均有權隨時增、刪、修改其內容與形式。
            </p>
            <p>
              二、對於本會員註冊同意書與本服務其他法律聲明之一部或全部，本服務之所有者與經營管理者有權隨時予以增、刪、修改，並於本服務公告。經公告後本服務之您如繼續使用本服務內容時，或於上開增、刪、修改後使用本服務者，視為完全閱讀、知悉、了解並同意遵守本會員註冊同意書與本服務其他法律聲明之修改變更。
            </p>
            <h2>捌、服務停止與更改</h2>
            <p>
              一、本服務保留隨時停止或更改各項服務內容或終止任一帳戶服務之權利，且無需事先通知。
            </p>
            <p>二、於發生下列情形之一時，本服務亦有權停止或中斷提供服務：</p>
            <p>
              （1）對本服務之主機、網路等電子通信設備進行必要之保養及施工時。
            </p>
            <p>（2）發生突發性設備故障時。</p>
            <p>
              （3）由於本服務所申請之電子通信服務不問任何原因被停止，無法提供服務時。
            </p>
            <p>（4）由於天災等不可抗力之因素致使本服務無法提供服務時</p>
            <p>
              三、本服務如因本公司網路維護商、其他協力廠商或相關電信業者網路系統軟硬體設備之故障或失靈、或人為操作上之疏失而全部或一部中斷、暫時無法使用、遲延、或造成資料傳輸或儲存上之錯誤、或遭第三人侵入系統篡改或偽造、變造資料等，均不對您負任何補償或賠償責任。
            </p>
            <h2>玖、擔保責任免除</h2>
            <p>
              一、
              本服務不提供任何明示或默示的擔保，包含但不限於商業適售性、特定目的之適用性及未侵害他人權利。
            </p>
            <p>二、本服務不保證以下事項：</p>
            <p>(1) 本服務將符合您的需求。</p>
            <p>(2) 本服務不受干擾、即時提供、安全可靠或免於出錯。</p>
            <p>(3) 由本服務之使用而取得之結果為正確或可靠。</p>
            <p>
              (4)
              您經由本服務購買或取得之任何產品、服務、資訊或其他資料將符合您的期望。
            </p>
            <p>
              三、
              本服務對於任何包含、經由或連接、下載或從任何與有關本服務所獲得的任何內容、資訊或廣告，不聲明或擔保其正確性或可靠性；並且對於您經本服務提供的內容、廣告、展示而購買、取得的任何產品、資訊或資料，本服務不負保證責任，您應自行判斷該廣告之正確性及可信度。
            </p>
            <p>
              您瞭解並同意，本服務可與其他第三方業者（下稱「內容提供者」）合作，由其提供包括新聞、訊息、廣告等不同內容於本服務內刊登，本服務於刊登時均將註明內容提供者。基於尊重內容提供者之智慧財產權，本服務對其所提供之內容並不做實質之審查或修改，對該等內容之正確真偽亦不負任何責任。對該等內容之正確真偽，您宜自行判斷之。您若認為某些內容涉及侵權或有不實，請逕向該內容提供者反應意見。
            </p>
            <h2>拾、賠償責任限制</h2>
            <p>
              本服務對於您使用各項服務、或因不可抗力之因素無法使用各項服務所導致的任何直接、間接、衍生或特別損害，不負任何賠償責任。若您使用之服務，係有對價者，本服務亦僅就其所支付之對價範圍內，負賠償責任。
            </p>
            <h2>拾壹、隱私保護</h2>
            <p>
              一、
              您同意本服務及其關係企業或合作夥伴，於法律許可範圍內得使用其個人資訊，以提供您其他服務，或就您個人資料製作會員統計資料。
            </p>
            <p>
              二、
              就您所登錄或留存之個人資料，除下列情況外，本服務同意於未獲您授權前，不會對非本服務經營管理之必要人員以外之人揭露您所提供、留存之個人資料：
            </p>
            <p>（1）基於法律之規定。</p>
            <p>（2）應法令或行政、司法機關之要求。</p>
            <p>
              （3）於緊急情況下為維護本服務所有者、經營管理者、其他第三人（含其他會員）之人身安全而有必要者。
            </p>
            <h2>拾貳、通知</h2>
            <p>
              如依法或其他相關規定須為通知時，本服務得以包括但不限於：電子郵件、多媒體簡訊、文字訊息、張貼於本服務對話視窗，或其他合理之方式通知您。但若您違反本條款，或未依據本公司提供之使用方式，您可能不會收到前述通知。您同意本服務依據本條款所為之任何通知，均視為送達。
            </p>
            <h2>拾參、本條款之修改</h2>
            <p>
              本條款將適時依據法律修正、服務相關技術發展及內部管理制度之調整而配合修改，並將最新版本通知或公告您。除法律另有規定外，在本條款變更生效後，如果您繼續使用本服務，即表示其同意並接受修改後的條款內容。
            </p>
            <h2>拾肆、準據法及管轄法院</h2>
            <p>
              本條款之解釋與適用，以及與本條款有關或您與本公司間因智慧醫療服務而產生之爭議或糾紛，應依照中華民國法律予以處理，並以臺灣臺北地方法院為第一審管轄法院。
            </p>
            <p></p>
          </div>
        </Content>
        <Button size="large" onClick={handleButtonClick} fullWidth>
          <span style={{ fontSize: 14 }}>我已閱讀並同意相關服務條款</span>
        </Button>
      </ModalContentWrapper>
    </Modal>
  );
};

TermsModal.propTypes = {
  open: PropTypes.bool,
  title: PropTypes.string,
  description: PropTypes.string,
  buttonText: PropTypes.string,
  onClose: PropTypes.func,
  onButtonClick: PropTypes.func,
};

export default memo(TermsModal);
