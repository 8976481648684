import React, { memo } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { colors, fonts } from 'styles/theme';

const TextWrapper = styled.span`
  display: ${({ block }) => (block ? 'flex' : 'inline-flex')};
  align-items: center;
  color: ${({ color }) => colors[color]};
  padding: 0;
  margin: 0;
  ${({ font }) => fonts[font]};
  ${({ padding }) => padding && `padding: ${padding};`};
  ${({ paddingTop }) => paddingTop && `padding-top: ${paddingTop}px;`};
  ${({ paddingRight }) => paddingRight && `padding-right:${paddingRight}px;`};
  ${({ paddingBottom }) =>
    paddingBottom && `padding-bottom: ${paddingBottom}px;`};
  ${({ paddingLeft }) => paddingLeft && `padding-left: ${paddingLeft}px;`};
  ${({ margin }) => margin && `margin: ${margin};`};
  ${({ marginTop }) => marginTop && `margin-top: ${marginTop}px;`};
  ${({ marginRight }) => marginRight && `margin-right:${marginRight}px;`};
  ${({ marginBottom }) => marginBottom && `margin-bottom: ${marginBottom}px;`};
  ${({ marginLeft }) => marginLeft && `margin-left: ${marginLeft}px;`};
  ${({ nowrap }) => nowrap && `white-space: nowrap;`};
  ${({ preLine }) => preLine && `white-space: pre-line;`};
`;

const Text = ({
  font = 'Body/16px_Regular',
  color = 'SHADES_900',
  content = '',
  padding,
  paddingTop,
  paddingRight,
  paddingBottom,
  paddingLeft,
  margin,
  marginTop,
  marginRight,
  marginBottom,
  marginLeft,
  block,
  nowrap,
  preLine,
  as, // styled component dynamic tag name, e.g. as="button", as="p"
  ...restProps
}) => {
  return (
    <TextWrapper
      font={font}
      color={color}
      padding={padding}
      paddingTop={paddingTop}
      paddingRight={paddingRight}
      paddingBottom={paddingBottom}
      paddingLeft={paddingLeft}
      margin={margin}
      marginTop={marginTop}
      marginRight={marginRight}
      marginBottom={marginBottom}
      marginLeft={marginLeft}
      block={block}
      nowrap={nowrap}
      preLine={preLine}
      as={as}
      {...restProps}
    >
      {content}
    </TextWrapper>
  );
};

Text.propTypes = {
  children: PropTypes.node,
  font: PropTypes.string,
  color: PropTypes.string,
  content: PropTypes.string,
  padding: PropTypes.string,
  paddingTop: PropTypes.number,
  paddingRight: PropTypes.number,
  paddingBottom: PropTypes.number,
  paddingLeft: PropTypes.number,
  margin: PropTypes.string,
  marginTop: PropTypes.number,
  marginRight: PropTypes.number,
  marginBottom: PropTypes.number,
  marginLeft: PropTypes.number,
  block: PropTypes.bool,
  nowrap: PropTypes.bool,
  preLine: PropTypes.bool,
  as: PropTypes.string,
};

export default memo(Text);
