import React, { memo } from 'react';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import FieldBase from 'components/FieldBase';
import { colors } from 'styles/theme';

const DatePickerWrapper = styled.div`
  position: relative;
  input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    color: transparent;
    background: transparent;
    border: none;
  }
  input[type='date']::-webkit-calendar-picker-indicator {
    background: transparent;
    bottom: 0;
    color: transparent;
    cursor: pointer;
    height: auto;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: auto;
  }
`;

const StyledInput = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid ${colors.SHADES_400};
  border-radius: 8px;
  padding: 9px 16px;
  line-height: 1.25;
  font-size: 16px;
  white-space: nowrap;
  color: ${colors.SHADES_900};
  width: 100%;
  i {
    margin-right: 16px;
    color: ${colors.SHADES_400};
  }
  span {
    color: ${colors.SHADES_300};
  }
  label {
    display: flex;
    align-items: center;
  }
  ${({ disabled }) =>
    disabled &&
    `
      &,
      span,
      i {
        cursor: not-allowed;
        color: ${colors.SHADES_300};
        border-color:  ${colors.SHADES_200};
      }
  `}
  ${({ error }) =>
    error &&
    ` &, 
      span,
      i {
        color: ${colors.SYSTEM_ERROR_500};
        border-color: ${colors.SYSTEM_ERROR_500};
        background-color: ${colors.SYSTEM_ERROR_100};
      }
  `}
`;

const DatePicker = ({
  label,
  value = '',
  min,
  max,
  onChange,
  error,
  helperText,
  disabled,
  required,
  placeholder,
}) => {
  return (
    <FieldBase
      label={label}
      error={error}
      helperText={helperText}
      required={required}
      fullWidth
    >
      <DatePickerWrapper>
        <StyledInput error={error} disabled={disabled}>
          <label>
            <i className="ri-calendar-todo-fill" />
            {value && dayjs(value).format('YYYY/MM/DD')}
            {!value && placeholder && <span>{placeholder}</span>}
            <input
              value={value}
              onChange={onChange}
              disabled={disabled}
              min={min}
              max={max}
              type="date"
            />
          </label>
        </StyledInput>
      </DatePickerWrapper>
    </FieldBase>
  );
};

DatePicker.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  min: PropTypes.string,
  max: PropTypes.string,
  onChange: PropTypes.func,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  placeholder: PropTypes.string,
};

export default memo(DatePicker);
