import dayjs from 'dayjs';

export const getUrlParams = (key) => {
  const search = window.location.search;
  let params = new URLSearchParams(
    decodeURIComponent(
      search.includes('liff.state') ? search.split('%3F')[1] : search
    )
  );
  return params.get(key);
};

export const getDisplayDate = (date, showWeekDay) => {
  const weekdayMap = {
    0: '日',
    1: '一',
    2: '二',
    3: '三',
    4: '四',
    5: '五',
    6: '六',
  };
  return `${dayjs(date).format('YYYY/MM/DD')}${
    showWeekDay ? `(星期${weekdayMap[dayjs(date).day()]})` : ''
  }`;
};

export const getDisplayHourAndMinute = (date) => {
  return `${dayjs(date).format('HH:mm')}`;
};

export const parseToken = (token) => {
  try {
    const strings = token.split('.');
    const tokenInfo = JSON.parse(
      decodeURIComponent(
        window.atob(strings[1].replace(/-/g, '+').replace(/_/g, '/'))
      )
    );
    return tokenInfo;
  } catch (e) {
    alert(`token parse error: ${e}`);
  }
};
