import React, { memo } from 'react';

const ErrorSvg = () => (
  <svg
    width="121"
    height="121"
    viewBox="0 0 121 121"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_293_23744)">
      <path
        d="M18.8199 15.86C18.8199 15.86 25.2899 15.49 29.5399 20.25"
        stroke="black"
        strokeWidth="0.63"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.74 13.6301L22.38 19.0501"
        stroke="black"
        strokeWidth="0.63"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27.76 16.66L26.02 19.21"
        stroke="black"
        strokeWidth="0.63"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M89.91 22.0901C89.91 22.0901 94.54 26.6301 93.91 32.9801"
        stroke="black"
        strokeWidth="0.63"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M94.8699 24.2401L89.9399 26.8701"
        stroke="black"
        strokeWidth="0.63"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M95.33 29.2401L92.28 29.6801"
        stroke="black"
        strokeWidth="0.63"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M79.75 33.16C78.5982 32.4323 77.6461 31.4286 76.98 30.24H75.74C73.01 27.94 72.8 21.85 72.8 21.85C72.8 21.85 68.41 26.85 63.69 25.73C63.69 25.73 62.69 22.07 59.57 23.98C59.0565 24.2896 58.6238 24.7166 58.3074 25.226C57.991 25.7353 57.8 26.3125 57.75 26.91C57.07 21.85 57.54 17.12 63.99 13.04C71.99 8.04003 78.88 14.09 78.88 14.09C85.57 16.62 85.4 24.91 84.29 29.3C84.1434 29.9076 83.9494 30.5027 83.71 31.08C82.7431 32.9996 81.112 34.5027 79.12 35.31C78.64 35.52 78.31 35.63 78.28 35.64C78.82 34.73 79.33 33.9 79.75 33.16Z"
        fill="#4271DD"
      />
      <path
        d="M70.1099 102.56L70.2699 100.47C70.454 100.48 70.6374 100.442 70.8023 100.36C70.9671 100.277 71.1076 100.153 71.2099 100C71.8071 98.9756 72.2645 97.8758 72.5699 96.73C72.9899 95.73 73.0599 94.73 74.3899 93.96C74.927 93.6016 75.4262 93.1895 75.8799 92.73H77.6299L76.6899 95.45L71.1199 111.59C70.7 111.59 70.2199 111.65 69.6899 111.66C63.9399 111.79 52.8499 110.66 45.3299 109.82C40.7999 109.3 37.5699 108.88 37.5699 108.88L46.2199 99.4L47.7399 100.79L70.1099 102.56Z"
        fill="#EEEEEE"
      />
      <path
        d="M76.6899 95.4401L77.6299 113C75.0333 113.503 72.3948 113.757 69.7499 113.76L69.6899 111.65C70.2199 111.65 70.6899 111.65 71.1199 111.58L76.6899 95.4401Z"
        fill="#EEEEEE"
      />
      <path
        d="M71.2 48.48L67.41 53.98C66.22 51.87 64.72 49.48 63.52 47.6C63.6 47.6 67.52 48.42 69.94 47.6C70.3061 47.9639 70.7324 48.2616 71.2 48.48Z"
        fill="#EEEEEE"
      />
      <path
        d="M70.7 94.1801C70.7 94.1801 71.4 94.1801 71.16 95.0301C70.92 95.8801 70.7 96.2701 70.49 96.9001C70.2822 97.7612 70.0046 98.604 69.66 99.4201C69.6127 99.5253 69.5918 99.6404 69.599 99.7556C69.6062 99.8707 69.6414 99.9823 69.7015 100.081C69.7615 100.179 69.8447 100.262 69.9438 100.321C70.0428 100.38 70.1548 100.414 70.27 100.42L70.11 102.51L47.74 100.73L46.22 99.3401L49.03 96.2601L50.26 97.3801L59.69 98.2201L61.62 97.2201C61.6846 97.3494 61.7848 97.4576 61.9088 97.532C62.0328 97.6064 62.1754 97.6439 62.32 97.6401C62.9557 97.6903 63.5936 97.5731 64.17 97.3001H64.33C63.92 97.9301 64.27 98.6601 65.25 98.5001C66.51 98.3701 69.66 95.2301 70.7 94.1801Z"
        fill="#EEEEEE"
      />
      <path
        d="M69.69 111.65L69.75 113.76C58.83 114.07 42.39 113 42.39 113L45.33 109.81C52.85 110.66 63.94 111.78 69.69 111.65Z"
        fill="#EEEEEE"
      />
      <path
        d="M63.57 91.5301C62.7794 92.9611 61.6323 94.1633 60.2399 95.0201C60.2038 95.0851 60.1801 95.1563 60.1699 95.2301L58.4599 94.9101L53.3299 94.4001L51.95 93.1401L52.8499 92.1401L54.45 90.3901L61.57 90.7301C61.4799 91.8701 62.8899 91.8201 63.57 91.5301Z"
        fill="#EEEEEE"
      />
      <path
        d="M61.84 96.07V96.29C61.7265 96.4274 61.6458 96.5889 61.6043 96.7623C61.5627 96.9356 61.5612 97.1161 61.6 97.29L59.67 98.29L50.24 97.45L49.01 96.33L51.91 93.16L53.29 94.42L58.42 94.93L60.13 95.25C60.09 95.58 60.2 96.2 61.84 96.07Z"
        fill="#EEEEEE"
      />
      <path
        d="M57.57 31.73C57.8125 32.1846 58.1436 32.5861 58.5438 32.9107C58.944 33.2353 59.4051 33.4765 59.9 33.62C58.27 38.1 61.4399 44.36 61.4399 44.36C62.4399 48.24 60.66 49.45 60.66 49.45C60.66 48.15 60.06 47.82 60.06 47.82C60.0448 48.3664 59.9913 48.9111 59.9 49.45C58.82 55.64 52.72 56 52.72 56C56.91 52 52.09 46.56 52.09 46.56C51.6562 46.9445 51.361 47.4611 51.25 48.03C50.4547 46.0596 50.0145 43.9639 49.95 41.84C49.84 33.74 57.2 31.28 57.38 31.22C57.4277 31.3954 57.4913 31.5661 57.57 31.73Z"
        fill="#4271DD"
      />
      <path
        d="M78.28 35.61C78.82 34.75 79.33 33.9 79.75 33.16"
        stroke="black"
        strokeWidth="0.63"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M75.28 39.66C76.4107 38.4299 77.4157 37.0899 78.28 35.66"
        stroke="black"
        strokeWidth="0.63"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M57.75 26.8901C57.685 27.508 57.7393 28.1327 57.91 28.7301C58.096 29.4574 58.4434 30.1336 58.9263 30.7083C59.4093 31.283 60.0156 31.7416 60.7 32.0501L60.61 32.6001L60.53 33.1901C60.5101 33.3996 60.5101 33.6105 60.53 33.8201C60.559 34.3962 60.7534 34.9517 61.09 35.4201C62.49 37.4201 65.59 41.5601 67.17 42.1501C67.8175 42.3493 68.5033 42.3904 69.17 42.2701C71.2956 41.9261 73.3179 41.1131 75.09 39.8901C75.1855 39.8164 75.2757 39.7362 75.36 39.6501"
        stroke="black"
        strokeWidth="0.63"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M75.74 30.2101C73.01 27.9101 72.8 21.8201 72.8 21.8201C72.8 21.8201 68.41 26.8201 63.69 25.7001C63.69 25.7001 62.69 22.0401 59.57 23.9501C59.0565 24.2597 58.6238 24.6867 58.3074 25.196C57.991 25.7054 57.8 26.2825 57.75 26.8801"
        stroke="black"
        strokeWidth="0.63"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M75.11 26.0201C75.5664 27.4949 76.194 28.9113 76.98 30.2401C77.6461 31.4287 78.5981 32.4323 79.75 33.1601L80.14 33.3601"
        stroke="black"
        strokeWidth="0.63"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M78.28 35.6101C78.28 35.6101 78.64 35.4901 79.12 35.2801C81.1119 34.4727 82.743 32.9696 83.71 31.0501C83.9494 30.4727 84.1433 29.8776 84.29 29.2701C85.4 24.8801 85.57 16.5901 78.88 14.0601C78.88 14.0601 71.96 7.98006 63.99 13.0101C57.57 17.0901 57.07 21.8201 57.75 26.8801"
        stroke="black"
        strokeWidth="0.63"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M57.24 30.73C57.3551 30.9451 57.5114 31.1353 57.7 31.29C58.5034 32.0068 59.503 32.4666 60.57 32.61"
        stroke="black"
        strokeWidth="0.63"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M57.91 28.73C57.91 28.73 56.69 29.62 57.24 30.67"
        stroke="black"
        strokeWidth="0.63"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M60.5 33.83C60.2791 33.7782 60.0619 33.7114 59.85 33.63C59.3634 33.4794 58.9114 33.234 58.5201 32.9079C58.1288 32.5818 57.8059 32.1815 57.57 31.73C57.4914 31.5662 57.4278 31.3954 57.38 31.22C57.3254 31.0416 57.2951 30.8566 57.29 30.67V30.67"
        stroke="black"
        strokeWidth="0.63"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M69.94 47.6C69.2584 46.9144 68.8095 46.0318 68.657 45.0771C68.5045 44.1224 68.6559 43.1439 69.09 42.28"
        stroke="black"
        strokeWidth="0.63"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M84.29 29.2701C84.29 29.2701 86.01 30.0101 85.74 33.1501C85.57 34.7301 84.49 37.3001 82.93 39.9601C80.29 44.4401 76.39 49.0901 73.85 49.0901C73.3788 49.0885 72.9093 49.0349 72.45 48.9301C72.0156 48.8346 71.5955 48.6833 71.2 48.4801C70.7358 48.2607 70.313 47.963 69.95 47.6001"
        stroke="black"
        strokeWidth="0.63"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M75.28 39.66C75.36 39.66 77.22 39.87 77.63 42.66"
        stroke="black"
        strokeWidth="0.63"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M70.49 44.7301C71.238 44.9592 72.0305 45.0025 72.799 44.8561C73.5675 44.7097 74.2886 44.3781 74.9 43.8901"
        stroke="black"
        strokeWidth="0.63"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M79.12 35.2801C79.4898 35.5876 79.9281 35.8018 80.398 35.9045C80.8679 36.0072 81.3556 35.9954 81.82 35.8701C82.326 35.7297 82.7938 35.477 83.1886 35.1307C83.5834 34.7844 83.8949 34.3535 84.1 33.8701"
        stroke="black"
        strokeWidth="0.63"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M57.33 31.23C57.15 31.29 49.79 33.75 49.9 41.85C49.9646 43.9739 50.4047 46.0696 51.2 48.04C51.311 47.4711 51.6062 46.9545 52.04 46.57C52.04 46.57 56.86 52.02 52.67 56.01C52.67 56.01 58.77 55.65 59.85 49.46C59.9414 48.9211 59.9949 48.3764 60.01 47.83C60.01 47.83 60.59 48.16 60.61 49.46C60.61 49.46 62.34 48.25 61.39 44.37C61.39 44.37 58.22 38.11 59.85 33.63"
        stroke="black"
        strokeWidth="0.63"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M57.5699 31.73C57.4299 31.84 52.7299 35.95 53.5699 41.53"
        stroke="black"
        strokeWidth="0.63"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M82.96 39.9701C82.96 39.9701 84.55 40.0701 86.1 42.6601C87.65 45.2501 96.1 62.7201 96.92 65.8601C97.74 69.0001 86.22 80.7301 86.22 80.7301L85.41 81.7301L79.3 89.5001C79.3 89.5001 79.21 89.3301 79.03 89.0501C78.26 87.8501 75.84 84.5901 71.93 84.0501C71.177 83.945 70.4131 83.945 69.66 84.0501C69.66 84.0501 72.54 80.1101 76.19 75.7501L76.59 75.2701C78.19 73.3701 79.92 71.4201 81.59 69.6901C82.6708 68.5645 83.8161 67.5028 85.02 66.5101"
        stroke="black"
        strokeWidth="0.63"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M84.0999 48.05C84.0999 48.05 82.2399 52.65 81.6099 53.28C81.6099 53.28 81.7399 55.8901 81.8199 59.1101C81.9743 62.6517 81.8907 66.1996 81.5699 69.73"
        stroke="black"
        strokeWidth="0.63"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M85.04 66.57C84.8291 65.9886 84.8291 65.3515 85.04 64.77C84.1936 62.765 83.1163 60.8656 81.83 59.11"
        stroke="black"
        strokeWidth="0.63"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M49.9001 41.85C49.7601 41.85 43.8401 42.62 42.1801 45.31C40.5201 48 37.1801 56.43 37.1801 56.43"
        stroke="black"
        strokeWidth="0.63"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M46.5699 50.73C47.1244 52.4229 47.3783 54.1997 47.3199 55.98C47.3199 57.59 47.2599 59.89 47.1099 62.66C46.8999 66.37 46.5799 70.92 46.3199 75.78"
        stroke="black"
        strokeWidth="0.63"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M47.0601 62.73C47.0601 62.73 43.4401 72.99 41.5701 74.04C39.7001 75.09 35.9101 76.98 33.5701 73.2L33.2101 72.59C30.5701 68.02 23.7101 54.14 23.3101 50.35C23.6873 50.0326 24.0812 49.7355 24.4901 49.46C25.2767 48.9033 26.1177 48.4276 27.0001 48.04"
        stroke="black"
        strokeWidth="0.63"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M37.9801 58.3101L37.1001 56.4501L33.4301 48.6801"
        stroke="black"
        strokeWidth="0.63"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.57 36.2901C20.57 36.2901 23.51 33.9901 26.02 33.5701C26.1014 33.3971 26.1446 33.2086 26.1466 33.0174C26.1485 32.8262 26.1093 32.6369 26.0315 32.4622C25.9536 32.2876 25.8391 32.1318 25.6956 32.0055C25.5521 31.8791 25.3831 31.7852 25.2 31.7301C23.87 31.3501 19.44 33.2001 18 35.4301C17.8518 35.6463 17.734 35.8818 17.65 36.1301C16.81 38.6501 21.84 44.3101 22.47 45.1301C23.2374 46.535 23.9057 47.9919 24.47 49.4901"
        stroke="black"
        strokeWidth="0.63"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27.29 40.73L27.17 40.82C25.9758 41.557 24.8271 42.3651 23.73 43.24"
        stroke="black"
        strokeWidth="0.63"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M26.03 33.5701C26.03 33.5701 27.08 33.7801 27.08 35.2501C27.3877 35.4478 27.6311 35.7309 27.7803 36.0648C27.9295 36.3988 27.9781 36.769 27.92 37.1301C28.1277 37.1933 28.3185 37.3024 28.4784 37.4493C28.6382 37.5962 28.7631 37.777 28.8437 37.9786C28.9243 38.1802 28.9587 38.3972 28.9442 38.6138C28.9297 38.8305 28.8668 39.041 28.76 39.2301"
        stroke="black"
        strokeWidth="0.63"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27.08 35.2501C25.3068 36.0324 23.6291 37.0155 22.08 38.1801"
        stroke="black"
        strokeWidth="0.63"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27.29 37.55C26.0446 38.1022 24.8443 38.7509 23.7 39.49"
        stroke="black"
        strokeWidth="0.63"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.5699 43.32C24.7447 44.8873 26.0385 46.3617 27.4399 47.73C28.046 48.436 28.8689 48.9212 29.7799 49.11C30.2899 49.11 30.8299 49.11 31.3599 49.03C32.0585 49.0021 32.7522 48.9015 33.4299 48.73C34.354 48.5026 35.1861 47.9971 35.8137 47.2818C36.4414 46.5665 36.8345 45.6758 36.9399 44.73C37.5699 40.8 33.5699 36.93 29.5699 38.85L29.2299 39.02L28.8299 39.26C28.1955 39.6561 27.6478 40.1766 27.2199 40.79C26.7082 41.4989 26.4067 42.3376 26.3499 43.21"
        stroke="black"
        strokeWidth="0.63"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.2 31.7301C25.1179 31.4866 25.1024 31.2256 25.1552 30.9742C25.2079 30.7227 25.327 30.49 25.5 30.3001L25.71 30.0001L26.21 29.3301C27.21 27.9801 26.66 24.8101 23.1 23.5501C22.2021 23.1796 21.204 23.1295 20.2734 23.4081C19.3429 23.6867 18.5366 24.2771 17.99 25.0801C17.1285 26.2513 16.5128 27.5847 16.18 29.0001C15.34 32.1501 18 35.4301 18 35.4301"
        stroke="black"
        strokeWidth="0.63"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.94 30.0001C23.94 30.0001 18.48 30.6301 19.74 25.1801"
        stroke="black"
        strokeWidth="0.63"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.5 30.2501C25.5 30.2501 27.95 32.7301 28.57 33.5701C29.19 34.4101 29.52 38.8501 29.52 38.8501"
        stroke="black"
        strokeWidth="0.63"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27.88 41.85C27.88 41.85 28.13 45.1 30.88 45.94"
        stroke="black"
        strokeWidth="0.63"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M63.5699 47.6C64.7699 49.48 66.2699 51.87 67.4599 53.98C67.9899 54.91 68.4599 55.79 68.7999 56.53C68.9999 56.96 69.1699 57.35 69.2899 57.69V75.45"
        stroke="black"
        strokeWidth="0.63"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M61.39 44.3701C61.39 44.3701 62.3 45.7201 63.51 47.6001"
        stroke="black"
        strokeWidth="0.63"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M67.77 59.9101L60.61 56.0101L61.67 50.7601L59.85 49.4501"
        stroke="black"
        strokeWidth="0.63"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M67.41 53.98L71.2 48.48"
        stroke="black"
        strokeWidth="0.63"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M69.65 56.4201L72.22 54.1701L75.58 51.2301L73.85 49.0901"
        stroke="black"
        strokeWidth="0.63"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M63.51 47.6C63.59 47.6 67.51 48.42 69.93 47.6"
        stroke="black"
        strokeWidth="0.63"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M69.6599 84.11C69.5448 84.6091 69.6196 85.1332 69.87 85.58"
        stroke="black"
        strokeWidth="0.63"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M64.91 88.4001C64.7928 88.5647 64.6955 88.7426 64.62 88.9301C64.3445 89.825 63.9932 90.6948 63.57 91.5301C62.7795 92.9611 61.6323 94.1633 60.24 95.0201C60.2038 95.0851 60.1801 95.1564 60.17 95.2301C60.11 95.5801 60.22 96.2301 61.86 96.0701H61.92"
        stroke="black"
        strokeWidth="0.63"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M71.93 84.11C71.3038 84.7074 70.6354 85.2588 69.93 85.76C68.6594 86.7635 67.1904 87.4861 65.62 87.8801C65.4609 87.8995 65.3088 87.9569 65.1766 88.0474C65.0443 88.1379 64.9357 88.2588 64.86 88.4"
        stroke="black"
        strokeWidth="0.63"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M66.57 90.1001C66.57 90.1001 64.21 94.3901 62.75 95.4301C62.4096 95.6643 62.112 95.9552 61.87 96.2901C61.7565 96.4275 61.6758 96.589 61.6342 96.7623C61.5926 96.9356 61.5912 97.1161 61.63 97.2901C61.6946 97.4194 61.7948 97.5277 61.9188 97.6021C62.0428 97.6765 62.1854 97.7139 62.33 97.7101C62.9658 97.7603 63.6036 97.6431 64.18 97.3701"
        stroke="black"
        strokeWidth="0.63"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M68.57 91.4501C68.57 91.4501 65.84 96.2701 64.79 96.9001C64.589 97.02 64.418 97.1842 64.29 97.3801C63.88 98.0101 64.23 98.7401 65.21 98.5801C66.47 98.3701 69.62 95.2301 70.66 94.1801C70.66 94.1801 71.36 94.1801 71.12 95.0301C70.88 95.8801 70.66 96.2701 70.45 96.9001C70.2422 97.7612 69.9646 98.604 69.62 99.4201C69.5727 99.5253 69.5518 99.6404 69.559 99.7556C69.5662 99.8707 69.6014 99.9823 69.6615 100.081C69.7216 100.179 69.8047 100.262 69.9038 100.321C70.0028 100.38 70.1148 100.414 70.23 100.42C70.414 100.43 70.5975 100.392 70.7623 100.31C70.9272 100.227 71.0677 100.103 71.17 99.9501C71.777 98.9434 72.2478 97.8606 72.57 96.7301C72.99 95.7301 73.06 94.7301 74.39 93.9601C74.927 93.6016 75.4263 93.1895 75.88 92.7301C77.0501 91.6407 78.1056 90.4344 79.03 89.1301"
        stroke="black"
        strokeWidth="0.63"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M64.91 88.4001C64.78 88.4501 61.67 89.7301 61.57 90.7301C61.47 91.7301 62.88 91.7301 63.57 91.4901"
        stroke="black"
        strokeWidth="0.63"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M86.12 65.61C86.9032 65.7143 87.6968 65.7143 88.48 65.61"
        stroke="black"
        strokeWidth="0.63"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.14 90.2401V93.1101C12.1408 93.4941 12.2604 93.8685 12.4824 94.1818C12.7044 94.4951 13.018 94.7321 13.38 94.8601L29.57 100.49L32.65 101.56C32.8954 101.644 33.1555 101.675 33.4137 101.653C33.6719 101.63 33.9227 101.555 34.15 101.43L51.5 91.7301C51.6823 91.6345 51.8451 91.5056 51.98 91.3501C52.2857 91.009 52.4564 90.5681 52.46 90.1101V87.2601"
        stroke="black"
        strokeWidth="0.63"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M52.25 87.26L34.03 97.26C33.8406 97.3639 33.6317 97.4273 33.4166 97.4463C33.2014 97.4652 32.9846 97.4392 32.78 97.37L31.57 96.97L12.14 90.24L15.42 88.49C19.78 86.17 27.14 82.29 29.61 80.99C29.8721 80.8468 30.1604 80.7579 30.4577 80.7287C30.7549 80.6994 31.055 80.7305 31.34 80.82L34.45 81.75L37.45 82.66L52.31 87.14H52.39L52.25 87.26Z"
        stroke="black"
        strokeWidth="0.63"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.87 91.9301L26.28 89.6001L37.1 83.8701"
        stroke="black"
        strokeWidth="0.63"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.89 92.0501L29.63 89.0101L38.82 92.5001L33.58 95.4301L23.89 92.0501Z"
        stroke="black"
        strokeWidth="0.63"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M39.24 91.0301C39.9359 91.0301 40.5 90.748 40.5 90.4001C40.5 90.0521 39.9359 89.7701 39.24 89.7701C38.5441 89.7701 37.98 90.0521 37.98 90.4001C37.98 90.748 38.5441 91.0301 39.24 91.0301Z"
        stroke="black"
        strokeWidth="0.63"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M36.31 89.7701C37.0059 89.7701 37.57 89.488 37.57 89.1401C37.57 88.7921 37.0059 88.5101 36.31 88.5101C35.6142 88.5101 35.05 88.7921 35.05 89.1401C35.05 89.488 35.6142 89.7701 36.31 89.7701Z"
        stroke="black"
        strokeWidth="0.63"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M33.37 88.5101C34.0659 88.5101 34.63 88.228 34.63 87.8801C34.63 87.5321 34.0659 87.2501 33.37 87.2501C32.6741 87.2501 32.11 87.5321 32.11 87.8801C32.11 88.228 32.6741 88.5101 33.37 88.5101Z"
        stroke="black"
        strokeWidth="0.63"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M42.18 89.56C42.8759 89.56 43.44 89.278 43.44 88.93C43.44 88.5821 42.8759 88.3 42.18 88.3C41.4842 88.3 40.92 88.5821 40.92 88.93C40.92 89.278 41.4842 89.56 42.18 89.56Z"
        stroke="black"
        strokeWidth="0.63"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M39.24 88.3C39.9359 88.3 40.5 88.018 40.5 87.67C40.5 87.3221 39.9359 87.04 39.24 87.04C38.5441 87.04 37.98 87.3221 37.98 87.67C37.98 88.018 38.5441 88.3 39.24 88.3Z"
        stroke="black"
        strokeWidth="0.63"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M36.31 87.04C37.0059 87.04 37.57 86.758 37.57 86.41C37.57 86.0621 37.0059 85.78 36.31 85.78C35.6142 85.78 35.05 86.0621 35.05 86.41C35.05 86.758 35.6142 87.04 36.31 87.04Z"
        stroke="black"
        strokeWidth="0.63"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M45.3201 88.0901C46.0159 88.0901 46.5801 87.808 46.5801 87.4601C46.5801 87.1121 46.0159 86.8301 45.3201 86.8301C44.6242 86.8301 44.0601 87.1121 44.0601 87.4601C44.0601 87.808 44.6242 88.0901 45.3201 88.0901Z"
        stroke="black"
        strokeWidth="0.63"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M42.39 86.8401C43.0859 86.8401 43.65 86.558 43.65 86.2101C43.65 85.8621 43.0859 85.5801 42.39 85.5801C41.6941 85.5801 41.13 85.8621 41.13 86.2101C41.13 86.558 41.6941 86.8401 42.39 86.8401Z"
        stroke="black"
        strokeWidth="0.63"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M39.4499 85.5801C40.1458 85.5801 40.7099 85.298 40.7099 84.9501C40.7099 84.6021 40.1458 84.3201 39.4499 84.3201C38.7541 84.3201 38.1899 84.6021 38.1899 84.9501C38.1899 85.298 38.7541 85.5801 39.4499 85.5801Z"
        stroke="black"
        strokeWidth="0.63"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.75 89.7701L16.01 89.6401L22.68 86.2001V87.8801L25.82 88.7201"
        stroke="black"
        strokeWidth="0.63"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.35 85.1601L30.43 82.0101"
        stroke="black"
        strokeWidth="0.63"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.1899 86.8301L27.7099 87.4601"
        stroke="black"
        strokeWidth="0.63"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M76.68 95.4301L77.62 92.7101H75.88H75.73"
        stroke="black"
        strokeWidth="0.63"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M61.5699 90.73L54.4199 90.36L52.8199 92.11L51.9199 93.11L49.0199 96.2801L46.2099 99.36L37.5699 108.87C37.5699 108.87 40.7999 109.29 45.3299 109.81C52.8499 110.66 63.9399 111.81 69.6899 111.65C70.2199 111.65 70.6899 111.65 71.1199 111.58L76.6899 95.44"
        stroke="black"
        strokeWidth="0.63"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M45.33 109.81L42.39 113C42.39 113 58.83 114.07 69.75 113.76C72.3948 113.757 75.0334 113.503 77.63 113L76.69 95.4401"
        stroke="black"
        strokeWidth="0.63"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.6 75.8101H32.93H46.33H69.23H76.18"
        stroke="black"
        strokeWidth="0.63"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M90.83 75.8101H109.92"
        stroke="black"
        strokeWidth="0.63"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M70.44 30.9201C70.3231 30.86 70.1873 30.8484 70.0619 30.8877C69.9365 30.927 69.8316 31.0141 69.7699 31.1301L69.32 32.0101C69.2831 32.0726 69.2604 32.1423 69.2532 32.2144C69.246 32.2866 69.2546 32.3594 69.2784 32.4279C69.3022 32.4964 69.3406 32.5589 69.3909 32.6111C69.4413 32.6633 69.5024 32.7039 69.57 32.7301C69.6455 32.7487 69.7244 32.7487 69.8 32.7301C69.8901 32.7314 69.9789 32.7079 70.0565 32.662C70.1342 32.6161 70.1976 32.5497 70.24 32.4701L70.69 31.5901C70.7198 31.53 70.7369 31.4645 70.7404 31.3976C70.7439 31.3307 70.7336 31.2637 70.7101 31.2009C70.6867 31.1381 70.6506 31.0808 70.6042 31.0325C70.5577 30.9842 70.5018 30.9459 70.44 30.9201Z"
        stroke="black"
        strokeWidth="0.63"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M75.4701 33.2301C75.4132 33.1992 75.3507 33.1801 75.2863 33.174C75.2219 33.1678 75.1569 33.1748 75.0953 33.1944C75.0337 33.214 74.9766 33.2459 74.9276 33.2881C74.8786 33.3303 74.8386 33.382 74.8101 33.4401L74.3501 34.3201C74.2908 34.4382 74.2806 34.5749 74.3218 34.7005C74.3631 34.826 74.4523 34.9301 74.5701 34.9901C74.6378 35.0256 74.7136 35.0428 74.7901 35.0401C74.8813 35.0405 74.9708 35.0154 75.0485 34.9677C75.1262 34.92 75.1891 34.8516 75.2301 34.7701L75.6901 33.8901C75.7192 33.8324 75.7366 33.7694 75.7414 33.7049C75.7461 33.6404 75.738 33.5756 75.7175 33.5143C75.6971 33.4529 75.6647 33.3962 75.6222 33.3474C75.5797 33.2987 75.528 33.2588 75.4701 33.2301Z"
        stroke="black"
        strokeWidth="0.63"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M73.22 32.73L71.67 36.21"
        stroke="black"
        strokeWidth="0.63"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M69.71 36.9501C69.5595 36.9166 69.4037 36.9137 69.2521 36.9416C69.1004 36.9695 68.9559 37.0277 68.8271 37.1126C68.6984 37.1975 68.588 37.3074 68.5026 37.4358C68.4172 37.5642 68.3585 37.7085 68.33 37.8601C68.1 38.8601 69.21 39.5601 70.06 39.7401C70.61 39.8501 71.78 39.7401 71.8 38.9701C71.82 38.2001 70.94 37.9201 70.49 37.5501C70.22 37.3301 70.16 37.0801 69.79 36.9701L69.71 36.9501Z"
        stroke="black"
        strokeWidth="0.63"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M69.45 28.11C70.3858 28.5551 71.4141 28.7711 72.45 28.74"
        stroke="black"
        strokeWidth="0.63"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M34.3 78.41C34.104 78.9458 34.0164 79.5152 34.0421 80.0851C34.0679 80.655 34.2065 81.2141 34.45 81.73"
        stroke="black"
        strokeWidth="0.63"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M34.3 78.4101C35.48 79.2401 36.24 77.1401 35.71 77.0701C35.36 77.0201 34.68 77.5001 34.3 78.4101Z"
        stroke="black"
        strokeWidth="0.63"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M33.44 72.0201C33.36 72.2001 33.29 72.3901 33.22 72.5901C32.8639 73.6241 32.7644 74.7291 32.93 75.8101C33.0191 76.3231 33.1878 76.8191 33.43 77.2801C33.635 77.7152 33.9318 78.1007 34.3 78.4101"
        stroke="black"
        strokeWidth="0.63"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M33.4399 72.0201C33.5799 72.1701 33.6999 72.2701 33.7899 72.3701C34.2099 72.7901 35.9299 70.8001 34.9699 70.4301C34.5699 70.2701 33.9099 70.9501 33.4399 72.0201Z"
        stroke="black"
        strokeWidth="0.63"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M32.7 63.5101C32.3145 64.6409 32.1714 65.8403 32.28 67.0301C32.47 70.4801 33.01 71.5301 33.44 72.0301"
        stroke="black"
        strokeWidth="0.63"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M32.7 63.51C32.8558 63.825 33.1129 64.0785 33.43 64.23C34.43 64.61 35.37 62.23 34.79 61.75C34.41 61.42 33.32 61.87 32.7 63.51Z"
        stroke="black"
        strokeWidth="0.63"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M32.02 57.67C31.9429 58.0889 31.9061 58.5142 31.91 58.94C31.8232 60.5036 32.0934 62.0664 32.7 63.51"
        stroke="black"
        strokeWidth="0.63"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M32.02 57.67C33.5 58.41 34.33 56.2 33.77 56.01C33.21 55.82 32.34 56.09 32.02 57.67Z"
        stroke="black"
        strokeWidth="0.63"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M31.3601 49.0601C31.3601 49.0601 30.0201 56.2201 31.7001 57.4801C31.7989 57.5558 31.9063 57.6195 32.0201 57.6701"
        stroke="black"
        strokeWidth="0.63"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M81.22 78.1901C81.35 79.8901 82.43 81.6601 85.41 81.7901H85.77"
        stroke="black"
        strokeWidth="0.63"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M81.22 78.19C83.38 77.54 83.41 74.26 82.22 75.09C81.8322 75.4991 81.541 75.99 81.368 76.5265C81.1949 77.063 81.1444 77.6314 81.22 78.19V78.19Z"
        stroke="black"
        strokeWidth="0.63"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M76.51 69.9401C76.0507 71.7101 76.0714 73.5706 76.57 75.3301C76.7471 75.951 77.0687 76.5211 77.5084 76.9939C77.9481 77.4666 78.4936 77.8286 79.1 78.0501C79.7686 78.313 80.5024 78.3619 81.2 78.1901"
        stroke="black"
        strokeWidth="0.63"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M76.51 69.9401C78.82 71.1401 78.13 66.8401 77.17 68.2101C76.8522 68.7445 76.629 69.3298 76.51 69.9401V69.9401Z"
        stroke="black"
        strokeWidth="0.63"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M74.89 63.73C74.33 65.73 74.42 68.62 76.19 69.73C76.2912 69.8022 76.3982 69.8657 76.51 69.92"
        stroke="black"
        strokeWidth="0.63"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M74.89 63.7301C75.0154 63.7743 75.1471 63.798 75.28 63.8001C76.79 63.8001 77.68 61.4301 76.19 61.8001C75.67 61.8701 75.19 62.6501 74.89 63.7301Z"
        stroke="black"
        strokeWidth="0.63"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M74.57 58.3801C73.32 60.9701 73.81 63.2701 74.94 63.7001"
        stroke="black"
        strokeWidth="0.63"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M74.5699 58.3801C74.6942 58.4173 74.8213 58.444 74.9499 58.4601C78.9499 58.9401 76.8399 54.5101 74.9499 57.6701C74.7599 57.9101 74.6299 58.1501 74.5699 58.3801Z"
        stroke="black"
        strokeWidth="0.63"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M72.45 48.9301C72.1764 50.6659 72.1026 52.4274 72.23 54.1801C72.41 56.1101 73 57.9401 74.52 58.3801"
        stroke="black"
        strokeWidth="0.63"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M60.25 11.0201C59.5706 10.992 58.9277 10.7049 58.4535 10.2176C57.9792 9.73035 57.7096 9.07997 57.7 8.40008C57.7 6.72008 58.7 6.51008 59.59 7.14008C60.48 7.77008 60.43 9.66008 60.43 9.66008"
        stroke="black"
        strokeWidth="0.63"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M57.7 13.0101C57.7 13.0101 56.44 9.87007 54.98 10.7101C53.52 11.5501 53.98 13.7101 56.86 14.4001"
        stroke="black"
        strokeWidth="0.63"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M66.3 65.02C66.8799 65.02 67.35 64.5499 67.35 63.97C67.35 63.3901 66.8799 62.92 66.3 62.92C65.7201 62.92 65.25 63.3901 65.25 63.97C65.25 64.5499 65.7201 65.02 66.3 65.02Z"
        stroke="black"
        strokeWidth="0.63"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M68.71 108.54L43.12 106.65"
        stroke="black"
        strokeWidth="0.63"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M69.9699 105.6L45.3199 103.69"
        stroke="black"
        strokeWidth="0.63"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M71.44 102.67L70.11 102.56L47.74 100.78"
        stroke="black"
        strokeWidth="0.63"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M59.69 98.2601L50.25 97.4301"
        stroke="black"
        strokeWidth="0.63"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M58.4301 94.9101L53.3101 94.4001"
        stroke="black"
        strokeWidth="0.63"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M29.62 98.7201L29.34 98.8901"
        stroke="black"
        strokeWidth="0.63"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M30.3 100.22L31.25 99.6501L31.36 99.5801C31.5145 99.486 31.6335 99.3435 31.6986 99.1747C31.7637 99.0059 31.7711 98.8204 31.7197 98.6469C31.6684 98.4735 31.5612 98.3219 31.4147 98.2158C31.2683 98.1096 31.0908 98.0549 30.91 98.0601C30.824 98.0449 30.736 98.0449 30.65 98.0601V98.0601C30.5714 98.0938 30.4975 98.1375 30.43 98.1901L29.64 98.6701"
        stroke="black"
        strokeWidth="0.63"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M29.63 98.73C29.7063 98.7196 29.7837 98.7196 29.86 98.73C29.9664 98.7326 30.0712 98.7562 30.1685 98.7993C30.2658 98.8424 30.3536 98.9043 30.427 98.9814C30.5003 99.0584 30.5578 99.1492 30.5961 99.2485C30.6343 99.3478 30.6527 99.4537 30.65 99.56C30.6512 99.6961 30.6201 99.8305 30.5592 99.9522C30.4984 100.074 30.4095 100.179 30.3 100.26V100.26C30.1776 100.347 30.0303 100.393 29.88 100.39C29.7719 100.392 29.6646 100.371 29.5651 100.329C29.4656 100.286 29.3761 100.223 29.3025 100.144C29.229 100.065 29.1729 99.971 29.138 99.8686C29.1031 99.7663 29.0902 99.6577 29.1 99.55C29.0983 99.4344 29.1205 99.3196 29.1653 99.2129C29.21 99.1062 29.2763 99.0099 29.36 98.93C29.4381 98.8514 29.5342 98.793 29.64 98.76L29.63 98.73Z"
        stroke="black"
        strokeWidth="0.63"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M106.2 81.8001H83.66C83.4373 81.8102 83.2228 81.8872 83.0444 82.0209C82.8661 82.1547 82.7321 82.3391 82.66 82.5501L78.16 95.6401H111.8L107.29 82.5501C107.214 82.3237 107.067 82.1282 106.87 81.9929C106.674 81.8576 106.438 81.7899 106.2 81.8001Z"
        fill="white"
        stroke="black"
        strokeWidth="0.63"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M107.14 89.67H95.69L94.73 93.34H108.31L107.14 89.67Z"
        stroke="black"
        strokeWidth="0.63"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M78.04 95.6401H111.8V98.5001C111.8 98.8608 111.657 99.2067 111.402 99.4617C111.147 99.7168 110.801 99.8601 110.44 99.8601H79.44C79.2581 99.8654 79.0769 99.8342 78.9073 99.7683C78.7376 99.7023 78.583 99.603 78.4524 99.4762C78.3219 99.3493 78.2181 99.1976 78.1473 99.0299C78.0764 98.8623 78.04 98.6821 78.04 98.5001V95.6401Z"
        stroke="black"
        strokeWidth="0.63"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M94.08 83.05L92.72 93.97"
        stroke="black"
        strokeWidth="0.63"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M97.09 88.5101C97.8908 88.5101 98.54 88.2594 98.54 87.9501C98.54 87.6408 97.8908 87.3901 97.09 87.3901C96.2892 87.3901 95.64 87.6408 95.64 87.9501C95.64 88.2594 96.2892 88.5101 97.09 88.5101Z"
        stroke="black"
        strokeWidth="0.63"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M101.31 88.5101C102.111 88.5101 102.76 88.2594 102.76 87.9501C102.76 87.6408 102.111 87.3901 101.31 87.3901C100.509 87.3901 99.86 87.6408 99.86 87.9501C99.86 88.2594 100.509 88.5101 101.31 88.5101Z"
        stroke="black"
        strokeWidth="0.63"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M105.53 88.5101C106.331 88.5101 106.98 88.2594 106.98 87.9501C106.98 87.6408 106.331 87.3901 105.53 87.3901C104.729 87.3901 104.08 87.6408 104.08 87.9501C104.08 88.2594 104.729 88.5101 105.53 88.5101Z"
        stroke="black"
        strokeWidth="0.63"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M97.43 86.4501C98.148 86.4501 98.73 86.1994 98.73 85.8901C98.73 85.5808 98.148 85.3301 97.43 85.3301C96.712 85.3301 96.13 85.5808 96.13 85.8901C96.13 86.1994 96.712 86.4501 97.43 86.4501Z"
        stroke="black"
        strokeWidth="0.63"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M101.22 86.4501C101.938 86.4501 102.52 86.1994 102.52 85.8901C102.52 85.5808 101.938 85.3301 101.22 85.3301C100.502 85.3301 99.92 85.5808 99.92 85.8901C99.92 86.1994 100.502 86.4501 101.22 86.4501Z"
        stroke="black"
        strokeWidth="0.63"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M105 86.4501C105.718 86.4501 106.3 86.1994 106.3 85.8901C106.3 85.5808 105.718 85.3301 105 85.3301C104.282 85.3301 103.7 85.5808 103.7 85.8901C103.7 86.1994 104.282 86.4501 105 86.4501Z"
        stroke="black"
        strokeWidth="0.63"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M97.76 84.3901C98.3951 84.3901 98.91 84.1394 98.91 83.8301C98.91 83.5208 98.3951 83.2701 97.76 83.2701C97.1249 83.2701 96.61 83.5208 96.61 83.8301C96.61 84.1394 97.1249 84.3901 97.76 84.3901Z"
        stroke="black"
        strokeWidth="0.63"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M101.12 84.3901C101.755 84.3901 102.27 84.1394 102.27 83.8301C102.27 83.5208 101.755 83.2701 101.12 83.2701C100.485 83.2701 99.97 83.5208 99.97 83.8301C99.97 84.1394 100.485 84.3901 101.12 84.3901Z"
        stroke="black"
        strokeWidth="0.63"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M104.47 84.3901C105.105 84.3901 105.62 84.1394 105.62 83.8301C105.62 83.5208 105.105 83.2701 104.47 83.2701C103.835 83.2701 103.32 83.5208 103.32 83.8301C103.32 84.1394 103.835 84.3901 104.47 84.3901Z"
        stroke="black"
        strokeWidth="0.63"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M82.25 89.67L80.98 93.34"
        stroke="black"
        strokeWidth="0.63"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M84.71 82.55L83.03 87.39"
        stroke="black"
        strokeWidth="0.63"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M82.03 93.55H91.45"
        stroke="black"
        strokeWidth="0.63"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M90.98 88.7201H85.25L83.87 87.9501"
        stroke="black"
        strokeWidth="0.63"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M86.01 84.3201H91.86"
        stroke="black"
        strokeWidth="0.63"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M107.93 98.6801C108.394 98.6801 108.77 98.304 108.77 97.8401C108.77 97.3761 108.394 97.0001 107.93 97.0001C107.466 97.0001 107.09 97.3761 107.09 97.8401C107.09 98.304 107.466 98.6801 107.93 98.6801Z"
        stroke="black"
        strokeWidth="0.63"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.02999 112.08H4.95999C4.93394 112.072 4.90978 112.059 4.88897 112.042C4.86816 112.024 4.85116 112.002 4.83899 111.978C4.82682 111.954 4.81974 111.927 4.81819 111.9C4.81665 111.873 4.82066 111.846 4.82999 111.82C4.88999 111.63 6.38999 107.29 16.96 107.61C22.31 107.77 25.36 105.61 26.96 103.78C28.0041 102.611 28.782 101.229 29.24 99.7301C29.2446 99.7025 29.2549 99.6762 29.2703 99.6528C29.2858 99.6295 29.3059 99.6096 29.3294 99.5945C29.353 99.5795 29.3795 99.5695 29.4071 99.5653C29.4348 99.5611 29.463 99.5627 29.49 99.5701C29.5431 99.5842 29.5889 99.6178 29.6185 99.664C29.6481 99.7103 29.6593 99.7659 29.65 99.8201C29.65 99.9101 27.65 108.07 17.52 108.07H16.95C11.6 107.91 8.68999 108.99 7.19999 109.92C6.32601 110.374 5.62389 111.101 5.19999 111.99C5.18032 112.017 5.1548 112.039 5.12535 112.055C5.0959 112.07 5.06329 112.079 5.02999 112.08Z"
        stroke="black"
        strokeWidth="0.63"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M115.06 112.42C102.67 111.84 96.5 110.42 96.2 108.02C95.9 105.62 101.55 103.75 102.68 103.39C108.46 101.6 107.68 98.0701 107.68 97.9201C107.662 97.8393 107.675 97.7545 107.718 97.6837C107.761 97.6128 107.83 97.5613 107.91 97.5401C107.95 97.5296 107.992 97.5274 108.033 97.5333C108.074 97.5393 108.113 97.5534 108.149 97.5749C108.184 97.5963 108.215 97.6246 108.239 97.6582C108.263 97.6917 108.281 97.7297 108.29 97.7701C108.29 97.7701 109.29 102.01 102.92 103.99C98.59 105.34 96.75 106.99 96.87 107.94C97.02 109.06 99.58 111.06 115.14 111.79C115.181 111.793 115.221 111.803 115.257 111.821C115.294 111.839 115.327 111.864 115.354 111.895C115.38 111.925 115.401 111.961 115.414 112C115.427 112.038 115.433 112.079 115.43 112.12C115.431 112.166 115.422 112.212 115.403 112.254C115.383 112.296 115.355 112.332 115.319 112.361C115.283 112.39 115.241 112.411 115.196 112.421C115.151 112.431 115.105 112.431 115.06 112.42V112.42Z"
        stroke="black"
        strokeWidth="0.63"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_293_23744">
        <rect
          width="111.19"
          height="107.63"
          fill="white"
          transform="translate(4.5 6.50006)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default memo(ErrorSvg);
