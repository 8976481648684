import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import withRouter from 'utils/withRouter';
import useAxios from 'hooks/useAxios';
import { getCoupons } from 'services';
import { colors } from 'styles/theme';
import EmptySvg from 'images/EmptySvg';
import Tabs from 'components/Tabs';
import Loading from 'components/Loading';
import Text from 'components/Text';
import CouponTicket from 'components/CouponTicket';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  padding: 72px 16px 16px 16px;
  margin: -16px;
  background-color: ${colors.SHADES_50};
`;

const EmptyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
`;

const Header = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 16px 16px 0 16px;
  background-color: ${colors.SHADES_50};
  z-index: 100;
`;

const Content = styled.div`
  margin-top: 24px;
`;

const tabItems = [
  { id: 1, name: '未使用', value: 'unused' },
  { id: 2, name: '已使用', value: 'used' },
  { id: 3, name: '已過期', value: 'overdue' },
];

const groupList = (listData) =>
  listData
    .sort((a, b) => b.gotTime - a.gotTime)
    .reduce(
      (group, data) => {
        const status =
          data.expired && data.status.code !== 'used'
            ? 'overdue'
            : data.status.code;
        if (!group[status]) {
          group[status] = [data];
        } else {
          group[status].push(data);
        }
        return group;
      },
      { unused: [], used: [], overdue: [] }
    );

const Coupons = ({ navigate }) => {
  const { request } = useAxios();
  const [currentTab, setCurrentTab] = useState(tabItems[0]);
  const [couponList, setCouponList] = useState(null);

  const getCouponList = useCallback(async () => {
    const { coupons } = await request(getCoupons());
    setCouponList(groupList(coupons));
  }, [request]);

  useEffect(() => {
    getCouponList();
  }, [getCouponList]);

  return !couponList ? (
    <Loading />
  ) : (
    <Wrapper>
      <Header>
        <Tabs
          currentTab={currentTab}
          tabs={tabItems}
          onChange={setCurrentTab}
        />
      </Header>
      <Content>
        {Object.keys(couponList).length > 0 &&
        couponList[currentTab.value].length ? (
          couponList[currentTab.value].map((data) => (
            <CouponTicket data={data} navigate={navigate} key={data.id} />
          ))
        ) : (
          <EmptyWrapper>
            <EmptySvg />
            <Text
              color="SHADES_400"
              content={`您目前尚無${currentTab.name}的專屬療程。`}
              marginTop={24}
              block
            />
          </EmptyWrapper>
        )}
      </Content>
    </Wrapper>
  );
};

Coupons.propTypes = {
  auth: PropTypes.object,
  navigate: PropTypes.func,
};

export default withRouter(Coupons);
