import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const CheckboxWrapper = styled.div`
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-bottom: 0;
  line-height: 1;
  font-size: 0;
  cursor: pointer;
  svg {
    width: ${({ size }) => `${size}px`};
    height: ${({ size }) => `${size}px`};
  }
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
`;

const Checkbox = ({ size = 24, checked = false, onClick = () => {} }) => {
  const handleClick = (e) => onClick(e.target.checked);
  return (
    <CheckboxWrapper size={size}>
      <input type="checkbox" checked={checked} onChange={handleClick} />
      {checked ? (
        <svg focusable="false" viewBox="0 0 24 24" fill="#5cc2e7">
          <path d="M19 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.11 0 2-.9 2-2V5c0-1.1-.89-2-2-2zm-9 14l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z"></path>
        </svg>
      ) : (
        <svg focusable="false" viewBox="0 0 24 24" fill="#929CB7">
          <path d="M19 5v14H5V5h14m0-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z"></path>
        </svg>
      )}
    </CheckboxWrapper>
  );
};

Checkbox.propTypes = {
  size: PropTypes.number,
  checked: PropTypes.bool,
  onClick: PropTypes.func,
};

export default Checkbox;
