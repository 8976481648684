import React, { memo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { colors } from 'styles/theme';

const Wrapper = styled.div`
  width: 100%;
  input {
    position: relative;
    -webkit-appearance: none;
    width: 100%;
    height: 6px;
    background: ${colors.SHADES_200};
    border-radius: 5px;
    background-image: linear-gradient(
      ${colors.PRIMARY_400},
      ${colors.PRIMARY_400}
    );
    background-size: ${({ value, min, max }) =>
      `${((value - min) * 100) / (max - min)}% 100%`};
    background-repeat: no-repeat;
  }
  input::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background: ${colors.PRIMARY_400};
  }
  input::-moz-range-thumb {
    -webkit-appearance: none;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background: ${colors.PRIMARY_400};
  }
`;

const RangeSelector = ({ value, onChange, min = 1, max = 10, step = 1 }) => {
  return (
    <Wrapper value={value} min={min} max={max}>
      <input
        value={value}
        onChange={(e) => onChange(e.target.value)}
        min={min}
        max={max}
        step={step}
        type="range"
      />
    </Wrapper>
  );
};

RangeSelector.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onChange: PropTypes.func,
  min: PropTypes.number,
  max: PropTypes.number,
  step: PropTypes.number,
};

export default memo(RangeSelector);
