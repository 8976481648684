import React, { memo } from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import { colors } from 'styles/theme';

const stateColorSetting = (color, bgColor, borderColor = color) => css`
  color: ${color};
  border-color: ${borderColor};
  &::before {
    background-color: ${bgColor};
  }
`;

const StyledRadioIndicator = styled.div`
  position: relative;
  cursor: pointer;
  width: 20px;
  height: 20px;
  max-width: 20px;
  border-radius: 50%;
  border: 2px solid ${colors.SHADES_400};
  background-color: ${colors.SHADES_000};
  outline-width: 0;
  overflow: hidden;
  transition: box-shadow ease-in-out 200ms;

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    opacity: 0;
    transform: scale(0) translate(-50%, -50%);
    transform-origin: top left;
    transition: transform 200ms, opacity 200ms;
  }

  ${({ checked }) =>
    checked && stateColorSetting(colors.PRIMARY_300, colors.PRIMARY_300)}
  ${({ checked }) =>
    checked &&
    `&::before {
      transform: scale(1) translate(-50%, -50%);
      opacity: 1;
  `}

  ${({ error }) =>
    error &&
    stateColorSetting(colors.SYSTEM_ERROR_500, colors.SYSTEM_ERROR_500)}
  ${({ disabled }) =>
    disabled && stateColorSetting(colors.SHADES_300, colors.SHADES_300)}
`;

const RadioIndicator = ({ checked, error, disabled }) => {
  return (
    <React.Fragment>
      <StyledRadioIndicator
        checked={checked}
        error={error}
        disabled={disabled}
      />
    </React.Fragment>
  );
};

const propTypes = {
  checked: PropTypes.bool,
  error: PropTypes.bool,
  disabled: PropTypes.bool,
};

RadioIndicator.propTypes = propTypes;

export default memo(RadioIndicator);
