import React, { useState, useEffect } from 'react';
import { createBrowserHistory } from 'history';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { setErrorMessage } from 'reducers/globalReducer';
import { getUrlParams } from 'utils';
import withRouter from 'utils/withRouter';
import useAxios from 'hooks/useAxios';
import { getExecutionItems, getExecutionRecords } from 'services';
import { colors } from 'styles/theme';
import EmptySvg from 'images/EmptySvg';
import Loading from 'components/Loading';
import Text from 'components/Text';
import TreatmentCard from 'components/TreatmentCard';
import TreatmentRecords from 'components/TreatmentRecords';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 16px;
  gap: 24px;
  min-height: 100vh;
  margin: -16px;
  background-color: ${colors.SHADES_50};
`;

const EmptyWrapper = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const getItemStatus = (totalTimes, leftTimes) => {
  if (totalTimes === leftTimes) {
    return 'pending';
  }
  if (leftTimes === 0) {
    return 'completion';
  }
  return 'executing';
};

const mergeTreatmentItems = (items, hideCompletion) => {
  const sortingArr = ['pending', 'executing', 'completion'];
  let mergedItems = items.reduce((obj, current) => {
    const { status, item, totalTimes, leftTimes } = current;
    if (status !== 'completion' && !leftTimes) return obj;

    if (!obj[item.id]) {
      obj[item.id] = {
        id: item.id,
        name: item.name,
        totalTimes: totalTimes,
        leftTimes: leftTimes,
        status: getItemStatus(totalTimes, leftTimes),
        itemIds: [current.id],
      };
    } else {
      obj[item.id].totalTimes += totalTimes;
      obj[item.id].leftTimes += leftTimes;
      obj[item.id].status = getItemStatus(
        obj[item.id].totalTimes,
        obj[item.id].leftTimes
      );
      obj[item.id].itemIds.push(current.id);
    }

    return obj;
  }, {});
  if (hideCompletion) {
    mergedItems = Object.values(mergedItems).filter(
      (item) => item.status !== 'completion'
    );
  }
  return Object.values(mergedItems).sort(
    (a, b) => sortingArr.indexOf(a.status) - sortingArr.indexOf(b.status)
  );
};

const mapTreatmentRecords = (records) =>
  records.map((record) => {
    const { id, quantity, cancelled, executedDate, revoked, refunded } = record;
    const status = (() => {
      if (cancelled) return 'cancelled';
      if (revoked) return 'revoked';
      if (refunded) return 'refunded';
      return 'executed';
    })();
    return { id, status, executedDate, quantity };
  });

const Treatments = ({ auth, navigate }) => {
  const browserHistory = createBrowserHistory();
  const dispatch = useDispatch();
  const hideCompletionTreatment = getUrlParams('hideCompletionTreatment');
  const { request, status } = useAxios();
  const [treatmentItems, setTreatmentItems] = useState(null);
  const [treatmentRecords, setTreatmentRecords] = useState({});
  const [openRecords, setOpenRecords] = useState(false);
  const [displayRecords, setDisplayRecords] = useState([]);

  const createAppointment = () => {
    navigate('/appointment/create');
  };

  const handleClickRecord = async (item) => {
    history.pushState(null, null, null);
    setOpenRecords(true);
    const { id, itemIds } = item;
    let recordData = treatmentRecords[id];
    if (!recordData) {
      const { treatmentRecords: records } = await request(
        getExecutionRecords({ itemIDs: itemIds })
      );
      recordData = mapTreatmentRecords(records);
      setTreatmentRecords({ ...treatmentRecords, [id]: recordData });
    }
    setDisplayRecords(recordData);
  };

  useEffect(() => {
    const getTreatmentsData = async () => {
      try {
        const { treatments } = await request(getExecutionItems());
        setTreatmentItems(
          mergeTreatmentItems(treatments, hideCompletionTreatment)
        );
      } catch (err) {
        dispatch(setErrorMessage(err.message));
      }
    };
    if (auth.token && auth.customerId) {
      getTreatmentsData();
    }
  }, [auth, request, dispatch, hideCompletionTreatment]);

  useEffect(() => {
    browserHistory.listen(({ action }) => {
      if (action === 'POP') {
        setOpenRecords(false);
      }
    });
  }, [browserHistory]);

  return !treatmentItems ? (
    <Loading />
  ) : (
    <Wrapper>
      {treatmentItems?.length ? (
        treatmentItems.map((data) => (
          <TreatmentCard
            data={data}
            key={data.id}
            onClick={createAppointment}
            onClickRecord={handleClickRecord}
          />
        ))
      ) : (
        <EmptyWrapper>
          <EmptySvg />
          <Text
            color="SHADES_400"
            content="您目前尚無療程。"
            marginTop={24}
            block
          />
        </EmptyWrapper>
      )}
      {openRecords && (
        <TreatmentRecords
          isLoading={status.loading}
          data={displayRecords || []}
          onClose={() => setOpenRecords(false)}
        />
      )}
    </Wrapper>
  );
};

Treatments.propTypes = {
  auth: PropTypes.object,
  navigate: PropTypes.func.isRequired,
};

export default withRouter(Treatments);
