import React, { memo } from 'react';
import styled from 'styled-components';
import PulseLoader from 'react-spinners/PulseLoader';
import { colors } from 'styles/theme';

const LoadingWrapper = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const Loading = () => {
  return (
    <LoadingWrapper>
      <PulseLoader color={colors.PRIMARY_400} />
    </LoadingWrapper>
  );
};

export default memo(Loading);
