import React, { memo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { colors } from 'styles/theme';
import { getDisplayDate } from 'utils';
import Text from 'components/Text';
import Loading from 'components/Loading';
import EmptySvg from 'images/EmptySvg';

const Wrapper = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  padding: 16px;
  overflow: auto;
  z-index: 300;
  background-color: ${colors.SHADES_50};
`;

const EmptyWrapper = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Record = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  width: 100%;
  padding: 16px;
  margin-bottom: 24px;
  background-color: ${colors.SHADES_000};
  border: 1px solid ${colors.SHADES_200};
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06),
    0px 0px 1px rgba(0, 0, 0, 0.04);
  border-radius: 16px;
`;

const getStatusText = (status) => {
  switch (status) {
    case 'pending':
      return '待執行';
    case 'executing':
      return '執行中';
    case 'executed':
      return '已執行';
    case 'completion':
      return '已完成';
    case 'cancelled':
      return '已取消';
    case 'revoked':
      return '已撤回';
    default:
      return status;
  }
};

const TreatmentRecords = ({ isLoading, data }) => {
  return (
    <Wrapper>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          {data.map((record, index) => (
            <Record key={index}>
              <Text
                font="Heading/Small/Heavy"
                color="SHADES_300"
                content={`${record.quantity} 筆 ${getStatusText(
                  record.status
                )}`}
                block
              />
              <Text
                font="Heading/Large/Medium"
                content={
                  open ? getDisplayDate(record.executedDate, true) : data.name
                }
                block
              />
            </Record>
          ))}
          {data.length === 0 && (
            <EmptyWrapper>
              <EmptySvg />
              <Text
                color="SHADES_400"
                content="此療程尚無使用歷程。"
                marginTop={24}
                block
              />
            </EmptyWrapper>
          )}
        </>
      )}
    </Wrapper>
  );
};

TreatmentRecords.propTypes = {
  data: PropTypes.array,
  isLoading: PropTypes.bool,
  onClose: PropTypes.func,
};

export default memo(TreatmentRecords);
